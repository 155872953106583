*,
*:focus {
  outline: none;
}
::-webkit-scrollbar {
  width: 9px;
  background-color: white;
  box-shadow: 0px 0.5px 4px 1px rgb(0 0 0 / 11%);
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
  width: 8px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border-radius: 10px;
}
[data-show-only="none"],
[data-show-only="none"][data-page-element="FlexContainer/V1"],
[data-show-only="none"][data-page-element="RowContainer/V1"] {
  display: none;
}
.hide {
  display: none;
}
.forceHide {
  display: none !important;
}
.elPageContent {
  font-size: 14px;
}
:host,
body {
  color: inherit !important;
  -webkit-font-smoothing: antialiased;
}
html,
:host {
  all: initial;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #2f2f2f;
  background-color: #fff;
}
u {
  text-decoration: underline;
}
strike {
  text-decoration: line-through;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
body {
  line-height: 1.42857143;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1.1;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0 auto;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
span {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* projects/shared/stylesheets/page-content/modal.css */

/* projects/shared/stylesheets/page-content/block_settings.css */
.containerWrapper {
  min-width: 320px;
  height: 100%;
}
.innerContent {
  word-wrap: break-word;
}
.wideContainer {
  max-width: 1170px;
  width: 100%;
}
.midWideContainer {
  max-width: 960px;
  width: 100%;
}
.midContainer {
  max-width: 720px;
  width: 100%;
}
.smallContainer {
  max-width: 550px;
}
.fullContainer {
  width: 100%;
}
.fullContainer .containerInner {
  width: 1170px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.fullContainer .containerInnerV2 {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}
.fullContainer .containerInnerV2 .containerInnerV2 {
  max-width: inherit;
}
.bgW100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}
.bgW100H100 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.bgNoRepeat {
  background-repeat: no-repeat !important;
}
.bgRepeatX {
  background-repeat: repeat-x !important;
}
.bgRepeatY {
  background-repeat: repeat-y !important;
}
.bgCoverV2 {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center !important;
}
.bgCoverCenter {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-repeat: repeat repeat !important;
  background-position: center center !important;
}

/* projects/shared/stylesheets/page-content/magnific-popup-custom.css */
.mfp-bg {
  opacity: 0.85;
  filter: alpha(opacity=85);
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close,
.mfp-zoom {
  cursor: inherit;
}

/* projects/shared/stylesheets/page-content/bootstrap-grid.min.css */
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  display: table;
  content: " ";
}
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

/* projects/editor/app/javascript/styles/layers.css */
.layer-behind-modal-backdrop {
  z-index: 57;
}
.layer-modal-backdrop {
  z-index: 60;
}
.layer-sticky-section {
  z-index: 59;
}
.layer-toolbar {
  z-index: 100;
}
.layer-toolbar-2 {
  z-index: 100;
}
.layer-sidebar {
  z-index: 150;
}
.layer-sidebar-2 {
  z-index: 170;
}
.layer-sidebar-3 {
  z-index: 190;
}
.layer-topbar {
  z-index: 250;
}
.layer-modal {
  z-index: 300;
}
.layer-initial {
  z-index: initial;
}
.layer-above-modal {
  z-index: 303;
}
.layer-topbar-dropdown {
  z-index: 400;
}

/* projects/shared/stylesheets/page-content/main.scss */
@media only screen and (min-width: 771px) {
  *[data-show-only="mobile"] {
    display: none !important;
  }
  *[data-show-only="desktop"] {
    display: block;
  }
  *[data-mobile-only] {
    display: none !important;
  }
  *[data-timed-style="fade"][data-show-only="desktop"] {
    display: none;
  }
  *[data-timed-style="scale"][data-show-only="desktop"] {
    display: none;
  }
  *[data-timed-style="fade"][data-show-only="desktop"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="scale"][data-show-only="desktop"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="no-action"][data-show-only="desktop"].displayImportantTimed {
    display: block;
  }
}
@media only screen and (min-width: 0px) and (max-width: 770px) {
  *[data-show-only="desktop"] {
    display: none !important;
  }
  *[data-show-only="mobile"] {
    display: block;
  }
  *[data-desktop-only] {
    display: none !important;
  }
  *[data-timed-style="fade"][data-show-only="mobile"] {
    display: none;
  }
  *[data-timed-style="scale"][data-show-only="mobile"] {
    display: none;
  }
  *[data-timed-style="fade"][data-show-only="mobile"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="scale"][data-show-only="mobile"].displayImportantTimed {
    display: block;
  }
  *[data-timed-style="no-action"][data-show-only="mobile"].displayImportantTimed {
    display: block;
  }
  .fullContainer .containerInner,
  .fullContainer .containerInnerV2 {
    width: 100% !important;
    min-width: 320px;
  }
  .fullContainer .containerInner .containerInner,
  .fullContainer .containerInnerV2 .containerInnerV2,
  .wideContainer .containerInner .containerInner,
  .wideContainer .containerInnerV2 .containerInnerV2,
  .midWideContainer .containerInner .containerInner,
  .midWideContainer .containerInnerV2 .containerInnerV2,
  .midContainer .containerInner .containerInner,
  .midContainer .containerInnerV2 .containerInnerV2,
  .smallContainer .containerInner .containerInner,
  .smallContainer .containerInnerV2 .containerInnerV2,
  .containerInner .fullContainer,
  .containerInnerV2 .fullContainer,
  .containerInner .wideContainer,
  .containerInnerV2 .wideContainer,
  .containerInner .midWideContainer,
  .containerInnerV2 .midWideContainer,
  .containerInner .midContainer,
  .containerInnerV2 .midContainer,
  .containerInner .smallContainer,
  .containerInnerV2 .smallContainer {
    min-width: auto;
  }
  .containerModal .fullContainer .containerInner,
  .containerModal .fullContainer .containerInnerV2 {
    min-width: inherit;
  }
  .containerModal .wideContainer,
  .containerModal .midWideContainer,
  .containerModal .midContainer,
  .containerModal .smallContainer {
    min-width: 100% !important;
  }
  .wideContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .midWideContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .midContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .smallContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .col-inner {
  }
  .innerContent {
    padding: 0px !important;
    width: 100%;
  }
  .row {
  }
  .col_right {
    margin-top: 20px !important;
  }
  .de-video-block {
    width: 100% !important;
  }
  .wideCountdownSize4 .countdown-amount {
    font-size: 18px;
    width: 57px;
  }
  .wideCountdownSize4 .countdown-period {
    font-size: 10px;
  }
  .de-nav {
    text-align: center !important;
  }
  .elScreenshot_text {
    float: none !important;
    width: 100% !important;
    text-align: center !important;
  }
  .elScreenshot_text_padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .elScreenshot_image {
    float: none !important;
    width: 100% !important;
  }
  .hsSize72 {
    font-size: 48px;
  }
  .elImageWrapper {
    text-align: center !important;
  }
  .elText {
  }
  .containerInner,
  .containerInnerV2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .row,
  .col-inner {
    margin-left: auto !important;
    margin-right: auto !important;
    width: auto !important;
  }
  .elButtonSize3,
  .elButtonSize4 {
    font-size: 21px;
    border-radius: 5px;
    padding: 15px 35px;
  }
  .elSharing,
  .elBTN {
    text-align: center;
  }
  .elBulletList {
  }
  .elScreenshot_text_body,
  .elScreenshot_text_headline {
    text-align: center !important;
  }
  .elScreenshot_image {
    text-align: center !important;
    margin-top: 20px;
  }
  .elButtonInline {
    display: block;
  }
  .elTextWrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .iframeBlocker {
    display: none !important;
  }
  .ccNumberWrap {
    float: left;
    width: 100%;
  }
  .ccCVCWrap {
    float: left;
    width: 100%;
    padding-left: 0;
  }
  .elAffStats_views:before {
    content: "";
  }
  .bgCover {
    background-attachment: scroll !important;
  }
  .elOrderProductOptinProductName {
    width: 100% !important;
  }
  .elOrderProductOptinPrice {
    width: 30% !important;
    text-align: right;
  }
  .elvideounlock_videoPauseBlocker {
    display: none !important;
  }
  .bgW100 {
    background-size: cover !important;
    -webkit-background-size: cover !important;
    background-repeat: repeat repeat !important;
    background-position: center center;
  }
  [data-page-element="RowContainer/V1"] {
    flex-direction: column;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"] {
    flex-direction: row;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col_right {
    margin-top: 0px !important;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-12,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-12,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-12,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-12 {
    width: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-11 {
    width: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-10 {
    width: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-9 {
    width: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-8 {
    width: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-7 {
    width: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-6 {
    width: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-5 {
    width: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-4 {
    width: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-3 {
    width: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-2 {
    width: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-1 {
    width: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-12 {
    right: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-11 {
    right: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-10 {
    right: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-9 {
    right: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-8 {
    right: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-7 {
    right: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-6 {
    right: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-5 {
    right: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-4 {
    right: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-3 {
    right: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-2 {
    right: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-1 {
    right: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-pull-0 {
    right: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-12 {
    left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-11 {
    left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-10 {
    left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-9 {
    left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-8 {
    left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-7 {
    left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-6 {
    left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-5 {
    left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-4 {
    left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-3 {
    left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-2 {
    left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-1 {
    left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-push-0 {
    left: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-12 {
    margin-left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-9 {
    margin-left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-6 {
    margin-left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-3 {
    margin-left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-xs-offset-0 {
    margin-left: 0%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-12 {
    float: left;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-12 {
    width: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-11 {
    width: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-10 {
    width: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-9 {
    width: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-8 {
    width: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-7 {
    width: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-6 {
    width: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-5 {
    width: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-4 {
    width: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-3 {
    width: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-2 {
    width: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-1 {
    width: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-12 {
    right: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-11 {
    right: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-10 {
    right: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-9 {
    right: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-8 {
    right: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-7 {
    right: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-6 {
    right: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-5 {
    right: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-4 {
    right: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-3 {
    right: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-2 {
    right: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-1 {
    right: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-pull-0 {
    right: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-12 {
    left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-11 {
    left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-10 {
    left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-9 {
    left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-8 {
    left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-7 {
    left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-6 {
    left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-5 {
    left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-4 {
    left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-3 {
    left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-2 {
    left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-1 {
    left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-push-0 {
    left: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-12 {
    margin-left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-9 {
    margin-left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-6 {
    margin-left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-3 {
    margin-left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-sm-offset-0 {
    margin-left: 0%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-12 {
    float: left;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-12 {
    width: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-11 {
    width: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-10 {
    width: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-9 {
    width: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-8 {
    width: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-7 {
    width: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-6 {
    width: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-5 {
    width: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-4 {
    width: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-3 {
    width: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-2 {
    width: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-1 {
    width: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-12 {
    right: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-11 {
    right: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-10 {
    right: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-9 {
    right: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-8 {
    right: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-7 {
    right: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-6 {
    right: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-5 {
    right: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-4 {
    right: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-3 {
    right: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-2 {
    right: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-1 {
    right: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-pull-0 {
    right: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-12 {
    left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-11 {
    left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-10 {
    left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-9 {
    left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-8 {
    left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-7 {
    left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-6 {
    left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-5 {
    left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-4 {
    left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-3 {
    left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-2 {
    left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-1 {
    left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-push-0 {
    left: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-12 {
    margin-left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-9 {
    margin-left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-6 {
    margin-left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-3 {
    margin-left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-md-offset-0 {
    margin-left: 0%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-1,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-2,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-3,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-4,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-5,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-6,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-7,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-8,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-9,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-10,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-11,
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-12 {
    float: left;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-12 {
    width: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-11 {
    width: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-10 {
    width: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-9 {
    width: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-8 {
    width: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-7 {
    width: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-6 {
    width: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-5 {
    width: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-4 {
    width: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-3 {
    width: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-2 {
    width: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-1 {
    width: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-12 {
    right: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-11 {
    right: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-10 {
    right: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-9 {
    right: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-8 {
    right: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-7 {
    right: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-6 {
    right: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-5 {
    right: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-4 {
    right: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-3 {
    right: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-2 {
    right: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-1 {
    right: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-pull-0 {
    right: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-12 {
    left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-11 {
    left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-10 {
    left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-9 {
    left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-8 {
    left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-7 {
    left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-6 {
    left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-5 {
    left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-4 {
    left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-3 {
    left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-2 {
    left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-1 {
    left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-push-0 {
    left: auto;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-12 {
    margin-left: 100%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-9 {
    margin-left: 75%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-6 {
    margin-left: 50%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-3 {
    margin-left: 25%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  [data-page-element="RowContainer/V1"][data-mobile-columns-direction="horizontal"]
    > .col-lg-offset-0 {
    margin-left: 0%;
  }
}
@media only screen and (min-width: 770px) and (max-width: 1170px) {
  .col-inner {
  }
  .fullContainer .col-md-1,
  .fullContainer .col-md-2,
  .fullContainer .col-md-3,
  .fullContainer .col-md-4,
  .fullContainer .col-md-5,
  .fullContainer .col-md-6,
  .fullContainer .col-md-7,
  .fullContainer .col-md-8,
  .fullContainer .col-md-9,
  .fullContainer .col-md-10,
  .fullContainer .col-md-11,
  .fullContainer .col-md-12 {
    float: left !important;
  }
  .fullContainer .col-md-12 {
    width: 100%;
  }
  .fullContainer .col-md-11 {
    width: 91.66666667%;
  }
  .fullContainer .col-md-10 {
    width: 83.33333333%;
  }
  .fullContainer .col-md-9 {
    width: 75%;
  }
  .fullContainer .col-md-8 {
    width: 66.66666667%;
  }
  .fullContainer .col-md-7 {
    width: 58.33333333%;
  }
  .fullContainer .col-md-6 {
    width: 50%;
  }
  .fullContainer .col-md-5 {
    width: 41.66666667%;
  }
  .fullContainer .col-md-4 {
    width: 33.33333333%;
  }
  .fullContainer .col-md-3 {
    width: 25%;
  }
  .fullContainer .col-md-2 {
    width: 16.66666667%;
  }
  .fullContainer .col-md-1 {
    width: 8.33333333%;
  }
  .wideContainer .col-md-1,
  .wideContainer .col-md-2,
  .wideContainer .col-md-3,
  .wideContainer .col-md-4,
  .wideContainer .col-md-5,
  .wideContainer .col-md-6,
  .wideContainer .col-md-7,
  .wideContainer .col-md-8,
  .wideContainer .col-md-9,
  .wideContainer .col-md-10,
  .wideContainer .col-md-11,
  .wideContainer .col-md-12 {
    float: left !important;
  }
  .wideContainer .col-md-12 {
    width: 100%;
  }
  .wideContainer .col-md-11 {
    width: 91.66666667%;
  }
  .wideContainer .col-md-10 {
    width: 83.33333333%;
  }
  .wideContainer .col-md-9 {
    width: 75%;
  }
  .wideContainer .col-md-8 {
    width: 66.66666667%;
  }
  .wideContainer .col-md-7 {
    width: 58.33333333%;
  }
  .wideContainer .col-md-6 {
    width: 50%;
  }
  .wideContainer .col-md-5 {
    width: 41.66666667%;
  }
  .wideContainer .col-md-4 {
    width: 33.33333333%;
  }
  .wideContainer .col-md-3 {
    width: 25%;
  }
  .wideContainer .col-md-2 {
    width: 16.66666667%;
  }
  .wideContainer .col-md-1 {
    width: 8.33333333%;
  }
  .midWideContainer .col-md-1,
  .midWideContainer .col-md-2,
  .midWideContainer .col-md-3,
  .midWideContainer .col-md-4,
  .midWideContainer .col-md-5,
  .midWideContainer .col-md-6,
  .midWideContainer .col-md-7,
  .midWideContainer .col-md-8,
  .midWideContainer .col-md-9,
  .midWideContainer .col-md-10,
  .midWideContainer .col-md-11,
  .midWideContainer .col-md-12 {
    float: left !important;
  }
  .midWideContainer .col-md-12 {
    width: 100%;
  }
  .midWideContainer .col-md-11 {
    width: 91.66666667%;
  }
  .midWideContainer .col-md-10 {
    width: 83.33333333%;
  }
  .midWideContainer .col-md-9 {
    width: 75%;
  }
  .midWideContainer .col-md-8 {
    width: 66.66666667%;
  }
  .midWideContainer .col-md-7 {
    width: 58.33333333%;
  }
  .midWideContainer .col-md-6 {
    width: 50%;
  }
  .midWideContainer .col-md-5 {
    width: 41.66666667%;
  }
  .midWideContainer .col-md-4 {
    width: 33.33333333%;
  }
  .midWideContainer .col-md-3 {
    width: 25%;
  }
  .midWideContainer .col-md-2 {
    width: 16.66666667%;
  }
  .midWideContainer .col-md-1 {
    width: 8.33333333%;
  }
  .midContainer .col-md-1,
  .midContainer .col-md-2,
  .midContainer .col-md-3,
  .midContainer .col-md-4,
  .midContainer .col-md-5,
  .midContainer .col-md-6,
  .midContainer .col-md-7,
  .midContainer .col-md-8,
  .midContainer .col-md-9,
  .midContainer .col-md-10,
  .midContainer .col-md-11,
  .midContainer .col-md-12 {
    float: left !important;
  }
  .midContainer .col-md-12 {
    width: 100%;
  }
  .midContainer .col-md-11 {
    width: 91.66666667%;
  }
  .midContainer .col-md-10 {
    width: 83.33333333%;
  }
  .midContainer .col-md-9 {
    width: 75%;
  }
  .midContainer .col-md-8 {
    width: 66.66666667%;
  }
  .midContainer .col-md-7 {
    width: 58.33333333%;
  }
  .midContainer .col-md-6 {
    width: 50%;
  }
  .midContainer .col-md-5 {
    width: 41.66666667%;
  }
  .midContainer .col-md-4 {
    width: 33.33333333%;
  }
  .midContainer .col-md-3 {
    width: 25%;
  }
  .midContainer .col-md-2 {
    width: 16.66666667%;
  }
  .midContainer .col-md-1 {
    width: 8.33333333%;
  }
  .smallContainer .col-md-1,
  .smallContainer .col-md-2,
  .smallContainer .col-md-3,
  .smallContainer .col-md-4,
  .smallContainer .col-md-5,
  .smallContainer .col-md-6,
  .smallContainer .col-md-7,
  .smallContainer .col-md-8,
  .smallContainer .col-md-9,
  .smallContainer .col-md-10,
  .smallContainer .col-md-11,
  .smallContainer .col-md-12 {
    float: left !important;
  }
  .smallContainer .col-md-12 {
    width: 100%;
  }
  .smallContainer .col-md-11 {
    width: 91.66666667%;
  }
  .smallContainer .col-md-10 {
    width: 83.33333333%;
  }
  .smallContainer .col-md-9 {
    width: 75%;
  }
  .smallContainer .col-md-8 {
    width: 66.66666667%;
  }
  .smallContainer .col-md-7 {
    width: 58.33333333%;
  }
  .smallContainer .col-md-6 {
    width: 50%;
  }
  .smallContainer .col-md-5 {
    width: 41.66666667%;
  }
  .smallContainer .col-md-4 {
    width: 33.33333333%;
  }
  .smallContainer .col-md-3 {
    width: 25%;
  }
  .smallContainer .col-md-2 {
    width: 16.66666667%;
  }
  .smallContainer .col-md-1 {
    width: 8.33333333%;
  }
}
@media only screen and (max-width: 700px) {
  [data-mobile-font-size] li {
    font-size: inherit !important;
  }
  .elPageContent.elPageContent [data-mobile-font-size="10"] {
    font-size: 10px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="11"] {
    font-size: 11px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="12"] {
    font-size: 12px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="13"] {
    font-size: 13px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="14"] {
    font-size: 14px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="15"] {
    font-size: 15px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="16"] {
    font-size: 16px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="17"] {
    font-size: 17px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="18"] {
    font-size: 18px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="19"] {
    font-size: 19px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="20"] {
    font-size: 20px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="21"] {
    font-size: 21px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="22"] {
    font-size: 22px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="23"] {
    font-size: 23px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="24"] {
    font-size: 24px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="25"] {
    font-size: 25px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="26"] {
    font-size: 26px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="27"] {
    font-size: 27px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="28"] {
    font-size: 28px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="29"] {
    font-size: 29px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="30"] {
    font-size: 30px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="31"] {
    font-size: 31px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="32"] {
    font-size: 32px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="33"] {
    font-size: 33px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="34"] {
    font-size: 34px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="35"] {
    font-size: 35px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="36"] {
    font-size: 36px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="37"] {
    font-size: 37px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="38"] {
    font-size: 38px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="39"] {
    font-size: 39px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="40"] {
    font-size: 40px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="41"] {
    font-size: 41px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="42"] {
    font-size: 42px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="43"] {
    font-size: 43px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="44"] {
    font-size: 44px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="45"] {
    font-size: 45px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="46"] {
    font-size: 46px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="47"] {
    font-size: 47px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="48"] {
    font-size: 48px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="49"] {
    font-size: 49px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="50"] {
    font-size: 50px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="51"] {
    font-size: 51px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="52"] {
    font-size: 52px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="53"] {
    font-size: 53px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="54"] {
    font-size: 54px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="55"] {
    font-size: 55px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="56"] {
    font-size: 56px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="57"] {
    font-size: 57px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="58"] {
    font-size: 58px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="59"] {
    font-size: 59px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="60"] {
    font-size: 60px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="61"] {
    font-size: 61px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="62"] {
    font-size: 62px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="63"] {
    font-size: 63px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="64"] {
    font-size: 64px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="65"] {
    font-size: 65px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="66"] {
    font-size: 66px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="67"] {
    font-size: 67px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="68"] {
    font-size: 68px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="69"] {
    font-size: 69px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="70"] {
    font-size: 70px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="71"] {
    font-size: 71px;
  }
  .elPageContent.elPageContent [data-mobile-font-size="72"] {
    font-size: 72px;
  }
}
@media (max-width: 1170px) {
  .fullContainer .containerInner,
  .fullContainer .containerInnerV2 {
    width: 100% !important;
  }
}
@media (max-width: 960px) {
  .midWideContainer {
    width: 100% !important;
    min-width: 320px;
  }
  .midWideContainer .containerInner,
  .midWideContainer .containerInnerV2 {
    width: 100% !important;
  }
}
@media only screen and (min-width: 650px) and (max-width: 720px) {
  .midContainer {
    width: 100% !important;
    min-width: 320px;
  }
}
@media only screen and (min-width: 650px) and (max-width: 650px) {
  .smallContainer {
    width: 100% !important;
    min-width: 320px;
  }
}
.displayImportantTimed {
  display: block;
}
.elContentWrapper .elContent {
  text-align: left;
}
.elContentWrapper .elContent h1,
.elContentWrapper .elContent h2,
.elContentWrapper .elContent h3,
.elContentWrapper .elContent h4,
.elContentWrapper .elContent h5 {
  font-weight: 600;
}
.elContentWrapper .elContent h2 {
  font-size: 1.5em;
  line-height: 2em;
}
.elContentWrapper .elContent h3 {
  font-size: 1.25em;
  line-height: 1.75em;
}
.elContentWrapper .elContent h4 {
  font-size: 1.125em;
  line-height: 1.75em;
}
.elContentWrapper .elContent a {
  color: rgb(37, 99, 235);
  font-weight: 400;
  text-decoration: underline;
}
.elContentWrapper .elContent ul > li {
  margin-left: 1.25em;
  list-style-type: disc;
}
.elContentWrapper .elContent ul > li ul > li {
  list-style-type: square;
}
.elContentWrapper .elContent ul > li ul > li ul > li {
  list-style-type: circle;
}
.elContentWrapper .elContent ol > li {
  margin-left: 1.25em;
  list-style-type: decimal;
}
.elContentWrapper .elContent ol > li ol > li {
  list-style-type: upper-roman;
}
.elContentWrapper .elContent blockquote {
  display: block;
  margin-bottom: 1em;
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  font-style: italic;
  border-left: 5px solid #ccc;
}
.pac-container {
  z-index: 100002;
}

/* projects/shared/stylesheets/bootstrap-modal.min.css */
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}
.clearfix:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

/* projects/user_pages/app/stylesheets/core.css */
.de-editable {
  cursor: auto !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.highlight {
  background-color: #ffff88;
}
.addthisevent-drop {
  width: 100% !important;
}
.de-add-new-element-empty {
  display: none;
}
.wideCountdown-demo {
  display: none;
}
html {
  margin-right: 0px !important;
  overflow-x: hidden;
}
body {
  color: #2e2e2e;
}
.containerWithVisibleOverflow {
  overflow: visible;
}
[data-trigger="load"] {
  opacity: 0;
  position: relative;
}
.stickyBottom {
  position: fixed;
  bottom: 0px;
}
.stickyClone {
  position: fixed !important;
  top: 0;
}
.stickyTop {
  position: relative;
}
.elVideoplaceholder,
.elAudioplaceholder {
  display: none;
}
.elVideo,
.elAudio {
  display: block !important;
}
.fb-comments {
  display: block !important;
}
.fbCommentsPlaceholder {
  display: none;
}
.pace .pace-progress {
  background: #cc181e;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;
  transition: width 1s;
}
.pace-inactive {
  display: none;
}
.nodoBadge {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
.btnIMG:hover {
  opacity: 0.8;
}
#closeCFPOPUP,
#closeCFPOPUP:hover {
  cursor: pointer !important;
}
.iframeblocked,
.iframeunmuted {
  position: relative;
}
.iframeBlocker,
.iframeUnmute {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.start-session-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.start-session {
  color: #fff;
  font-weight: bold;
  font-size: 2.5em;
  line-height: normal;
}
.start-session-wrapper:hover {
  cursor: pointer;
}
.unmute-player-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  font-size: 20px;
}
.unmute-player-wrapper:after {
  content: var(--style-video-unmute-label-text);
}
.unmute-player-icon {
  color: #fff;
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.unmute-player-wrapper:hover {
  cursor: pointer;
}
.smsExtras {
  display: none;
}
.smsCheckArea {
  cursor: pointer;
  margin-bottom: 0px;
}
.smsCheck {
  margin-right: 10px;
}
.smsExtras {
  margin-top: 15px;
}
[data-element-link]:not([data-element-link=""]) {
  cursor: pointer;
}
.elAffCopyPaste:hover {
  border: 1px solid #d4af37;
  cursor: pointer;
}
.elAffCopyPaste:hover .elAffiliateAssetTitle {
  background-color: #d4af37;
  color: #fff;
}
.elAffBlack {
  display: none;
  position: absolute;
  background-color: rgba(0, 116, 199, 0.15);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.elAffCopyPaste:hover .elAffBlack {
  display: block;
}
.elAffCopyPaste .elAffBlack.showAffButtonRightAway {
  display: block !important;
}
#reset-form .goto-register {
  display: none;
}
.surveyRadioOption:hover {
  background-color: #def2fa;
}
.surveyRadioOption:hover .surveyRadioOptionText,
.surveyStepSelectedAnswer .surveyRadioOptionText {
  color: ##d4af37 !important;
}
.surveyStepSelectedAnswer {
  background-color: #def2fa;
}
.smsCheckArea .smsCheck {
  position: relative;
  display: none;
}
.smsCheckArea .smsRecieveText {
  position: relative;
}
.smsCheckArea .smsRecieveText:before {
  content: "\f0c8";
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
  font: normal normal 100 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.smsCheckArea .smsRecieveText.smsRecieveTextActive:before {
  content: "\f14a";
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
  font: normal normal 900 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #3b99fc;
}
#bump-offer {
  cursor: normal !important;
}
.containerModal {
  position: fixed;
  overflow-y: auto;
}
.de2column {
  -moz-column-count: 2;
  column-count: 2;
}
.de3column {
  -moz-column-count: 3;
  column-count: 3;
}
.de4column {
  -moz-column-count: 4;
  column-count: 4;
}
.de5column {
  -moz-column-count: 5;
  column-count: 5;
}
.video-description {
  display: none;
}
.innerContent {
  word-wrap: break-word;
}
.editor-video-holder {
  display: none !important;
}
@media screen and (max-width: 500px) {
  #tubular-container {
    display: none !important;
  }
}
.otoloading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.otoloadingtext {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 4px;
  padding: 60px;
  margin-top: 20px;
  margin-left: -100px;
  min-width: 200px;
  position: absolute;
  left: 50%;
  font-weight: bold;
}
.otoloadingtext h2 {
  font-weight: bold;
}
.otoloadingtext i {
  font-size: 72px;
}
.elOrderProductOptinProductName {
  width: 100% !important;
}
.elOrderProductOptinPrice {
  width: 30% !important;
  text-align: right;
}
.elOrderProductOptinProductName.product-name,
.elOrderProductOptinTableProductName {
  width: 70% !important;
}
.elOrderProductOptinPrice.product-price,
.elOrderProductOptinTablePriceName {
  width: 30% !important;
  text-align: right;
}
#product-bump-name {
  width: 70% !important;
  padding-top: 10px;
}
#product-bump-price {
  padding-top: 10px;
}
.gr-textarea-btn {
  display: none !important;
}
.ea-buttonWobble {
  transition: 0.3s;
}
.ea-buttonWobble:hover {
  animation: wobble 0.5s 1;
  animation-timing-function: ease-in-out;
}
@keyframes wobble {
  0% {
    transform: skewX(0deg);
  }
  25% {
    transform: skewX(10deg);
  }
  50% {
    transform: skewX(0deg);
  }
  75% {
    transform: skewX(-10deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
.ea-buttonStretch {
  padding: 8px 50px;
  border-radius: 3px;
  transition: 0.2s;
}
.ea-buttonStretch:hover {
  padding: 10px 100px;
}
.ea-buttonPulseGlow {
  animation: pulseGlow 2s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes pulseGlow {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0px 0px 2.5px 1px rgba(255, 255, 255, 0.25);
  }
  50% {
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.5);
  }
  85% {
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
  }
}
.ea-buttonRocking {
  animation: rocking 2s infinite;
  animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  transition: 0.2s;
}
@keyframes rocking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.ea-buttonRocking:hover {
  animation: none !important;
}
.ea-buttonBounce {
  animation: bounce 1.5s infinite;
  animation-timing-function: ease-in;
  transition: 0.2s;
}
.ea-buttonBounce:hover {
  animation: none !important;
}
@keyframes bounce {
  15% {
    transform: translateY(0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  35% {
    transform: translateY(-35%);
    box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.25);
  }
  45% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  55% {
    transform: translateY(-20%);
    box-shadow: 0px 5px 4px -4px rgba(0, 0, 0, 0.25);
  }
  70% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  80% {
    transform: translateY(-10%);
    box-shadow: 0px 4px 3px -3px rgba(0, 0, 0, 0.25);
  }
  90% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  95% {
    transform: translateY(-2%);
    box-shadow: 0px 2px 3px -3px rgba(0, 0, 0, 0.25);
  }
  99% {
    transform: translateY(0%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateY(0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}
.video-description .midContainer {
  max-width: 100% !important;
}
.email_suggestion {
  margin: 10px;
  display: block;
  text-align: left;
  color: grey;
}
.sf_answer_h_blue .surveyRadioOption:hover,
.sf_answer_h_blue .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #0e8dc7;
}
.sf_answer_h_blue .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_blue
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_blue
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_blue .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_blue
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_blue
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}
.sf_answer_h_green .surveyRadioOption:hover,
.sf_answer_h_green .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #96bd2c;
}
.sf_answer_h_green .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_green
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_green
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_green .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_green
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_green
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}
.sf_answer_h_yellow .surveyRadioOption:hover,
.sf_answer_h_yellow .surveyStepSelectedAnswer {
  color: rgba(66, 21, 21, 0.7);
  background-color: #ffcc33;
}
.sf_answer_h_yellow .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_yellow
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_yellow
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_yellow .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_yellow
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_yellow
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: rgba(66, 21, 21, 0.7) !important;
}
.sf_answer_h_red .surveyRadioOption:hover,
.sf_answer_h_red .surveyStepSelectedAnswer {
  color: #ffffff;
  background-color: rgb(206, 53, 26);
}
.sf_answer_h_red .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_red
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_red
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_red .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_red
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_red
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #ffffff !important;
}
.sf_answer_h_orange .surveyRadioOption:hover,
.sf_answer_h_orange .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #fba30a;
}
.sf_answer_h_orange .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_orange
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_orange
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_orange .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_orange
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_orange
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}
.sf_answer_h_black .surveyRadioOption:hover,
.sf_answer_h_black .surveyStepSelectedAnswer {
  color: #fff;
  background-color: #3d3f47;
}
.sf_answer_h_black .surveyRadioOption:hover .surveyRadioOptionText,
.sf_answer_h_black
  div[data-radio-type="letters"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_black
  div[data-radio-type="numbers"]
  .surveyRadioOption:hover
  input:before,
.sf_answer_h_black .surveyStepSelectedAnswer .surveyRadioOptionText,
.sf_answer_h_black
  div[data-radio-type="letters"]
  .surveyStepSelectedAnswer
  input:before,
.sf_answer_h_black
  div[data-radio-type="numbers"]
  .surveyStepSelectedAnswer
  input:before {
  color: #fff !important;
}
.sf_answer_hs_growshadow .surveyRadioOption:hover,
.sf_answer_hs_growshadow .surveyStepSelectedAnswer {
  transform: scale(1.03);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14),
    inset 0 0 0 2px rgba(255, 255, 255, 0.16) !important;
}
.o2step_step2 .paypal-button {
  margin-top: 20px;
}
#order-declined-message ul {
  list-style: inside;
  margin-bottom: 0px;
}
.socialLikesPreview {
  display: none !important;
}
.socialLikesLive {
  display: block !important;
}
body {
  margin: 0px;
}
html,
body,
.containerWrapper,
.elPageContent,
.elPageContentWrapper {
  height: 100%;
  min-height: 100%;
}
.cf-submitting-page {
  cursor: wait;
  pointer-events: none;
}
.popup-relative-position {
  position: relative;
}
.remove-page-scroll {
  overflow-y: hidden !important;
}
.hide-page-scroll::-webkit-scrollbar {
  display: none;
}
.hide-page-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mailcheck {
  margin: 0;
  text-align: left;
  position: relative;
}
.elCustomHtmlJsWidget {
  display: none;
}
input:-webkit-autofill,
select:-webkit-autofill {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}
.elVideoWrapper[data-video-aspect-ratio="vertical"] .fluid-width-video-wrapper {
  padding-top: 0 !important;
}
.elVideoWrapper[data-video-aspect-ratio="vertical"] iframe {
  position: relative !important;
  height: auto !important;
  aspect-ratio: 9 / 16 !important;
}
.elVideoWrapper[data-video-aspect-ratio="vertical"]
  .fluid-width-video-wrapper
  iframe {
  width: 100% !important;
}

/* projects/user_pages/app/stylesheets/mediaelementplayer-custom.css */
.mejs__overlay-button {
  background: url(./assest/mejs-controls.svg) no-repeat;
}
.mejs__overlay-loading-bg-img {
  background: transparent url(./assest/mejs-controls.svg) -160px -40px no-repeat;
}
.mejs__controls {
  display: flex;
  flex-wrap: wrap;
}

/* projects/user_pages/app/stylesheets/alert_messages.scss */
.alert {
  position: fixed;
  top: 14px;
  right: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.28);
  color: white;
  line-height: 20px;
  animation: alert-fade-out 0.5s 1;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  z-index: 2147483647;
}
.alert.alert-info {
  background-color: #106bf4;
}
.alert.alert-error {
  background-color: #e02424;
}
.alert.alert-success {
  background-color: #009868;
}
@keyframes alert-fade-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-54px);
  }
}

/* projects/lib/packages/yggdrasil-blueprints/__generated__/dist/systemBluprints.min.css */
[data-page-element="CloseIcon/V1"] {
  color: #2d2d2d;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.elSpinnerCircle {
  display: inline-block;
  transform: translateZ(1px);
}
.elSpinnerCircle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: elSpinnerCircle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes elSpinnerCircle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
.elSpinnerDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.elSpinnerDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: elSpinnerDualRing 1.2s linear infinite;
}
@keyframes elSpinnerDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.elSpinnerBars {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerBars div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: elSpinnerBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.elSpinnerBars div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.elSpinnerBars div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.elSpinnerBars div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes elSpinnerBars {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.elSpinnerHeart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.elSpinnerHeart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  animation: elSpinnerHeart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.elSpinnerHeart div:after,
.elSpinnerHeart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fff;
}
.elSpinnerHeart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.elSpinnerHeart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes elSpinnerHeart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
.elSpinnerRing {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  animation: elSpinnerRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 8px solid #fff;
  border-color: #fff transparent transparent transparent;
}
.elSpinnerRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.elSpinnerRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.elSpinnerRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes elSpinnerRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.elSpinnerRoller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerRoller div {
  animation: elSpinnerRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.elSpinnerRoller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.elSpinnerRoller div:nth-child(1) {
  animation-delay: -0.036s;
}
.elSpinnerRoller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.elSpinnerRoller div:nth-child(2) {
  animation-delay: -0.072s;
}
.elSpinnerRoller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.elSpinnerRoller div:nth-child(3) {
  animation-delay: -0.108s;
}
.elSpinnerRoller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.elSpinnerRoller div:nth-child(4) {
  animation-delay: -0.144s;
}
.elSpinnerRoller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.elSpinnerRoller div:nth-child(5) {
  animation-delay: -0.18s;
}
.elSpinnerRoller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.elSpinnerRoller div:nth-child(6) {
  animation-delay: -0.216s;
}
.elSpinnerRoller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.elSpinnerRoller div:nth-child(7) {
  animation-delay: -0.252s;
}
.elSpinnerRoller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.elSpinnerRoller div:nth-child(8) {
  animation-delay: -0.288s;
}
.elSpinnerRoller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes elSpinnerRoller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.elSpinnerDots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerDots div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: elSpinnerDots 1.2s linear infinite;
}
.elSpinnerDots div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.elSpinnerDots div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.elSpinnerDots div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.elSpinnerDots div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.elSpinnerDots div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.elSpinnerDots div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.elSpinnerDots div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.elSpinnerDots div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.elSpinnerDots div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.elSpinnerDots div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.elSpinnerDots div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.elSpinnerDots div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes elSpinnerDots {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.elSpinnerEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.elSpinnerEllipsis div:nth-child(1) {
  left: 8px;
  animation: elSpinnerEllipsis1 0.6s infinite;
}
.elSpinnerEllipsis div:nth-child(2) {
  left: 8px;
  animation: elSpinnerEllipsis2 0.6s infinite;
}
.elSpinnerEllipsis div:nth-child(3) {
  left: 32px;
  animation: elSpinnerEllipsis2 0.6s infinite;
}
.elSpinnerEllipsis div:nth-child(4) {
  left: 56px;
  animation: elSpinnerEllipsis3 0.6s infinite;
}
@keyframes elSpinnerEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes elSpinnerEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes elSpinnerEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.elSpinnerGrid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerGrid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: elSpinnerGrid 1.2s linear infinite;
}
.elSpinnerGrid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.elSpinnerGrid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.elSpinnerGrid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.elSpinnerGrid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.elSpinnerGrid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.elSpinnerGrid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.elSpinnerGrid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.elSpinnerGrid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.elSpinnerGrid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes elSpinnerGrid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.elSpinnerHourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerHourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: elSpinnerHourglass 1.2s infinite;
}
@keyframes elSpinnerHourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.elSpinnerRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerRipple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: elSpinnerRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.elSpinnerRipple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes elSpinnerRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.elSpinnerLongBars {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.elSpinnerLongBars div {
  transform-origin: 40px 40px;
  animation: elSpinnerLongBars 1.2s linear infinite;
}
.elSpinnerLongBars div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.elSpinnerLongBars div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.elSpinnerLongBars div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.elSpinnerLongBars div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.elSpinnerLongBars div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.elSpinnerLongBars div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.elSpinnerLongBars div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.elSpinnerLongBars div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.elSpinnerLongBars div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.elSpinnerLongBars div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.elSpinnerLongBars div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.elSpinnerLongBars div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.elSpinnerLongBars div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes elSpinnerLongBars {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.elSpinnerWrapper[data-loader="true"] {
  display: block;
}
.elSpinnerWrapper {
  --spinner-rotating-color: #ffffff;
  --spinner-still-color: 255, 255, 255;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #777777;
  opacity: 0.4;
}
.elSpinner {
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: load5 1.1s infinite ease;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto !important;
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em var(--spinner-rotating-color),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.5),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.7),
      1.8em -1.8em 0 0em var(--spinner-rotating-color),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.5),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.7),
      2.5em 0em 0 0em var(--spinner-rotating-color),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.5),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.7),
      1.75em 1.75em 0 0em var(--spinner-rotating-color),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.5),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.7),
      0em 2.5em 0 0em var(--spinner-rotating-color),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.5),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.7),
      -1.8em 1.8em 0 0em var(--spinner-rotating-color),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.5),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.7),
      -2.6em 0em 0 0em var(--spinner-rotating-color),
      -1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--spinner-still-color), 0.2),
      1.8em -1.8em 0 0em rgba(var(--spinner-still-color), 0.2),
      2.5em 0em 0 0em rgba(var(--spinner-still-color), 0.2),
      1.75em 1.75em 0 0em rgba(var(--spinner-still-color), 0.2),
      0em 2.5em 0 0em rgba(var(--spinner-still-color), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--spinner-still-color), 0.5),
      -2.6em 0em 0 0em rgba(var(--spinner-still-color), 0.7),
      -1.8em -1.8em 0 0em var(--spinner-rotating-color);
  }
}
.hide {
  display: none !important;
}
[data-page-element="ContentNode"] {
  width: 100%;
  min-height: 100%;
}
[data-page-element="ContentNode"]:before {
  content: "";
  display: table;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.elSelectWrapper {
  position: relative;
}
.elSelectWrapper .elSelectArrow {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
  color: inherit;
  font-size: inherit;
  display: flex;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}
@keyframes disable-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
}
[data-page-element="SectionContainer/V1"] {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
[data-page-element="ColContainer/V1"] {
  padding-left: 0px;
  padding-right: 0px;
}
[data-page-element="ColContainer/V1"] > .col-inner {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-left: 16px;
  margin-right: 16px;
}
[data-page-element="ColContainer/V1"] > .col-inner:before {
  content: "";
  display: table;
}
[data-page-element="ColContainer/V1"] > .col-inner:after {
  content: "";
  display: table;
}
[data-page-element="FlexContainer/V1"] {
  display: flex;
  justify-content: center;
  gap: 1.5em;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
[data-page-element="FlexContainer/V1"].elFlexNoWrap {
  flex-wrap: nowrap;
}
[data-page-element="FlexContainer/V1"].elFlexWrap {
  flex-wrap: wrap;
}
[data-page-element="FlexContainer/V1"].elFlexWrapReverse {
  flex-wrap: wrap-reverse;
}
[data-page-element="FlexContainer/V1"] div {
  margin: 0px;
}
@media (max-width: 770px) {
  [data-page-element="FlexContainer/V1"].elFlexNoWrapMobile {
    flex-wrap: nowrap;
  }
  [data-page-element="FlexContainer/V1"].elFlexWrapMobile {
    flex-wrap: wrap;
  }
  [data-page-element="FlexContainer/V1"].elFlexWrapReverseMobile {
    flex-wrap: wrap-reverse;
  }
}
[data-page-element="RowContainer/V1"] {
  /* padding-top: 20px; */
  padding-bottom: 20px;
  max-width: 100%;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}
[data-page-element="RowContainer/V1"] .col-inner {
  height: 100%;
}
[data-page-element="CollectionContainer/V1"] {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
  max-width: 100%;
  width: 100%;
}
[data-page-element="CollectionContainer/V1"] .innerCollectionContent > * {
  height: 100%;
}
@media (max-width: 770px) {
  [data-page-element="CollectionContainer/V1"] {
    grid-template-columns: 1fr;
  }
}
[data-page-element="Modal/V1"] {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: none;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.4);
}
[data-page-element="Modal/V1"] .elModalInnerContainer {
  z-index: 100001;
  cursor: auto;
  position: relative;
}
[data-page-element="ModalSidebar/V1"] {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  font-family: var(--text-font-family);
  margin-left: 0px;
  margin-right: 0px;
  background: var(--backdrop-color);
}
[data-page-element="ModalSidebar/V1"] .elModalSidebar {
  display: flex;
  width: 550px;
  height: 100%;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.25s ease;
  margin-left: 0px;
  margin-right: 0px;
  background: var(--container-color);
}
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 32px;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-style: solid;
  border-color: var(--divider-color);
  border-width: 0px;
  border-bottom-width: var(--divider-height);
}
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader span {
  font-size: 16px;
  font-weight: 700;
}
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader i {
  font-size: 16px;
  color: black;
}
[data-page-element="ModalSidebar/V1"] .elModalSidebarHeader a {
  text-decoration: none;
  margin-left: 0px;
  margin-right: 0px;
}
[data-page-element="ModalSidebar/V1"] .elModalSidebarBody {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 36px;
  padding-bottom: 36px;
}
[data-page-element="ModalSidebar/V1"] .removeSlideAnimation {
  transform: none !important;
}
[data-page-element="ModalSidebar/V1"] .elModalSidebarSlideAnimation {
  transform: translateX(0%);
}
@media (max-width: 768px) {
  [data-page-element="ModalSidebar/V1"] .elModalSidebar {
    width: 100%;
    height: 85%;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    transform: translateY(100%);
  }
  [data-page-element="ModalSidebar/V1"] .elModalSidebarSlideAnimation {
    transform: translateY(0%);
  }
}
[data-page-element="ModalContainer/V1"] .modal-wrapper {
  z-index: 60;
  opacity: 0;
  top: -800px;
}
[data-page-element="ModalContainer/V1"] .containerModal {
  display: block;
  position: relative;
  padding-top: 41px;
  padding-bottom: 24px;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
  z-index: 222;
  overflow: visible !important;
  padding-left: 20px;
  padding-right: 20px;
  background: #fff;
}
[data-page-element="ModalContainer/V1"] .closeLPModal {
  display: flex;
  z-index: 9999;
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
  background: #031a01;
}
[data-page-element="ModalContainer/V1"] .elModalInnerContainer {
  max-width: 100%;
  width: 50%;
}
@media (max-width: 770px) {
  [data-page-element="ModalContainer/V1"] .elModalInnerContainer {
    max-width: 100%;
    width: 80%;
  }
}
[data-page-element="Headline/V1"] .elHeadline {
  text-align: center;
  font-size: 32px;
}
[data-page-element="Headline/V1"].truncateByLine .elHeadline {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
[data-page-element="SubHeadline/V1"] .elSubheadline {
  text-align: center;
  font-size: 22px;
}
[data-page-element="Paragraph/V1"] .elParagraph {
  text-align: center;
}
[data-page-element="Paragraph/V1"].truncateByLine span {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
[data-page-element="BulletList/V1"] li:not(:first-child) {
  margin-top: 10px;
}
[data-page-element="BulletList/V1"] .elBulletList li {
  display: flex;
}
[data-page-element="BulletList/V1"] .elBulletList .fa_icon {
  margin-right: 10px;
  line-height: inherit;
  margin-left: 0px;
  flex-shrink: 0;
}
[data-page-element="BulletList/V1"] .elBulletListTextWrapper {
  min-width: 5px;
}
.elBulletList[data-list-type="ordered"] ul {
  counter-reset: li;
}
.elBulletList[data-list-type="ordered"] li {
  counter-increment: li;
}
.elBulletList[data-list-type="ordered"] li i {
  font-family: inherit;
}
.elBulletList[data-list-type="ordered"] li i.fa::before,
.elBulletList[data-list-type="ordered"] li i.far::before,
.elBulletList[data-list-type="ordered"] li i.fas::before,
.elBulletList[data-list-type="ordered"] li i.fab::before,
.elBulletList[data-list-type="ordered"] li i.fad::before {
  content: counter(li) ".";
  display: inline-block;
  font-family: inherit;
}
[data-page-element="Image/V1"] {
  text-align: center;
  margin-top: 15px;
}
[data-page-element="Image/V1"] .elImage {
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
  height: auto;
  aspect-ratio: auto;
  box-sizing: border-box;
}
[data-page-element="Image/V2"] {
  text-align: center;
  margin-top: 15px;
  /* width: 222px;
  height: 100px; */
}
[data-page-element="Image/V2"] .elImage {
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
  height: auto;
  aspect-ratio: auto;
  box-sizing: border-box;
}
.image-placeholder {
  height: 200px;
  background-image: url(./assest/editor-placeholder-image.svg);
  background-size: 100% 100%;
  padding: 15px;
  text-align: center;
  cursor: help;
}
.image-placeholder-header {
  color: grey;
}
[data-page-element="Button/V1"] {
  margin-top: 15px;
  text-align: center;
}
[data-page-element="Button/V1"] .fa_prepended.fa_prepended,
[data-page-element="Button/V1"] .fa_apended.fa_apended {
  color: inherit;
}
[data-page-element="Button/V1"] .elButton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: 600;
  transition: background 0.2s ease;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #d4af37;
}
[data-page-element="Button/V1"] .elButton .elButtonText {
  font-size: 1.25rem;
  line-height: 100%;
  color: rgb(255, 255, 255);
  transition: color 0.2s ease;
}
[data-page-element="Button/V1"] .elButton .elButtonSub {
  font-size: 0.875rem;
  line-height: 100%;
  opacity: 0.8;
  color: rgb(255, 255, 255);
  transition: color 0.2s ease;
}
[data-page-element="Button/V1"] .elButton:hover,
[data-page-element="Button/V1"] .elButton.elButtonHovered {
  background: rgb(253, 194, 0);
}
[data-page-element="Button/V1"] .elButton:hover .elButtonText,
[data-page-element="Button/V1"] .elButton.elButtonHovered .elButtonText {
  color: rgb(255, 255, 255);
}
[data-page-element="Button/V1"] .elButton:hover .elButtonSub,
[data-page-element="Button/V1"] .elButton.elButtonHovered .elButtonSub {
  color: rgb(255, 255, 255);
}
[data-page-element="Button/V1"] .elButton:active,
[data-page-element="Button/V1"] .elButton.elButtonActive {
  background: rgb(253, 194, 0);
}
[data-page-element="Button/V1"] .elButton:active .elButtonText,
[data-page-element="Button/V1"] .elButton.elButtonActive .elButtonText {
  color: rgb(255, 255, 255);
}
[data-page-element="Button/V1"] .elButton:active .elButtonSub,
[data-page-element="Button/V1"] .elButton.elButtonActive .elButtonSub {
  color: rgb(255, 255, 255);
}
[data-page-element="Button/V1"]
  .elButtonSpinner.elButtonSpinner.elButtonSpinner.elButtonSpinner {
  display: none;
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  letter-spacing: 0;
  line-height: 0;
  text-decoration: none;
}
@media (max-width: 770px) {
  [data-page-element="Button/V1"] .elButton {
    max-width: 100%;
  }
}
.elButton {
  position: relative;
}
.elButton[data-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
}
.elButton:hover {
  text-decoration: none;
}
.elButtonSub {
  display: block;
}
.elButtonSub:empty {
  display: none;
}
[data-page-element="SelectBox/V1"] {
  margin-top: 15px;
}
[data-page-element="SelectBox/V1"] .elSelectLabel {
  position: relative;
  font-size: 14px;
  color: rgb(21, 21, 23);
}
[data-page-element="SelectBox/V1"] .elSelect {
  font-family: sans-serif;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  background-position: 97%;
  box-shadow: inset 0 1px 2px rgba(130, 137, 150, 0.23),
    0 1px 0 rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 12px;
  padding-right: 12px;
  background: #ffffff;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;
}
[data-page-element="SelectBox/V1"] .elSelect:focus {
  border-color: #42b0e2;
}
[data-page-element="SelectBox/V2"] {
  margin-top: 15px;
  background-position: 97%;
  font-family: inherit;
  position: relative;
  padding: 0 !important;
  --label-padding-on-focus: 4px;
  border-style: none;
}
[data-page-element="SelectBox/V2"] .elInputFocused [data-input-status-type],
[data-page-element="SelectBox/V2"] .hasValue [data-input-status-type] {
  position: relative !important;
}
[data-page-element="SelectBox/V2"] [data-input-status-type] {
  position: initial;
  border-style: none;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 5px;
  top: -10px;
}
[data-page-element="SelectBox/V2"] .elLabel {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  border-radius: inherit;
  display: flex;
  border-width: inherit;
}
[data-page-element="SelectBox/V2"] .inputHolder {
  border-radius: inherit;
  border-color: transparent !important;
}
[data-page-element="SelectBox/V2"] label {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  display: block;
  line-height: 18px;
  transition: all 0.2s ease;
  border-bottom-color: transparent !important;
}
[data-page-element="SelectBox/V2"].elInputFocused .labelText,
[data-page-element="SelectBox/V2"].hasValue .labelText {
  font-size: 12px;
}
[data-page-element="SelectBox/V2"].hasValue .elSelect {
  opacity: 1;
}
[data-page-element="SelectBox/V2"] .elSelect {
  width: 100%;
  font-size: 16px;
  border-style: none;
  background: transparent;
  outline: 0;
  opacity: 0;
}
[data-page-element="SelectBox/V2"] .elLabel,
[data-page-element="SelectBox/V2"] .elSelect {
  text-align: left;
}
[data-page-element="SelectBox/V2"].labelInside .borderHolder {
  display: none;
}
[data-page-element="SelectBox/V2"].labelInside .labelText {
  display: block;
  width: 100%;
}
[data-page-element="SelectBox/V2"].labelInside.elInputFocused label,
[data-page-element="SelectBox/V2"].labelInside.hasValue label {
  top: 0px;
}
[data-page-element="SelectBox/V2"].labelInside.elInputFocused .elSelect,
[data-page-element="SelectBox/V2"].labelInside.hasValue .elSelect {
  transform: translateY(15%);
}
[data-page-element="SelectBox/V2"].labelInside.elInputFocused .labelText {
  font-size: 10px;
}
[data-page-element="SelectBox/V2"].labelInside.hasValue .labelText {
  font-size: 10px;
}
[data-page-element="SelectBox/V2"].labelOnBorder .labelText {
  flex: 0 0 auto;
  width: auto;
  color: rgb(21, 21, 23);
  padding-left: var(--label-padding-on-focus) !important;
  padding-right: var(--label-padding-on-focus) !important;
  border-right: none !important;
  border-left: none !important;
  flex-grow: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0 !important;
  font-size: 14px;
}
[data-page-element="SelectBox/V2"].labelOnBorder.elInputFocused .labelText {
  font-size: 12px;
}
[data-page-element="SelectBox/V2"].labelOnBorder.hasValue .labelText {
  font-size: 12px;
}
[data-page-element="SelectBox/V2"].labelOnBorder .borderHolder:last-child {
  border-left: none !important;
  flex-grow: 1;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
[data-page-element="SelectBox/V2"].labelOnBorder .borderHolder:first-child {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-page-element="SelectBox/V2"].labelOnBorder
  .elLabel
  .borderHolder:first-child {
  padding-right: 0 !important;
}
[data-page-element="SelectBox/V2"].labelOnBorder.elInputFocused .labelText,
[data-page-element="SelectBox/V2"].labelOnBorder.hasValue .labelText {
  border-top-color: transparent !important;
}
[data-page-element="SelectBox/V2"].labelOnBorder.elInputFocused label,
[data-page-element="SelectBox/V2"].labelOnBorder.hasValue label {
  top: 0px;
  transform: translateY(-50%);
  border-bottom: inherit;
}
[data-page-element="Audio/V1"] {
  margin-top: 15px;
  width: 100%;
}
.elAudio {
  position: relative;
  overflow: hidden;
}
.elAudio audio {
  position: relative;
  top: 0;
  left: 0;
  width: 100% !important;
  box-sizing: border-box;
  z-index: 3;
}
[data-page-element="Input/V1"] {
  margin: 0px auto;
  margin-top: 15px;
  font-family: inherit;
  position: relative;
  padding: 0 !important;
  --label-padding-on-focus: 4px;
  border: 0 !important;
}
[data-page-element="Input/V1"] [data-input-status-type] {
  position: initial !important;
  border: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 2px;
  top: -10px;
}
[data-page-element="Input/V1"] .elLabel {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
  border-radius: inherit;
  display: flex;
  border-width: inherit;
}
[data-page-element="Input/V1"] label {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  display: block;
  transition: all 0.2s ease;
  border-bottom-color: transparent !important;
}
[data-page-element="Input/V1"] .borderHolder {
  margin: 0 !important;
  box-shadow: none !important;
  background: #ffffff;
}
[data-page-element="Input/V1"] .inputHolder {
  background: transparent;
  border-radius: inherit;
  border-color: transparent !important;
}
[data-page-element="Input/V1"] .elInput {
  width: 100%;
  font-size: 12px;
  background: transparent;
  border: 0;
  z-index: 1;
  position: relative;
}
[data-page-element="Input/V1"] .elInput::-moz-placeholder {
  font-size: 12px;
  color: rgb(96, 96, 107, 80%);
  opacity: 0;
}
[data-page-element="Input/V1"] .elInput::placeholder {
  font-size: 12px;
  color: rgb(96, 96, 107, 80%);
  opacity: 0;
}
[data-page-element="Input/V1"].elInputFocused .elInput::-moz-placeholder,
[data-page-element="Input/V1"].hasValue .elInput::-moz-placeholder {
  opacity: 1;
}
[data-page-element="Input/V1"].elInputFocused .elInput::placeholder,
[data-page-element="Input/V1"].hasValue .elInput::placeholder {
  opacity: 1;
}
[data-page-element="Input/V1"] .elLabel,
[data-page-element="Input/V1"] .elInput {
  text-align: left;
}
[data-page-element="Input/V1"].labelInside .borderHolder {
  display: none;
}
[data-page-element="Input/V1"].labelInside .labelText {
  display: block;
  width: 100%;
}
[data-page-element="Input/V1"].labelInside.elInputFocused label,
[data-page-element="Input/V1"].labelInside.hasValue label {
  top: 0px;
}
[data-page-element="Input/V1"].labelInside.elInputFocused .elInput,
[data-page-element="Input/V1"].labelInside.hasValue .elInput {
  transform: translateY(15%);
}
[data-page-element="Input/V1"].labelInside.elInputFocused .labelText {
  font-size: 10px;
}
[data-page-element="Input/V1"].labelInside.hasValue .labelText {
  font-size: 10px;
}
[data-page-element="Input/V1"].labelOnBorder .labelText {
  flex: 0 0 auto;
  width: auto;
  color: rgb(21, 21, 23);
  padding-left: var(--label-padding-on-focus) !important;
  padding-right: var(--label-padding-on-focus) !important;
  border-right: none !important;
  border-left: none !important;
  flex-grow: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0 !important;
  font-size: 14px;
}
[data-page-element="Input/V1"].labelOnBorder.elInputFocused .labelText {
  font-size: 12px;
}
[data-page-element="Input/V1"].labelOnBorder.hasValue .labelText {
  font-size: 12px;
}
[data-page-element="Input/V1"].labelOnBorder .borderHolder:last-child {
  border-left: none !important;
  flex-grow: 1;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
[data-page-element="Input/V1"].labelOnBorder .borderHolder:first-child {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-page-element="Input/V1"].labelOnBorder
  .elLabel
  .borderHolder:first-child {
  padding-right: 0 !important;
}
[data-page-element="Input/V1"].labelOnBorder.elInputFocused .labelText,
[data-page-element="Input/V1"].labelOnBorder.hasValue .labelText {
  border-top-color: transparent !important;
}
[data-page-element="Input/V1"].labelOnBorder.elInputFocused label,
[data-page-element="Input/V1"].labelOnBorder.hasValue label {
  top: 0px;
  transform: translateY(-50%);
  border-bottom: inherit;
}
[data-page-element="Input/V1"] .input-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  border: 1px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  display: none;
  align-items: center;
  justify-content: center;
}
[data-page-element="Input/V1"] .input-icon i {
  font-size: 10px;
}
[data-page-element="Input/V1"] .iti--allow-dropdown .iti__selected-flag:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
[data-page-element="Input/V1"] .iti__flag-container {
  z-index: 10;
}
[data-page-element="Input/V1"] .iti {
  width: 100%;
}
[data-page-element="Input/V1"].labelOnBorder
  .iti
  ~ .elLabel
  .borderHolder:first-child {
  padding-left: 50px !important;
}
[data-page-element="Input/V1"].labelInside .iti ~ .elLabel .labelText {
  padding-left: 50px;
}
[data-page-element="Input/V1"] [data-intl-tel-input-id] {
  padding-left: 50px !important;
}
[data-page-element="Checkbox/V1"][data-prevent-click-on-label="true"]
  .elCheckboxLabel {
  cursor: initial;
}
[data-page-element="Checkbox/V1"] .elCheckboxLabel {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 0.5em;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
[data-page-element="Checkbox/V1"].elCheckboxWrapper
  .elCheckboxInput.elCheckboxInput.inputHolder {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0px;
  width: 0px;
  pointer-events: none;
}
[data-page-element="Checkbox/V1"] .elCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px;
  background-color: #fff;
  font-size: 12px;
  width: 2em;
  min-width: 2em;
  height: 2em;
  min-height: 2em;
  border-style: solid;
  border-color: #ccc;
  border-width: 1px;
}
[data-page-element="Checkbox/V1"]
  .elCheckboxLabel
  .elCheckboxInput:checked
  ~ .elCheckbox {
  background-color: #2196f3;
  border-radius: 0px;
  border-style: solid;
  border-color: #eee;
  border-width: 1px;
}
[data-page-element="Checkbox/V1"] .elCheckboxIcon {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
[data-page-element="Checkbox/V1"] .elCheckboxIconCheck {
  font-size: inherit;
  color: #fff;
  line-height: 100%;
}
[data-page-element="Checkbox/V1"] .elCheckboxIconSquare {
  font-size: inherit;
  width: 1em;
  height: 1em;
  background-color: #fff;
}
[data-page-element="Checkbox/V1"] .elCheckboxText {
  font-size: 14px;
  color: #60606b;
  width: 100%;
}
[data-page-element="Checkbox/V1"]
  .elCheckboxInput:checked
  ~ .elCheckbox
  .elCheckboxIcon {
  display: flex !important;
}
.hide {
  display: none !important;
}
[data-page-element="Radio/V1"] {
  margin: 0px;
}
[data-page-element="Radio/V1"] .elRadioLabel {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
[data-page-element="Radio/V1"] .elRadioInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0px;
  margin: 0px;
  width: 0px;
}
[data-page-element="Radio/V1"] .elRadio {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 2em;
  min-height: 2em;
  width: 2em;
  min-width: 2em;
  font-size: 14px;
  border-radius: 50%;
  background-color: #fff;
  border-style: solid;
  border-color: #939393;
  border-width: 1px;
}
[data-page-element="Radio/V1"] .elRadioLabel .elRadioInput:checked ~ .elRadio {
  background-color: #fff;
  border-radius: 50%;
  border-style: solid;
  border-color: #2196f3;
  border-width: 1px;
}
[data-page-element="Radio/V1"] .elRadioIcon {
  width: 1em;
  height: 1em;
  font-size: inherit;
  border-radius: 50%;
  vertical-align: middle;
  background-color: #2196f3;
  display: none;
  margin: 0px;
}
[data-page-element="Radio/V1"] .elRadioInput:checked ~ .elRadio .elRadioIcon {
  display: block !important;
}
[data-page-element="Radio/V1"] .elRadioImage {
  max-height: 100px;
}
[data-page-element="SuperSelectBox/V1"] {
  background-color: #fff;
  background-position: 97%;
  font-family: inherit;
  padding: 12px 18px;
}
[data-page-element="SuperSelectBox/V1"].elInputFocused {
  border-color: #42b0e2;
}
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabel {
  display: flex;
  flex-direction: row;
}
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabelText {
  min-width: -moz-fit-content;
  min-width: fit-content;
}
[data-page-element="SuperSelectBox/V1"] .elSuperSelectIcon,
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabelText,
[data-page-element="SuperSelectBox/V1"] .elSuperSelect {
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: inherit;
}
[data-page-element="SuperSelectBox/V1"] .elSuperSelectIcon,
[data-page-element="SuperSelectBox/V1"] .elSuperSelectLabelText {
  margin-left: 10px;
  margin-right: 10px;
}
[data-page-element="SuperSelectBox/V1"] .elSuperSelect {
  width: 100%;
  flex: auto;
  text-align: right;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: inherit;
  padding-right: 20px;
  border-style: none;
  background: transparent;
  outline: 0;
}
[data-page-element="SuperSelectBox/V1"] .elSuperSelect:focus {
  border-color: transparent;
}
[data-page-element="Icon/V1"] .iconElement {
  text-align: center;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: inherit;
}
[data-page-element="Icon/V1"] .fa_icon {
  font-size: 55px;
}
[data-page-element="Divider/V1"] {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-basis: 100%;
  align-self: stretch;
}
[data-page-element="Divider/V1"] .elDivider {
  width: 100%;
  min-height: 1px;
  margin: 0px;
  border-style: solid;
  border-top: 1px solid;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
[data-page-element="Link/V1"] {
  text-align: center;
}
[data-page-element="CustomHtmlJs/V1"] {
  margin-top: 15px;
}
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbs {
  display: flex;
  max-width: 100%;
}
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbItem {
  display: flex;
  margin: 0px;
  color: #4b5563;
}
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbItemActive {
  display: flex;
  margin: 0px;
  color: #3b82f6;
}
[data-page-element="Breadcrumbs/V1"] .elBreadcrumbDivider {
  margin-left: 10px;
  margin-right: 10px;
}
[data-page-element="ProgressBar/V1"] {
  margin-top: 12px;
}
[data-page-element="ProgressBar/V1"] .progress {
  overflow: hidden;
  background: #fcfcfc;
}
[data-page-element="ProgressBar/V1"] .progress-bar {
  height: 28px;
  margin: 0px;
  transition: width 0.2s ease 0s;
  display: flex;
  align-items: center;
  background: #307ced;
}
[data-page-element="ProgressBar/V1"] .progress-label {
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  padding: 0 8px;
}
[data-page-element="ProgressBar/V1"] > .progress-label {
  color: #353535;
}
[data-page-element="TextArea/V1"] {
  margin: 0px auto;
  margin-top: 15px;
  font-family: inherit;
  position: relative;
  padding: 0 !important;
  --label-padding-on-focus: 4px;
  border: 0 !important;
  width: 100%;
}
[data-page-element="TextArea/V1"] .elTextarea {
  font-family: inherit;
  width: 100%;
  height: 120px;
  font-size: 12px;
  background: transparent;
  border: 0;
  vertical-align: middle;
  resize: none;
  z-index: 1;
  position: relative;
}
[data-page-element="TextArea/V1"] .elTextarea::-moz-placeholder {
  opacity: 0;
  font-size: 12px;
  color: rgb(96, 96, 107, 80%);
}
[data-page-element="TextArea/V1"] .elTextarea::placeholder {
  opacity: 0;
  font-size: 12px;
  color: rgb(96, 96, 107, 80%);
}
[data-page-element="TextArea/V1"] .elLabel {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
  border-radius: inherit;
  display: flex;
  border-width: inherit;
}
[data-page-element="TextArea/V1"] label {
  top: 0;
  position: relative;
  transform: translateY(-50%);
  display: block;
  transition: all 0.2s ease;
  padding-top: inherit;
  border-bottom-color: transparent !important;
}
[data-page-element="TextArea/V1"] .borderHolder {
  margin: 0 !important;
  box-shadow: none !important;
  background: #ffffff;
}
[data-page-element="TextArea/V1"] .inputHolder {
  background: transparent;
  border-radius: inherit;
  padding-bottom: 0px;
  padding-top: 0px;
  border-color: transparent !important;
}
[data-page-element="TextArea/V1"] div.inputHolder {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
[data-page-element="TextArea/V1"].elInputFocused .elTextarea::-moz-placeholder,
[data-page-element="TextArea/V1"].hasValue .elTextarea::-moz-placeholder {
  opacity: 1;
}
[data-page-element="TextArea/V1"].elInputFocused .elTextarea::placeholder,
[data-page-element="TextArea/V1"].hasValue .elTextarea::placeholder {
  opacity: 1;
}
[data-page-element="TextArea/V1"] .elLabel,
[data-page-element="TextArea/V1"] .elTextarea {
  text-align: left;
}
[data-page-element="TextArea/V1"].labelInside .borderHolder {
  display: none;
}
[data-page-element="TextArea/V1"].labelInside .labelText {
  display: block;
  width: 100%;
}
[data-page-element="TextArea/V1"].labelInside.elInputFocused label,
[data-page-element="TextArea/V1"].labelInside.hasValue label {
  top: 0px;
}
[data-page-element="TextArea/V1"].labelInside.elInputFocused .elInput,
[data-page-element="TextArea/V1"].labelInside.hasValue .elInput {
  transform: translateY(15%);
}
[data-page-element="TextArea/V1"].labelInside.elInputFocused .labelText {
  font-size: 10px;
}
[data-page-element="TextArea/V1"].labelInside.hasValue .labelText {
  font-size: 10px;
}
[data-page-element="TextArea/V1"].labelOnBorder .labelText {
  flex: 0 0 auto;
  width: auto;
  color: rgb(21, 21, 23);
  padding-left: var(--label-padding-on-focus) !important;
  padding-right: var(--label-padding-on-focus) !important;
  border-right: none !important;
  border-left: none !important;
  flex-grow: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0 !important;
  font-size: 14px;
}
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused .labelText {
  font-size: 12px;
}
[data-page-element="TextArea/V1"].labelOnBorder.hasValue .labelText {
  font-size: 12px;
}
[data-page-element="TextArea/V1"].labelOnBorder .borderHolder:last-child {
  border-left: none !important;
  flex-grow: 1;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
[data-page-element="TextArea/V1"].labelOnBorder .borderHolder:first-child {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-page-element="TextArea/V1"].labelOnBorder
  .elLabel
  .borderHolder:first-child {
  padding-right: 0 !important;
}
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused .borderHolder,
[data-page-element="TextArea/V1"].labelOnBorder.hasValue .borderHolder {
  padding-top: 0 !important;
}
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused .labelText,
[data-page-element="TextArea/V1"].labelOnBorder.hasValue .labelText {
  border-top-color: transparent !important;
}
[data-page-element="TextArea/V1"].labelOnBorder.elInputFocused label,
[data-page-element="TextArea/V1"].labelOnBorder.hasValue label {
  top: 0px;
  border-bottom: inherit;
}
[data-page-element="AddToCart"] {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: var(--text-font-family);
  padding-left: 15px;
  padding-right: 15px;
}
[data-page-element="AddToCart"] .elAddToCartButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
[data-page-element="AddToCart"] [data-page-element="Button/V1"] {
  margin: 0px;
}
[data-page-element="AddToCart"] [data-page-element="Button/V1"] .elButton {
  width: 100%;
}
[data-page-element="AddToCart"] .elAddToCartButtonExtraSmall .elButton {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
[data-page-element="AddToCart"]
  .elAddToCartButtonExtraSmall
  .elButton
  .elButtonText {
  font-size: 12px;
}
[data-page-element="AddToCart"] .elAddToCartButtonSmall .elButton {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-page-element="AddToCart"]
  .elAddToCartButtonSmall
  .elButton
  .elButtonText {
  font-size: 13px;
}
[data-page-element="AddToCart"] .elAddToCartButtonMedium .elButton {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
[data-page-element="AddToCart"]
  .elAddToCartButtonMedium
  .elButton
  .elButtonText {
  font-size: 13px;
}
[data-page-element="AddToCart"] .elAddToCartButtonLarge .elButton {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 14px;
  padding-bottom: 14px;
}
[data-page-element="AddToCart"]
  .elAddToCartButtonLarge
  .elButton
  .elButtonText {
  font-size: 14px;
}
[data-page-element="AddToCart"] .elAddToCartButtonExtraLarge .elButton {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
}
[data-page-element="AddToCart"]
  .elAddToCartButtonExtraLarge
  .elButton
  .elButtonText {
  font-size: 14px;
}
[data-page-element="AddToCart"] .elAddToCartAddToCartButton .elButton {
  background: var(--button-primary-background-color);
}
[data-page-element="AddToCart"] .elAddToCartAddToCartButton .elButtonText {
  color: var(--button-primary-text-color);
}
[data-page-element="AddToCart"]
  .elAddToCartAddToCartButton
  .elButton:hover
  .elButtonText,
[data-page-element="AddToCart"]
  .elAddToCartAddToCartButton
  .elButton.elButtonHovered
  .elButtonText {
  color: var(--button-primary-text-color);
}
[data-page-element="AddToCart"] .elAddToCartBuyNowButton .elButton {
  background: transparent;
  border-style: solid;
  border-color: var(--button-secondary-color);
  border-width: 1px;
}
[data-page-element="AddToCart"] .elAddToCartBuyNowButton .elButtonText {
  color: var(--button-secondary-color);
}
[data-page-element="AddToCart"]
  .elAddToCartBuyNowButton
  .elButton:hover
  .elButtonText,
[data-page-element="AddToCart"]
  .elAddToCartBuyNowButton
  .elButton.elButtonHovered
  .elButtonText {
  color: var(--button-secondary-color);
}
[data-page-element="AddToCart"] .elAddToCartSinglePriceWrapper {
  margin: 0px;
  display: flex;
  gap: 10px;
}
[data-page-element="AddToCart"] .elSelectWrapper {
  margin: 0px;
}
[data-page-element="AddToCart"] .elSelectWrapper select {
  color: var(--input-text-color);
  font-family: var(--text-font-family);
  border-color: var(--input-border-color);
  border-style: solid;
  border-width: 1px;
}
[data-page-element="AddToCart"] .elSelectArrow {
  color: var(--input-text-color);
}
[data-page-element="AddToCart"] .elAddToCartInputExtraSmall select {
  padding-left: 10px;
  padding-right: 35px;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}
[data-page-element="AddToCart"] .elAddToCartInputExtraSmall .elSelectArrow {
  font-size: 13px;
}
[data-page-element="AddToCart"] .elAddToCartInputSmall select {
  padding-left: 10px;
  padding-right: 35px;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-page-element="AddToCart"] .elAddToCartInputSmall .elSelectArrow {
  font-size: 13px;
}
[data-page-element="AddToCart"] .elAddToCartInputMedium select {
  padding-left: 10px;
  padding-right: 35px;
  font-size: 13px;
  padding-top: 12px;
  padding-bottom: 12px;
}
[data-page-element="AddToCart"] .elAddToCartInputMedium .elSelectArrow {
  font-size: 14px;
}
[data-page-element="AddToCart"] .elAddToCartInputLarge select {
  padding-left: 12px;
  padding-right: 40px;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
}
[data-page-element="AddToCart"] .elAddToCartInputLarge .elSelectArrow {
  font-size: 15px;
  right: 14px;
}
[data-page-element="AddToCart"] .elAddToCartInputExtraLarge select {
  padding-left: 14px;
  padding-right: 40px;
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
}
[data-page-element="AddToCart"] .elAddToCartInputExtraLarge .elSelectArrow {
  font-size: 15px;
  right: 14px;
}
[data-page-element="AddToCart"] .elAddToCartVariantSelectorWrapper {
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
[data-page-element="AddToCart"] .elAddToCartVariantSelectorWrapper select {
  width: 100%;
}
[data-page-element="AddToCart"] .elAddToCartName {
  font-size: 28px;
  color: var(--style-headline-color, #000);
  font-weight: 600;
}
[data-page-element="AddToCart"] .elAddToCartPrice {
  color: var(--style-subheadline-color, #000);
}
[data-page-element="AddToCart"] .elAddToCartCompareAt {
  color: var(--style-subheadline-color, rgb(0, 0, 0, 0.5));
  text-decoration: line-through;
}
[data-page-element="AddToCart"] .elAddToCartPrice,
[data-page-element="AddToCart"] .elAddToCartCompareAt {
  font-size: 18px;
}
[data-page-element="AddToCart"] .elAddToCartDescription {
  font-size: 14px;
  color: var(--style-color, #000);
}
[data-page-element="AddToCart"] .elAddToCartVariantSelectorTitle {
  font-size: 14px;
  color: var(--style-link-color, #000);
  display: block;
  margin-bottom: 5px;
}
.elAddToCart {
  --button-primary-background-color: var(--style-link-color, #000);
  --button-primary-text-color: #fff;
  --button-secondary-color: var(--style-link-color, #000);
  --input-text-color: var(--style-link-color, #000);
  --input-border-color: var(--style-link-color, #000);
  --text-font-family: sans-serif;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="CoursePodcastLink/V1"] {
  text-align: center;
}
[data-page-element="CoursePodcastLink/V1"] .elImageWrapper {
  padding: 0px;
}
[data-page-element="CoursePodcastLink/V1"] .elPodcastLink:hover {
  text-decoration: none;
}
[data-page-element="CoursePodcastLink/V1"] .elPodcastLink {
  display: inline-flex;
  color: black;
}
[data-page-element="CoursePodcastLink/V1"] .elPodcastLinkText {
  margin-left: 5px;
  display: -webkit-inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  -webkit-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-flex-align: center;
  -webkit-align-items: center;
  vertical-align: top;
}
[data-page-element="CoursePodcastLink/V1"] .elPodcastLinkImage {
  display: inline-block;
  width: 30px;
  margin-top: 0px;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="CoursesSectionPodcastLink/V1"] {
  text-align: center;
}
[data-page-element="CoursesSectionPodcastLink/V1"] .elImageWrapper {
  padding: 0px;
}
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLink:hover {
  text-decoration: none;
}
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLink {
  display: inline-flex;
  color: black;
}
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLinkText {
  margin-left: 5px;
  display: -webkit-inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  -webkit-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-flex-align: center;
  -webkit-align-items: center;
  vertical-align: top;
}
[data-page-element="CoursesSectionPodcastLink/V1"] .elPodcastLinkImage {
  display: inline-block;
  width: 30px;
  margin-top: 0px;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="Image/V2"][data-dynamic-image] .elImage {
  aspect-ratio: 16 / 9;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  -o-object-position: center;
  object-position: center;
}
[data-page-element="ProductsCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="ProductsCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="ProductsCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="ProductsCollection/V1"] .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="ProductsCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="ProductsCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItemActive,
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItemNotActive,
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="ProductCollectionsCollection/V1"]
  .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="ProductCollectionsCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="ProductCollectionsCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemActive,
[data-page-element="BlogPostsCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="BlogPostsCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="BlogPostsCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="BlogPostsCollection/V1"]
  .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="BlogPostsCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="BlogPostsCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="BlogCategoriesCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="BlogCategoriesCollection/V1"]
  .collectionPaginationItemActive,
[data-page-element="BlogCategoriesCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="BlogCategoriesCollection/V1"]
  .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="BlogCategoriesCollection/V1"]
  .collectionPaginationItemNotActive,
[data-page-element="BlogCategoriesCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="BlogCategoriesCollection/V1"]
  .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="BlogCategoriesCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="BlogCategoriesCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="GrantedAssetsCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="GrantedAssetsCollection/V1"]
  .collectionPaginationItemActive,
[data-page-element="GrantedAssetsCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="GrantedAssetsCollection/V1"]
  .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="GrantedAssetsCollection/V1"]
  .collectionPaginationItemNotActive,
[data-page-element="GrantedAssetsCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="GrantedAssetsCollection/V1"]
  .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="GrantedAssetsCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="GrantedAssetsCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="CoursesCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemActive,
[data-page-element="CoursesCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemNotActive,
[data-page-element="CoursesCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="CoursesCollection/V1"] .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="CoursesCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="CoursesCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="CourseSectionsCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="CourseSectionsCollection/V1"]
  .collectionPaginationItemActive,
[data-page-element="CourseSectionsCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="CourseSectionsCollection/V1"]
  .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="CourseSectionsCollection/V1"]
  .collectionPaginationItemNotActive,
[data-page-element="CourseSectionsCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="CourseSectionsCollection/V1"]
  .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="CourseSectionsCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="CourseSectionsCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="CourseLessonsCollection/V1"] .collectionPaginationItem {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
[data-page-element="CourseLessonsCollection/V1"]
  .collectionPaginationItemActive,
[data-page-element="CourseLessonsCollection/V1"]
  .collectionPaginationItemActive:visited {
  color: white;
}
[data-page-element="CourseLessonsCollection/V1"]
  .collectionPaginationItemActive {
  background-color: blue;
  border-color: black;
}
[data-page-element="CourseLessonsCollection/V1"]
  .collectionPaginationItemNotActive,
[data-page-element="CourseLessonsCollection/V1"]
  .collectionPaginationItemNotActive:visited {
  color: black;
}
[data-page-element="CourseLessonsCollection/V1"]
  .collectionPaginationItemNotActive {
  background-color: white;
  border-color: black;
}
[data-page-element="CourseLessonsCollection/V1"] .innerCollectionContent {
  padding: 5px;
  width: 100%;
}
[data-page-element="CourseLessonsCollection/V1"] .collectionPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-page-element="CourseProgressBar/V1"] .progress {
  background: #fcfcfc;
}
[data-page-element="CourseProgressBar/V1"] .progress-bar {
  background: #307ced;
}
[data-page-element="CourseProgressBar/V1"] .progress-label {
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  padding: 0 8px;
}
[data-page-element="CourseProgressBar/V1"] .elProgressBar > .progress-label {
  color: #353535;
}
[data-page-element="LessonStatus"] .elTypographyLink.elTypographyLink {
  color: inherit;
}
[data-page-element="BlogPostCategories/V1"] {
  gap: 1.5em;
  display: flex;
}
[data-page-element="BlogPostCategories/V1"] div,
[data-page-element="BlogPostCategories/V1"] a,
[data-page-element="BlogPostCategories/V1"] i {
  margin: 0px;
}
[data-page-element="BlogPostCategories/V1"][data-direction="row"] {
  flex-direction: row;
  align-items: center;
  justify-content: var(--layout-justification-setting);
}
[data-page-element="BlogPostCategories/V1"][data-direction="column"] {
  flex-direction: column;
  align-items: var(--layout-justification-setting);
  justify-content: center;
}
[data-page-element="BlogPostAuthors/V1"] {
  gap: 1.5em;
  display: flex;
}
[data-page-element="BlogPostAuthors/V1"] div,
[data-page-element="BlogPostAuthors/V1"] a,
[data-page-element="BlogPostAuthors/V1"] i {
  margin: 0px;
}
[data-page-element="BlogPostAuthors/V1"][data-direction="row"] {
  flex-direction: row;
  align-items: center;
  justify-content: var(--layout-justification-setting);
}
[data-page-element="BlogPostAuthors/V1"][data-direction="column"] {
  flex-direction: column;
  align-items: var(--layout-justification-setting);
  justify-content: center;
}
[data-page-element="TemplateDynamicSection"] {
  width: 100%;
}
[data-page-element="CheckoutProductImageCarousel/V1"] {
  padding-left: 10px;
  padding-right: 10px;
}
[data-page-element="CheckoutProductImageCarousel/V1"] {
  position: relative;
}
[data-page-element="CheckoutProductImageCarousel/V1"]
  .elCheckoutProductImageCarouselContainer {
  --swiper-scrollbar-bg-color: color-mix(
    in srgb,
    var(--swiper-scrollbar-drag-bg-color) 10%,
    transparent
  );
  --swiper-pagination-color: #aaa;
  --swiper-navigation-color: #aaa;
  position: relative;
  padding-top: 10px;
  overflow: hidden;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-slide {
  overflow: hidden;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav img {
  height: 100%;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav {
  height: 100px;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-main-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-main {
  height: 100%;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .slider-nav-wrapper {
  padding: 15px 0px;
  position: relative;
  overflow: hidden;
}
[data-page-element="CheckoutProductImageCarousel/V1"]
  .slider-nav
  .swiper-slide {
  opacity: 0.4;
  margin: 0px;
}
[data-page-element="CheckoutProductImageCarousel/V1"]
  .slider-nav
  .swiper-slide-thumb-active {
  opacity: 1;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-scrollbar {
  width: 100%;
  z-index: 0;
}
[data-page-element="CheckoutProductImageCarousel/V1"] .swiper-scrollbar-drag {
  margin-left: 0px;
}
[data-page-element="CheckoutProductImageCarousel/V1"]
  .elProductImageCarouselOuterFrame,
[data-page-element="CheckoutProductImageCarousel/V1"]
  .elProductCarouselMainImageOuterFrame {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: transparent;
  z-index: 2;
  pointer-events: none;
}
[data-page-element="OrderConfirmation/V1"] {
  margin-top: 15px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHead {
  height: 64px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHeadIcon {
  color: #151d28;
  font-size: 14px;
  margin-right: 10px;
  margin: 0px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationBody {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 24px;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductInfoName {
  font-size: 14px;
  color: #2d2d2d;
  font-weight: 600;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductInfo {
  font-size: 14px;
  color: #364557;
  font-weight: 400;
  margin: 0px;
  display: flex;
  gap: 20px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProduct {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 0px;
}
[data-page-element="OrderConfirmation/V1"]
  .elOrderConfirmationProductImage
  img {
  width: 100%;
  height: 100%;
}
[data-page-element="OrderConfirmation/V1"]
  .elOrderConfirmationProductInfoWrapper {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHead,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationFoot,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationBody {
  border-style: solid;
  border-color: #ecf0f5;
  border-bottom-width: 1px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationTotal {
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationHeadTitle {
  font-size: 16px;
  color: #151d28;
  font-weight: 500;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationFoot {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 24px;
  padding-right: 24px;
}
[data-page-element="OrderConfirmation/V1"]
  .elOrderConfirmationFoot
  .elOrderConfirmationItem {
  font-weight: 400;
  font-size: 15px;
  color: #364557;
}
[data-page-element="OrderConfirmation/V1"]
  .elOrderConfirmationFoot
  .elOrderConfirmationPrice {
  font-weight: 500;
  font-size: 15px;
  color: #151d28;
}
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationTaxTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationSubTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationShippingTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationDiscountTotal,
[data-page-element="OrderConfirmation/V1"] .elOrderConfirmationInvoiceLineItem {
  display: flex;
  height: 35px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 770px) {
  [data-page-element="OrderConfirmation/V1"] .elOrderConfirmationProductImage {
    width: 30%;
  }
}
[data-page-element="SurveyImageOption/V1"] {
  margin: 0px;
  border: 1px solid
    rgba(
      var(--style-link-color-r),
      var(--style-link-color-g),
      var(--style-link-color-b),
      25%
    );
  border-radius: 6px;
  margin-bottom: 16px;
  background-color: rgba(
    var(--style-link-color-r),
    var(--style-link-color-g),
    var(--style-link-color-b),
    15%
  );
  box-shadow: 0 4px 6px
    rgba(
      var(--style-link-color-r),
      var(--style-link-color-g),
      var(--style-link-color-b),
      15%
    );
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
[data-page-element="SurveyImageOption/V1"]:hover {
  background-color: rgba(
    var(--style-link-color-r),
    var(--style-link-color-g),
    var(--style-link-color-b),
    30%
  );
}
[data-page-element="SurveyImageOption/V1"]:hover .elRadioWrapper:hover,
[data-page-element="SurveyImageOption/V1"]:hover .elCheckboxWrapper:hover {
  background-color: unset;
}
[data-page-element="SurveyImageOption/V1"]
  .elCheckboxWrapper:hover
  .elCheckboxText,
[data-page-element="SurveyImageOption/V1"] .elRadioWrapper:hover .elRadioText {
  color: var(--answer-color) !important;
}
[data-page-element="SurveyImageOption/V1"]:has(input:checked) {
  border: 1px solid var(--style-link-color);
}
[data-page-element="SurveyImageOption/V1"] [data-page-element="Checkbox/V1"] {
  margin: 0;
}
[data-page-element="SurveyImageOption/V1"] .elSurveyImageOptionBase__Image {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}
[data-page-element="SurveyImageOption/V1"] .elSurveyImageOptionBase__Image img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  position: absolute;
  box-sizing: inherit;
  height: 100%;
  top: 0px;
  left: 0px;
}
[data-page-element="SurveyImageOption/V1"] .elSurveyImageOptionBase__Input {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.dynamic-content-placeholder {
  height: 350px;
  background-image: url(./assest/dynamiccontentplaceholder.png);
  background-size: 100% 100%;
  padding: 15px;
  text-align: center;
  cursor: help;
}
.dynamic-content-placeholder-header {
  color: grey;
}

[data-page-element="Navigation/V1"] {
  font-size: 20px;
}
[data-page-element="Navigation/V1"] {
  --nav-icon-placement-setting: -1;
}
[data-page-element="Navigation/V1"] div,
[data-page-element="Navigation/V1"] a,
[data-page-element="Navigation/V1"] i {
  margin: 0px;
}
[data-page-element="Navigation/V1"] a.elTypographyLink:not(.elNavigatorButton) {
  display: flex;
  align-items: center;
}
[data-page-element="Navigation/V1"] .elTypographyLink {
  color: #2d2d2d;
}
[data-page-element="Navigation/V1"] a.elTypographyLink > i {
  order: var(--nav-icon-placement-setting);
  margin: 0 4px;
}
[data-page-element="Navigation/V1"] .fa_apended.fa_apended {
  order: 0;
}
[data-page-element="Navigation/V1"] .elModalInnerContainer {
  width: 100%;
}
[data-page-element="Navigation/V1"] .elNavigatorButton {
  color: #2d2d2d;
  display: none;
  flex-direction: row;
  justify-content: start;
  cursor: pointer;
}
[data-page-element="Navigation/V1"] .elNavigatorButton:hover {
  text-decoration: none;
}
[data-page-element="Navigation/V1"] .elNavigatorDesktop {
  gap: 1.5em;
  display: flex;
}
[data-page-element="Navigation/V1"] .elNavigatorDesktop[data-direction="row"] {
  flex-direction: row;
  align-items: center;
  justify-content: var(--layout-justification-setting);
}
[data-page-element="Navigation/V1"]
  .elNavigatorDesktop[data-direction="column"] {
  flex-direction: column;
  align-items: var(--layout-justification-setting);
  justify-content: center;
}
[data-page-element="Navigation/V1"] .elNavigatorMobile {
  padding: 20px;
  padding-top: 60px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: var(--layout-justification-setting-mobile);
  justify-content: start;
}
[data-page-element="Navigation/V1"] .elNavigatorMobile .elTypographyLink > i {
  display: none;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown {
  justify-content: var(--layout-justification-setting-mobile);
  display: flex;
  flex-direction: column;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown
  > a {
  align-self: var(--layout-justification-setting-mobile);
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown
  a:after {
  display: none;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown
  > div {
  visibility: visible;
  opacity: 1;
  position: static;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown-item {
  border-style: none;
  padding-left: 10px;
  padding-right: 10px;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown-item:hover {
  filter: brightness(150%);
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown-content {
  position: static;
  opacity: 1;
  visibility: visible;
  border-style: none;
  padding-left: 10px;
  padding-right: 10px;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown-content
  a {
  display: flex;
  justify-content: var(--layout-justification-setting-mobile);
}
[data-page-element="Navigation/V1"].elNavigator[data-overlay-menu="always"]
  .elNavigatorButton {
  display: flex;
}
[data-page-element="Navigation/V1"].elNavigator[data-overlay-menu="always"]
  .elNavigatorDesktop {
  display: none;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-holder a {
  white-space: nowrap;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-holder {
  flex-direction: column;
  margin-left: 10px;
  display: flex;
  background: white;
  gap: 0.5em;
  align-items: var(--layout-justification-setting);
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown-holder {
  background: transparent;
  box-shadow: none;
  border-style: none;
}
[data-page-element="Navigation/V1"]
  .elNavigatorMobile
  .elNavigation-menu-dropdown-holder
  .elTypographyLink {
  margin-left: 0px;
  border-style: none;
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown-item:last-child {
  border-top-width: 0px;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-item {
  position: relative;
  display: inline-block;
  margin-left: 0px;
  cursor: pointer;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-title {
  text-decoration: none;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-title:after {
  content: "\203a";
  margin-left: 4px;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-content {
  background: white;
  position: absolute;
  left: 100%;
  top: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown-item:hover
  .elNavigation-menu-dropdown-content {
  visibility: visible;
  opacity: 1;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-content a {
  margin-left: 0px;
  width: 100%;
  text-decoration: none;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown {
  position: relative;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown > div {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: opacity 0.2s ease;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown:hover > div {
  visibility: visible;
  opacity: 1;
}
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown > a:after {
  content: "\203a";
  margin-left: 6px;
  display: inline-block;
  transform: rotate(90deg);
  vertical-align: middle;
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown-holder
  > .elNavigation-menu-dropdown
  > a:after {
  transform: rotate(0deg);
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown
  .dropdown-open-left {
  right: 0px;
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown
  .dropdown-open-left
  .elNavigation-menu-dropdown-content {
  right: 100%;
  left: auto;
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown
  .dropdown-open-top {
  bottom: 100%;
  top: auto;
}
[data-page-element="Navigation/V1"]
  .elNavigation-menu-dropdown
  .dropdown-open-top
  .elNavigation-menu-dropdown-content {
  bottom: -1px;
  top: auto;
}
[data-page-element="Navigation/V1"] .navHamburgerIcon {
  height: 20px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
[data-page-element="Navigation/V1"] .navHamburgerIcon > div,
[data-page-element="Navigation/V1"] .navHamburgerIcon:before,
[data-page-element="Navigation/V1"] .navHamburgerIcon:after {
  content: "";
  height: 4px;
  width: 100%;
  display: block;
  background: rgba(0, 0, 0, 1);
  border-radius: 0px;
}
[data-page-element="Navigation/V1"] .elNavigatorWrapper .navigatorBtn {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
[data-page-element="Navigation/V1"]
  .elNavigatorWrapper
  .navigatorBtn.twoBarHamburger
  > div {
  justify-content: space-evenly;
}
[data-page-element="Navigation/V1"]
  .elNavigatorWrapper
  .navigatorBtn.twoBarHamburger
  .navHamburgerIcon
  > div {
  display: none;
}
@media (max-width: 770px) {
  [data-page-element="Navigation/V1"][data-overlay-menu="mobile"]
    .elNavigatorButton {
    display: flex;
  }
  [data-page-element="Navigation/V1"][data-overlay-menu="mobile"]
    .elNavigatorDesktop {
    display: none;
  }
}




.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border: 0.08em solid #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s steps(8) infinite;
  animation: fa-spin 1s steps(8) infinite;
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.fa-flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical,
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}
:root .fa-flip-both,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-500px:before {
  content: "\f26e";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}
.fa-ad:before {
  content: "\f641";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-airbnb:before {
  content: "\f834";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angry:before {
  content: "\f556";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-artstation:before {
  content: "\f77a";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-atlassian:before {
  content: "\f77b";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-baby:before {
  content: "\f77c";
}
.fa-baby-carriage:before {
  content: "\f77d";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-bacon:before {
  content: "\f7e5";
}
.fa-bacteria:before {
  content: "\e059";
}
.fa-bacterium:before {
  content: "\e05a";
}
.fa-bahai:before {
  content: "\f666";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battle-net:before {
  content: "\f835";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bible:before {
  content: "\f647";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-biking:before {
  content: "\f84a";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-biohazard:before {
  content: "\f780";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blender-phone:before {
  content: "\f6b6";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blog:before {
  content: "\f781";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-dead:before {
  content: "\f6b7";
}
.fa-book-medical:before {
  content: "\f7e6";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-bootstrap:before {
  content: "\f836";
}
.fa-border-all:before {
  content: "\f84c";
}
.fa-border-none:before {
  content: "\f850";
}
.fa-border-style:before {
  content: "\f853";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-tissue:before {
  content: "\e05b";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-bread-slice:before {
  content: "\f7ec";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-buffer:before {
  content: "\f837";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-buy-n-large:before {
  content: "\f8a6";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-day:before {
  content: "\f783";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-calendar-week:before {
  content: "\f784";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-campground:before {
  content: "\f6bb";
}
.fa-canadian-maple-leaf:before {
  content: "\f785";
}
.fa-candy-cane:before {
  content: "\f786";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-caravan:before {
  content: "\f8ff";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-centos:before {
  content: "\f789";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chair:before {
  content: "\f6c0";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-cheese:before {
  content: "\f7ef";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-chromecast:before {
  content: "\f838";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-clinic-medical:before {
  content: "\f7f2";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-meatball:before {
  content: "\f73b";
}
.fa-cloud-moon:before {
  content: "\f6c3";
}
.fa-cloud-moon-rain:before {
  content: "\f73c";
}
.fa-cloud-rain:before {
  content: "\f73d";
}
.fa-cloud-showers-heavy:before {
  content: "\f740";
}
.fa-cloud-sun:before {
  content: "\f6c4";
}
.fa-cloud-sun-rain:before {
  content: "\f743";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudflare:before {
  content: "\e07d";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-medical:before {
  content: "\f7f5";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-arrows-alt:before {
  content: "\f78c";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-confluence:before {
  content: "\f78d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-cotton-bureau:before {
  content: "\f89e";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-critical-role:before {
  content: "\f6c9";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-crutch:before {
  content: "\f7f7";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}
.fa-dailymotion:before {
  content: "\e052";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-deezer:before {
  content: "\e077";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-democrat:before {
  content: "\f747";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-dev:before {
  content: "\f6cc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-dhl:before {
  content: "\f790";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diaspora:before {
  content: "\f791";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-d20:before {
  content: "\f6cf";
}
.fa-dice-d6:before {
  content: "\f6d1";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-disease:before {
  content: "\f7fa";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-dna:before {
  content: "\f471";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dog:before {
  content: "\f6d3";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-dragon:before {
  content: "\f6d5";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drumstick-bite:before {
  content: "\f6d7";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dumpster:before {
  content: "\f793";
}
.fa-dumpster-fire:before {
  content: "\f794";
}
.fa-dungeon:before {
  content: "\f6d9";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edge-legacy:before {
  content: "\e078";
}
.fa-edit:before {
  content: "\f044";
}
.fa-egg:before {
  content: "\f7fb";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-ethernet:before {
  content: "\f796";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-evernote:before {
  content: "\f839";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fan:before {
  content: "\f863";
}
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-faucet:before {
  content: "\e005";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-fedex:before {
  content: "\f797";
}
.fa-fedora:before {
  content: "\f798";
}
.fa-female:before {
  content: "\f182";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-figma:before {
  content: "\f799";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-csv:before {
  content: "\f6dd";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-alt:before {
  content: "\f7e4";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-firefox-browser:before {
  content: "\e007";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-fist-raised:before {
  content: "\f6de";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flag-usa:before {
  content: "\f74d";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frown:before {
  content: "\f119";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-ghost:before {
  content: "\f6e2";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gifts:before {
  content: "\f79c";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-alt:before {
  content: "\f841";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass-cheers:before {
  content: "\f79f";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-glass-whiskey:before {
  content: "\f7a0";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-globe-europe:before {
  content: "\f7a2";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-pay:before {
  content: "\e079";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-grin:before {
  content: "\f580";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-lines:before {
  content: "\f7a4";
}
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-guilded:before {
  content: "\e07e";
}
.fa-guitar:before {
  content: "\f7a6";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hamburger:before {
  content: "\f805";
}
.fa-hammer:before {
  content: "\f6e3";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-medical:before {
  content: "\e05c";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-middle-finger:before {
  content: "\f806";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-sparkles:before {
  content: "\e05d";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-wash:before {
  content: "\e05e";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt-slash:before {
  content: "\e05f";
}
.fa-handshake-slash:before {
  content: "\e060";
}
.fa-hanukiah:before {
  content: "\f6e6";
}
.fa-hard-hat:before {
  content: "\f807";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hat-cowboy:before {
  content: "\f8c0";
}
.fa-hat-cowboy-side:before {
  content: "\f8c1";
}
.fa-hat-wizard:before {
  content: "\f6e8";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-head-side-cough:before {
  content: "\e061";
}
.fa-head-side-cough-slash:before {
  content: "\e062";
}
.fa-head-side-mask:before {
  content: "\e063";
}
.fa-head-side-virus:before {
  content: "\e064";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-broken:before {
  content: "\f7a9";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-hiking:before {
  content: "\f6ec";
}
.fa-hippo:before {
  content: "\f6ed";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hive:before {
  content: "\e07f";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-holly-berry:before {
  content: "\f7aa";
}
.fa-home:before {
  content: "\f015";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-horse:before {
  content: "\f6f0";
}
.fa-horse-head:before {
  content: "\f7ab";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hospital-user:before {
  content: "\f80d";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotdog:before {
  content: "\f80f";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-house-damage:before {
  content: "\f6f1";
}
.fa-house-user:before {
  content: "\e065";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-hryvnia:before {
  content: "\f6f2";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-ice-cream:before {
  content: "\f810";
}
.fa-icicles:before {
  content: "\f7ad";
}
.fa-icons:before {
  content: "\f86d";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-ideal:before {
  content: "\e013";
}
.fa-igloo:before {
  content: "\f7ae";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-innosoft:before {
  content: "\e080";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-instagram-square:before {
  content: "\e055";
}
.fa-instalod:before {
  content: "\e081";
}
.fa-intercom:before {
  content: "\f7af";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-invision:before {
  content: "\f7b0";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itch-io:before {
  content: "\f83a";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-jira:before {
  content: "\f7b1";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-key:before {
  content: "\f084";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laptop-house:before {
  content: "\e066";
}
.fa-laptop-medical:before {
  content: "\f812";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-lungs:before {
  content: "\f604";
}
.fa-lungs-virus:before {
  content: "\e067";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mask:before {
  content: "\f6fa";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-mdb:before {
  content: "\f8ca";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mendeley:before {
  content: "\f7b3";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-meteor:before {
  content: "\f753";
}
.fa-microblog:before {
  content: "\e01a";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mitten:before {
  content: "\f7b5";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mixer:before {
  content: "\e056";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mountain:before {
  content: "\f6fc";
}
.fa-mouse:before {
  content: "\f8cc";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-mug-hot:before {
  content: "\f7b6";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-neos:before {
  content: "\f612";
}
.fa-network-wired:before {
  content: "\f6ff";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octopus-deploy:before {
  content: "\e082";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-orcid:before {
  content: "\f8d2";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-otter:before {
  content: "\f700";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-pager:before {
  content: "\f815";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-parking:before {
  content: "\f540";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-penny-arcade:before {
  content: "\f704";
}
.fa-people-arrows:before {
  content: "\e068";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-pepper-hot:before {
  content: "\f816";
}
.fa-perbyte:before {
  content: "\e083";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-booth:before {
  content: "\f756";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-alt:before {
  content: "\f879";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-square-alt:before {
  content: "\f87b";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-photo-video:before {
  content: "\f87c";
}
.fa-php:before {
  content: "\f457";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-square:before {
  content: "\e01e";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-pizza-slice:before {
  content: "\f818";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-plane-slash:before {
  content: "\e069";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-poll:before {
  content: "\f681";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poo-storm:before {
  content: "\f75a";
}
.fa-poop:before {
  content: "\f619";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-pray:before {
  content: "\f683";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-print:before {
  content: "\f02f";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-pump-medical:before {
  content: "\e06a";
}
.fa-pump-soap:before {
  content: "\e06b";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quran:before {
  content: "\f687";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-radiation:before {
  content: "\f7b9";
}
.fa-radiation-alt:before {
  content: "\f7ba";
}
.fa-rainbow:before {
  content: "\f75b";
}
.fa-random:before {
  content: "\f074";
}
.fa-raspberry-pi:before {
  content: "\f7bb";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-reacteurope:before {
  content: "\f75d";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-record-vinyl:before {
  content: "\f8d9";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redhat:before {
  content: "\f7bc";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-remove-format:before {
  content: "\f87d";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-republican:before {
  content: "\f75e";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-restroom:before {
  content: "\f7bd";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-ring:before {
  content: "\f70b";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-running:before {
  content: "\f70c";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-rust:before {
  content: "\e07a";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-safari:before {
  content: "\f267";
}
.fa-salesforce:before {
  content: "\f83b";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-satellite:before {
  content: "\f7bf";
}
.fa-satellite-dish:before {
  content: "\f7c0";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scroll:before {
  content: "\f70e";
}
.fa-sd-card:before {
  content: "\f7c2";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-virus:before {
  content: "\e06c";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopify:before {
  content: "\e057";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-sim-card:before {
  content: "\f7c4";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sink:before {
  content: "\e06d";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skating:before {
  content: "\f7c5";
}
.fa-sketch:before {
  content: "\f7c6";
}
.fa-skiing:before {
  content: "\f7c9";
}
.fa-skiing-nordic:before {
  content: "\f7ca";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skull-crossbones:before {
  content: "\f714";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-slash:before {
  content: "\f715";
}
.fa-sleigh:before {
  content: "\f7cc";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-smog:before {
  content: "\f75f";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-sms:before {
  content: "\f7cd";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snowboarding:before {
  content: "\f7ce";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-snowman:before {
  content: "\f7d0";
}
.fa-snowplow:before {
  content: "\f7d2";
}
.fa-soap:before {
  content: "\e06e";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-down-alt:before {
  content: "\f881";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-alpha-up-alt:before {
  content: "\f882";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-down-alt:before {
  content: "\f884";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-amount-up-alt:before {
  content: "\f885";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-down-alt:before {
  content: "\f886";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-numeric-up-alt:before {
  content: "\f887";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-sourcetree:before {
  content: "\f7d3";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-speaker-deck:before {
  content: "\f83c";
}
.fa-spell-check:before {
  content: "\f891";
}
.fa-spider:before {
  content: "\f717";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stackpath:before {
  content: "\f842";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-stopwatch-20:before {
  content: "\e06f";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-store-alt-slash:before {
  content: "\e070";
}
.fa-store-slash:before {
  content: "\e071";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-suse:before {
  content: "\f7d6";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swift:before {
  content: "\f8e1";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-symfony:before {
  content: "\f83d";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-temperature-high:before {
  content: "\f769";
}
.fa-temperature-low:before {
  content: "\f76b";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tenge:before {
  content: "\f7d7";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-think-peaks:before {
  content: "\f731";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-tiktok:before {
  content: "\e07b";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-tint:before {
  content: "\f043";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toilet:before {
  content: "\f7d8";
}
.fa-toilet-paper:before {
  content: "\f71e";
}
.fa-toilet-paper-slash:before {
  content: "\e072";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tools:before {
  content: "\f7d9";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-tractor:before {
  content: "\f722";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-trailer:before {
  content: "\e041";
}
.fa-train:before {
  content: "\f238";
}
.fa-tram:before {
  content: "\f7da";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-trello:before {
  content: "\f181";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-ubuntu:before {
  content: "\f7df";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbraco:before {
  content: "\f8e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-uncharted:before {
  content: "\e084";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-unity:before {
  content: "\e049";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-unsplash:before {
  content: "\e07c";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-ups:before {
  content: "\f7e0";
}
.fa-usb:before {
  content: "\f287";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-slash:before {
  content: "\e073";
}
.fa-usps:before {
  content: "\f7e1";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-vest:before {
  content: "\e085";
}
.fa-vest-patches:before {
  content: "\e086";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-virus:before {
  content: "\e074";
}
.fa-virus-slash:before {
  content: "\e075";
}
.fa-viruses:before {
  content: "\e076";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-voicemail:before {
  content: "\f897";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vote-yea:before {
  content: "\f772";
}
.fa-vr-cardboard:before {
  content: "\f729";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-watchman-monitoring:before {
  content: "\e087";
}
.fa-water:before {
  content: "\f773";
}
.fa-wave-square:before {
  content: "\f83e";
}
.fa-waze:before {
  content: "\f83f";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-wind:before {
  content: "\f72e";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-wine-bottle:before {
  content: "\f72f";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wizards-of-the-coast:before {
  content: "\f730";
}
.fa-wodu:before {
  content: "\e088";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wpressr:before {
  content: "\f3e4";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yammer:before {
  content: "\f840";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yarn:before {
  content: "\f7e3";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./fonts/fa-brands-400.eot);
  src: url(./fonts/fa-brands-400.eot#iefix) format("embedded-opentype"),
    url(./fonts/fa-brands-400.woff2) format("woff2"),
    url(./fonts/fa-brands-400.woff) format("woff"),
    url(./fonts/fa-brands-400.ttf) format("truetype"),
    url(./assest/fa-brands-400.svg#fontawesome) format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./fonts/fa-regular-400.eot);
  src: url(./fonts/fa-regular-400.eot#iefix) format("embedded-opentype"),
    url(./fonts/fa-regular-400.woff2) format("woff2"),
    url(./fonts/fa-regular-400.woff) format("woff"),
    url(./fonts/fa-regular-400.ttf) format("truetype"),
    url(./assest/fa-regular-400.svg#fontawesome) format("svg");
}
.fab,
.far {
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(./fonts/fa-solid-900.eot);
  src: url(./fonts/fa-solid-900.eot#iefix) format("embedded-opentype"),
    url(./fonts/fa-solid-900.woff2) format("woff2"),
    url(./fonts/fa-solid-900.woff) format("woff"),
    url(./fonts/fa-solid-900.ttf) format("truetype"),
    url(./assest/fa-solid-900.svg#fontawesome) format("svg");
}
.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}
.fa,
.fas {
  font-weight: 900;
}

.id-6Z-LalDMW-6 {
  width: 100%;
  justify-content: space-around;
  gap: 0px;
  align-items: center;
  padding-left: 53px;
  padding-right: 53px;
  background: #000000;
}
.id-6Z-LalDMW-7 {
  margin-top: 0px;
  text-align: left;
  flex-shrink: 0;
}
.id-6Z-LalDMW-7 .elImage {
  width: 83px;
  height: 77px;
  aspect-ratio: 16 / 9;
}

.id-6Z-LalDMW-8 {
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  font-size: 0.8333rem;
  flex-basis: 507px;
}

.id-6Z-LalDMW-8 .elNavigatorDesktop .elNavigation-menu-dropdown-holder {
  width: auto;
}
.id-6Z-LalDMW-8 .elModal {
  background: rgb(1, 0, 0);
}

.id-6Z-LalDMW-8 .elNavigatorWrapper {
  --layout-justification-setting: center;
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .elNavigatorButton {
  justify-content: end;
}

.id-6Z-LalDMW-8
  .elNavigatorDesktop
  .elNavigation-menu-dropdown-holder
  .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon > div,
.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon:before,
.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon:after {
  background: rgb(255, 255, 255);
  height: 5px;
}

.id-6Z-LalDMW-8 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon {
  height: 19px;
  width: 28px;
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .navigatorBtn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.id-6Z-LalDMW-8 .elNavigatorDesktop {
  gap: 1.9em;
}

.id-6Z-LalDMW-8 .elNavigatorMobile {
  --layout-justification-setting-mobile: center;
  gap: 1.43em;
}
.id-6Z-LalDMW-8 .elClose {
  color: rgb(254, 254, 254);
  font-size: 48px;
}
@media only screen and (max-width: 700px) {
  .id-6Z-LalDMW-8 {
    letter-spacing: 0;
    line-height: 140%;
    font-size: 1.25rem;
  }
}

.id-6Z-LalDMW-9 {
  /* background: ; */
}

.id-6Z-LalDMW-9.elTypographyLink {
  color: rgb(212, 175, 55);
}

.id-6Z-LalDMW-15 {
  text-transform: uppercase;
}

.id-6Z-LalDMW-17 {
  text-align: right;
  margin-top: 5px;
}
.id-6Z-LalDMW-17 .elButton {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  border-style: none;
  padding-left: 15px;
  padding-right: 15px;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 12px;
  --style-box-shadow-blur: 24px;
  --style-box-shadow-spread: -8px;
  --style-box-shadow-color: rgb(220, 178, 42);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px)
    var(--style-box-shadow-spread, 0px) var(--style-box-shadow-color, #000000);
  background: rgb(212, 175, 55);
}

.id-6Z-LalDMW-17 .elButton .elButtonSub {
  color: #ffffff;
}

.id-6Z-LalDMW-17 .elButton .elButtonText {
  color: #ffffff;
  font-size: 0.86rem;
}

.id-6Z-LalDMW-17 .elButton:hover,
.id-6Z-LalDMW-17 .elButton.elButtonHovered {
  background: rgb(253, 194, 0);
}

.id-6Z-LalDMW-17 .elButton:hover .elButtonText,
.id-6Z-LalDMW-17 .elButton.elButtonHovered .elButtonText {
  color: #fff;
}

.id-6Z-LalDMW-17 .elButton:hover .elButtonSub,
.id-6Z-LalDMW-17 .elButton.elButtonHovered .elButtonSub {
  color: #fff;
}

.id-6Z-LalDMW-17 .elButton:active,
.id-6Z-LalDMW-17 .elButton.elButtonActive {
  background: rgb(212, 175, 55);
}

.id-6Z-LalDMW-17 .elButton:active .elButtonText,
.id-6Z-LalDMW-17 .elButton.elButtonActive .elButtonText {
  color: #ffffff;
}

.id-6Z-LalDMW-17 .elButton:active .elButtonSub,
.id-6Z-LalDMW-17 .elButton.elButtonActive .elButtonSub {
  color: #ffffff;
}

/*  */
/* [data-style-guide-text] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-border] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-shadow] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-button] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-corner] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-headline] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-subheadline] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       }
 
       [data-style-guide-content] * {
         --style-box-shadow-style-type: initial;
         --style-box-shadow-distance-x: initial;
         --style-box-shadow-distance-y: initial;
         --style-box-shadow-blur: initial;
         --style-box-shadow-spread: initial;
         --style-box-shadow-color: initial;
       } */
:root {
  --color-6Z-NARx4R-1: rgb(220, 178, 42);
  --color-6Z-NARx4R-2: rgb(7, 31, 47);
  --color-6Z-NARx4R-3: rgb(20, 138, 255);
  --color-6Z-NARx4R-5: rgb(77, 86, 99);
  --color-6Z-NARx4R-6: rgb(253, 253, 253);
  --color-6Z-NARx4R-4: rgb(20, 138, 255);
  --color-6Z-NARx4R-7: rgb(13, 31, 46);
  --color-6Z-NARx4R-8: rgb(20, 138, 255);
}

/* [data-paint-colors] {
         color: var(--style-color);
       }
       [data-paint-colors] .elHeadline {
         color: var(--style-headline-color);
       }
       [data-paint-colors] .elSubheadline {
         color: var(--style-subheadline-color);
       }
       [data-paint-colors] .elTypographyLink {
         color: var(--style-link-color);
       }
       [data-paint-colors] .fa-fw {
         color: var(--style-icon-color);
       }
       [data-paint-colors] .elBulletList .fa,
       [data-paint-colors] .elBulletList .fas,
       [data-paint-colors] .elBulletList .fa-fw {
         color: var(--style-bullet-color);
       } */

:root {
  --style-guide-font-family-headline: "Poppins", sans-serif;
  --style-guide-font-weight-headline: 900;
  --style-guide-font-family-subheadline: "Open Sans", sans-serif;
  --style-guide-font-weight-subheadline: 700;
  --style-guide-font-family-content: "Open Sans", sans-serif;
  --style-guide-font-weight-content: 400;
  --style-guide-button-style1-background-color: rgb(212, 175, 55);
  --style-guide-button-style1-text-color: rgb(255, 255, 255);
  --style-guide-button-style1-hover-background-color: rgb(220, 178, 42);
  --style-guide-button-style1-hover-text-color: rgb(255, 255, 255);
  --style-guide-button-style1-active-background-color: rgb(212, 175, 55);
  --style-guide-button-style1-active-text-color: rgb(255, 255, 255);
  --style-guide-button-style2-background-color: var(--color-6Z-NARx4R-7);
  --style-guide-button-style2-text-color: rgb(255, 255, 255);
  --style-guide-button-style2-hover-background-color: var(--color-6Z-NARx4R-7);
  --style-guide-button-style2-hover-text-color: rgb(255, 255, 255);
  --style-guide-button-style2-active-background-color: var(--color-6Z-NARx4R-7);
  --style-guide-button-style2-active-text-color: rgb(255, 255, 255);
  --style-guide-button-style3-background-color: #abcdef;
  --style-guide-button-style3-text-color: #123456;
  --style-guide-button-style3-hover-background-color: #abcdef;
  --style-guide-button-style3-hover-text-color: #123456;
  --style-guide-button-style3-active-background-color: #abcdef;
  --style-guide-button-style3-active-text-color: #123456;
}
html {
  font-size: 24px;
}
.elTypographyLink {
  font-size: 18px;
}
/* .ne{
  font-size: 25px;
  font-weight: 600;
} */
[data-page-element="Button/V1"] .elButton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: 600;
  transition: background 0.2s ease;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #d4af37;
  font-size: 12px !important;
}
[data-page-element="Button/V1"] .elButton .elButtonText {
  font-size: 26px;
  line-height: 100%;
  color: rgb(255, 255, 255);
  transition: color 0.2s ease;
}
.elPageContent [data-style-guide-headline] {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 0.02rem;
}
.elPageContent [data-style-guide-subheadline] {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.01rem;
  line-height: 130%;
}
.elPageContent [data-style-guide-content] {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
}
.elPageContent [data-style-guide-subheadline="xl"] {
  font-size: 1.25rem;
}
.elPageContent [data-style-guide-headline="xl"] {
  font-size: 2.7083rem;
}
.elPageContent [data-style-guide-headline="l"] {
  font-size: 2.0833rem;
}
.elPageContent [data-style-guide-headline="m"] {
  font-size: 1.7083rem;
}
.elPageContent [data-style-guide-headline="s"] {
  font-size: 1.0833rem;
}
.elPageContent [data-style-guide-content="xl"] {
  font-size: 1rem;
}
.elPageContent [data-style-guide-content="l"] {
  font-size: 0.8333rem;
}
.elPageContent [data-style-guide-content="m"] {
  font-size: 0.75rem;
}
.elPageContent [data-style-guide-content="s"] {
  font-size: 0.6667rem;
}
.elPageContent [data-style-guide-subheadline="l"] {
  font-size: 1rem;
}
.elPageContent [data-style-guide-subheadline="m"] {
  font-size: 0.75rem;
}
.elPageContent [data-style-guide-subheadline="s"] {
  font-size: 0.55rem;
}
.elPageContent [data-style-guide-shadow="style1"] {
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 18px;
  --style-box-shadow-spread: -4px;
  --style-box-shadow-color: rgba(0, 0, 0, 0.18);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x) var(--style-box-shadow-distance-y)
    var(--style-box-shadow-blur) var(--style-box-shadow-spread)
    var(--style-box-shadow-color);
}
.elPageContent [data-style-guide-shadow="style2"] {
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 39px;
  --style-box-shadow-spread: -8px;
  --style-box-shadow-color: rgba(0, 0, 0, 0.15);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x) var(--style-box-shadow-distance-y)
    var(--style-box-shadow-blur) var(--style-box-shadow-spread)
    var(--style-box-shadow-color);
}
.elPageContent [data-style-guide-shadow="style3"] {
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 56px;
  --style-box-shadow-spread: -16px;
  --style-box-shadow-color: rgba(0, 0, 0, 0.2);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x) var(--style-box-shadow-distance-y)
    var(--style-box-shadow-blur) var(--style-box-shadow-spread)
    var(--style-box-shadow-color);
}
.elPageContent [data-style-guide-border="style1"] {
  border-style: solid;
  border-color: #ebebeb;
  border-width: 1px;
}
.elPageContent [data-style-guide-border="style2"] {
  border-style: solid;
  border-color: #bbbbbb;
  border-width: 1px;
}
.elPageContent [data-style-guide-border="style3"] {
  border-style: solid;
  border-color: #5c5c5c;
  border-width: 1px;
}
.elPageContent [data-style-guide-button="style1"] {
  font-family: "Open Sans", sans-serif;
  border-style: none;
  background: rgb(212, 175, 55) !important;
  border-color: #123acb !important;
  border-width: 1px;
}
.elPageContent [data-style-guide-button="style2"] {
  font-family: "Open Sans", sans-serif;
  background: var(--color-6Z-NARx4R-7);
  border-style: solid;
  border-color: var(--color-6Z-NARx4R-4);
  border-width: 1px;
}
.elPageContent [data-style-guide-button="style3"] {
  border-width: 0px;
  border-radius: 0px;
  background: #abcdef;
  border-style: solid;
  border-color: rgb(244, 252, 255);
}
.elPageContent [data-style-guide-button="style1"] .elButtonText {
  color: rgb(255, 255, 255);
  font-size: 26px !important;
  letter-spacing: 0;
  line-height: 130%;
  font-weight: 600;
  text-transform: capitalize;
}
.elPageContent [data-style-guide-button="style1"] .elButtonSub {
  color: rgb(255, 255, 255);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
}
.elPageContent [data-style-guide-button="style2"] .elButtonText {
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 100%;
}
.elPageContent [data-style-guide-button="style2"] .elButtonSub {
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 14px;
}
.elPageContent [data-style-guide-button="style3"] .elButtonText {
  color: #123456;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 100%;
}
.elPageContent [data-style-guide-button="style3"] .elButtonSub {
  color: #123456;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 100%;
}
.elPageContent [data-style-guide-button="style1"]:hover,
.elPageContent [data-style-guide-button="style1"].elButtonHovered {
  background: rgb(220, 178, 42);
}
.elPageContent [data-style-guide-button="style1"]:active,
.elPageContent [data-style-guide-button="style1"].elButtonActive {
  background: rgb(212, 175, 55);
}
.elPageContent [data-style-guide-button="style1"]:hover .elButtonText,
.elPageContent
  [data-style-guide-button="style1"].elButtonHovered
  .elButtonText {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style1"]:active .elButtonText,
.elPageContent [data-style-guide-button="style1"].elButtonActive .elButtonText {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style1"]:hover .elButtonSub,
.elPageContent [data-style-guide-button="style1"].elButtonHovered .elButtonSub {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style1"]:active .elButtonSub,
.elPageContent [data-style-guide-button="style1"].elButtonActive .elButtonSub {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style2"]:hover,
.elPageContent [data-style-guide-button="style2"].elButtonHovered {
  background: var(--color-6Z-NARx4R-7);
}
.elPageContent [data-style-guide-button="style2"]:active,
.elPageContent [data-style-guide-button="style2"].elButtonActive {
  background: var(--color-6Z-NARx4R-7);
}
.elPageContent [data-style-guide-button="style2"]:hover .elButtonText,
.elPageContent
  [data-style-guide-button="style2"].elButtonHovered
  .elButtonText {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style2"]:active .elButtonText,
.elPageContent [data-style-guide-button="style2"].elButtonActive .elButtonText {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style2"]:hover .elButtonSub,
.elPageContent [data-style-guide-button="style2"].elButtonHovered .elButtonSub {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style2"]:active .elButtonSub,
.elPageContent [data-style-guide-button="style2"].elButtonActive .elButtonSub {
  color: rgb(255, 255, 255);
}
.elPageContent [data-style-guide-button="style3"]:hover,
.elPageContent [data-style-guide-button="style3"].elButtonHovered {
  background: #abcdef;
}
.elPageContent [data-style-guide-button="style3"]:active,
.elPageContent [data-style-guide-button="style3"].elButtonActive {
  background: #abcdef;
}
.elPageContent [data-style-guide-button="style3"]:hover .elButtonText,
.elPageContent
  [data-style-guide-button="style3"].elButtonHovered
  .elButtonText {
  color: #123456;
}
.elPageContent [data-style-guide-button="style3"]:active .elButtonText,
.elPageContent [data-style-guide-button="style3"].elButtonActive .elButtonText {
  color: #123456;
}
.elPageContent [data-style-guide-button="style3"]:hover .elButtonSub,
.elPageContent [data-style-guide-button="style3"].elButtonHovered .elButtonSub {
  color: #123456;
}
.elPageContent [data-style-guide-button="style3"]:active .elButtonSub,
.elPageContent [data-style-guide-button="style3"].elButtonActive .elButtonSub {
  color: #123456;
}
.elPageContent [data-style-guide-corner="style1"] {
  border-radius: 4px;
}
.elPageContent [data-style-guide-corner="style2"] {
  border-radius: 12px;
}
.elPageContent [data-style-guide-corner="style3"] {
  border-radius: 20px;
}
@media (max-width: 700px) {
  html {
    font-size: 17px;
  }
}
@media only screen and (max-width: 700px) {
  .elPageContent.elPageContent.elPageContent [data-style-guide-headline] {
    letter-spacing: 0;
    line-height: 140%;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-headline="xl"] {
    font-size: 1.95rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-headline="l"] {
    font-size: 1.5rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-headline="m"] {
    font-size: 1.25rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-headline="s"] {
    font-size: 1rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-subheadline] {
    letter-spacing: 0;
    line-height: 140%;
  }
  .elPageContent.elPageContent.elPageContent
    [data-style-guide-subheadline="xl"] {
    font-size: 1.25rem;
  }
  .elPageContent.elPageContent.elPageContent
    [data-style-guide-subheadline="l"] {
    font-size: 1rem;
  }
  .elPageContent.elPageContent.elPageContent
    [data-style-guide-subheadline="m"] {
    font-size: 0.8rem;
  }
  .elPageContent.elPageContent.elPageContent
    [data-style-guide-subheadline="s"] {
    font-size: 0.64rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-content] {
    letter-spacing: 0;
    line-height: 140%;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-content="xl"] {
    font-size: 1.56rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-content="l"] {
    font-size: 1.25rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-content="m"] {
    font-size: 1rem;
  }
  .elPageContent.elPageContent.elPageContent [data-style-guide-content="s"] {
    font-size: 0.8rem;
  }
}

[data-paint-colors="lightest"] {
  --style-color: var(--color-6Z-NARx4R-2);
  --style-headline-color: var(--color-6Z-NARx4R-2);
  --style-subheadline-color: var(--color-6Z-NARx4R-8);
  --style-link-color: var(--color-6Z-NARx4R-2);
  --style-icon-color: var(--color-6Z-NARx4R-8);
  --style-bullet-color: var(--color-6Z-NARx4R-8);
  --style-background-color: var(--color-6Z-NARx4R-6);
  background: linear-gradient(
      var(--color-6Z-NARx4R-6) 0%,
      var(--color-6Z-NARx4R-6) 100%
    ),
    var(--color-6Z-NARx4R-6);
  --style-link-color-r: 7;
  --style-link-color-g: 31;
  --style-link-color-b: 47;
}
[data-paint-colors="lightest"] .elContent {
  color: var(--color-6Z-NARx4R-4);
}

[data-paint-colors="light"] {
  --style-color: var(--color-6Z-NARx4R-2);
  --style-headline-color: var(--color-6Z-NARx4R-2);
  --style-subheadline-color: var(--color-6Z-NARx4R-1);
  --style-link-color: var(--color-6Z-NARx4R-2);
  --style-icon-color: var(--color-6Z-NARx4R-1);
  --style-bullet-color: var(--color-6Z-NARx4R-1);
  --style-background-color: var(--color-6Z-NARx4R-1);
  background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--color-6Z-NARx4R-1);
  --style-link-color-r: 7;
  --style-link-color-g: 31;
  --style-link-color-b: 47;
}
[data-paint-colors="light"] .elContent {
  color: var(--color-6Z-NARx4R-4);
}

[data-paint-colors="colored"] {
  --style-color: var(--color-6Z-NARx4R-6);
  --style-headline-color: var(--color-6Z-NARx4R-6);
  --style-subheadline-color: var(--color-6Z-NARx4R-1);
  --style-link-color: var(--color-6Z-NARx4R-6);
  --style-icon-color: var(--color-6Z-NARx4R-1);
  --style-bullet-color: var(--color-6Z-NARx4R-5);
  --style-background-color: var(--color-6Z-NARx4R-1);
  background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--color-6Z-NARx4R-1);
  --style-link-color-r: 253;
  --style-link-color-g: 253;
  --style-link-color-b: 253;
}
[data-paint-colors="colored"] .elContent {
  color: var(--color-6Z-NARx4R-1);
}

[data-paint-colors="dark"] {
  --style-color: var(--color-6Z-NARx4R-1);
  --style-headline-color: var(--color-6Z-NARx4R-6);
  --style-subheadline-color: var(--color-6Z-NARx4R-1);
  --style-link-color: var(--color-6Z-NARx4R-8);
  --style-icon-color: var(--color-6Z-NARx4R-8);
  --style-bullet-color: var(--color-6Z-NARx4R-4);
  --style-background-color: var(--color-6Z-NARx4R-5);
  background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--color-6Z-NARx4R-5);
  --style-link-color-r: 20;
  --style-link-color-g: 138;
  --style-link-color-b: 255;
}
[data-paint-colors="dark"] .elContent {
  color: var(--color-6Z-NARx4R-1);
}

[data-paint-colors="darkest"] {
  --style-color: var(--color-6Z-NARx4R-1);
  --style-headline-color: var(--color-6Z-NARx4R-1);
  --style-subheadline-color: var(--color-6Z-NARx4R-3);
  --style-link-color: var(--color-6Z-NARx4R-8);
  --style-icon-color: var(--color-6Z-NARx4R-8);
  --style-bullet-color: var(--color-6Z-NARx4R-8);
  --style-background-color: var(--color-6Z-NARx4R-7);
  background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--color-6Z-NARx4R-7);
  --style-link-color-r: 20;
  --style-link-color-g: 138;
  --style-link-color-b: 255;
}
[data-paint-colors="darkest"] .elContent {
  color: var(--color-6Z-NARx4R-1);
}
.id-6Z-6K9P7D-1.id-6Z-6K9P7D-1 .containerModal {
  margin-bottom: 0px;
}

.id-6Z-LalDMW-6.id-6Z-LalDMW-6 {
  width: 100%;
  justify-content: space-around;
  gap: 0px;
  align-items: center;
  padding-left: 53px;
  padding-right: 53px;
  background: #000000;
}
.id-6Z-LalDMW-7.id-6Z-LalDMW-7 {
  margin-top: 0px;
  text-align: left;
  flex-shrink: 0;
}
.id-6Z-LalDMW-7.id-6Z-LalDMW-7 .elImage {
  width: 50px;
  height: 50px;
  aspect-ratio: 16 / 9;
}

.id-6Z-LalDMW-8.id-6Z-LalDMW-8 {
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  font-size: 0.8333rem;
  flex-basis: 507px;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8
  .elNavigatorDesktop
  .elNavigation-menu-dropdown-holder {
  width: auto;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elModal {
  background: rgb(1, 0, 0);
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper {
  --layout-justification-setting: center;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper .elNavigatorButton {
  justify-content: end;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8
  .elNavigatorDesktop
  .elNavigation-menu-dropdown-holder
  .elTypographyLink {
  color: rgb(255, 255, 255);
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon > div,
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon:before,
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon:after {
  background: rgb(255, 255, 255);
  height: 5px;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elTypographyLink {
  color: rgb(255, 255, 255);
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon {
  height: 19px;
  width: 28px;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorWrapper .navigatorBtn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorDesktop {
  gap: 1.9em;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elNavigatorMobile {
  --layout-justification-setting-mobile: center;
  gap: 1.43em;
}
.id-6Z-LalDMW-8.id-6Z-LalDMW-8 .elClose {
  color: rgb(254, 254, 254);
  font-size: 48px;
}
@media only screen and (max-width: 700px) {
  .id-6Z-LalDMW-8.id-6Z-LalDMW-8.id-6Z-LalDMW-8 {
    letter-spacing: 0;
    line-height: 140%;
    font-size: 1.25rem;
  }
}

.id-6Z-LalDMW-9.id-6Z-LalDMW-9 {
  /* background: ; */
}
.id-6Z-LalDMW-9.id-6Z-LalDMW-9.elTypographyLink {
  color: rgb(212, 175, 55);
}

.id-6Z-LalDMW-15.id-6Z-LalDMW-15 {
  text-transform: uppercase;
}
.id-6Z-LalDMW-9.id-6Z-LalDMW-9 {
  /* background: ; */
}
.id-6Z-LalDMW-9.id-6Z-LalDMW-9.elTypographyLink {
  color: rgb(212, 175, 55);
}

.id-6Z-LalDMW-15.id-6Z-LalDMW-15 {
  text-transform: uppercase;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 {
  text-align: right;
  margin-top: 5px;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  border-style: none;
  padding-left: 15px;
  padding-right: 15px;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 12px;
  --style-box-shadow-blur: 24px;
  --style-box-shadow-spread: -8px;
  --style-box-shadow-color: rgb(220, 178, 42);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px)
    var(--style-box-shadow-spread, 0px) var(--style-box-shadow-color, #000000);
  background: rgb(212, 175, 55);
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton .elButtonSub {
  color: #ffffff;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton .elButtonText {
  color: #ffffff;
  font-size: 0.86rem;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton:hover,
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton.elButtonHovered {
  background: rgb(253, 194, 0);
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton:hover .elButtonText,
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton.elButtonHovered .elButtonText {
  color: #fff;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton:hover .elButtonSub,
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton.elButtonHovered .elButtonSub {
  color: #fff;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton:active,
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton.elButtonActive {
  background: rgb(212, 175, 55);
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton:active .elButtonText,
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton.elButtonActive .elButtonText {
  color: #ffffff;
}
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton:active .elButtonSub,
.id-6Z-LalDMW-17.id-6Z-LalDMW-17 .elButton.elButtonActive .elButtonSub {
  color: #ffffff;
}

.id-6Z-6K9P7D-2161.id-6Z-6K9P7D-2161 {
  padding-bottom: 16px;
  padding-top: 51px;
  background-position: center top !important;
  background-color: #000;


  /* background: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/78ed8c650ce80dc7e9d7ebeb24f9cf50.png"); */
}
.id-6Z-6K9P7D-2162.id-6Z-6K9P7D-2162 {
  width: 1173px;
  padding-top: 5px;
}
.id-6Z-6K9P7D-2162.id-6Z-6K9P7D-2162 .col-inner {
  height: auto;
}

.id-6Z-6K9P7D-2168.id-6Z-6K9P7D-2168 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -65px;
}
.id-6Z-6K9P7D-2168.id-6Z-6K9P7D-2168 .elHeadline {
  text-align: center;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-2281.id-6Z-6K9P7D-2281 {
  padding-top: 21px;
  padding-bottom: 10px;
  margin-top: -90px;
}
.id-6Z-6K9P7D-2281.id-6Z-6K9P7D-2281 .elHeadline {
  text-align: center;
  text-transform: capitalize;
  color: rgb(253, 194, 0);
}

.id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 13px;
}
.id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187 .elHeadline {
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 0.02rem;
  font-size: 1.0833rem;
  color: rgb(241, 191, 30);
}
.id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187 .fa_prepended {
  font-size: 54px;
  margin-right: 8px;
}
@media only screen and (max-width: 700px) {
  .id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187 .fa_prepended {
    font-size: 26px;
  }
  .id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187.id-6Z-6K9P7D-2187 .elHeadline {
    letter-spacing: 0;
    line-height: 140%;
    font-size: 1rem;
  }
}

.id-6Z-6K9P7D-2269.id-6Z-6K9P7D-2269 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-2269.id-6Z-6K9P7D-2269 .elSubheadline {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-2238.id-6Z-6K9P7D-2238 {
  margin-top: 15px;
}
.id-6Z-6K9P7D-2238.id-6Z-6K9P7D-2238 .elButton {
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.id-6Z-6K9P7D-6626.id-6Z-6K9P7D-6626 {
  padding-bottom: 0px;
}
.id-6Z-6K9P7D-6627.id-6Z-6K9P7D-6627 {
  width: 1170px;
  padding-bottom: 0px;
}
.id-6Z-6K9P7D-6632.id-6Z-6K9P7D-6632 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6632.id-6Z-6K9P7D-6632 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6629.id-6Z-6K9P7D-6629 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6643.id-6Z-6K9P7D-6643 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6787.id-6Z-6K9P7D-6787 {
  width: 1170px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.id-6Z-6K9P7D-6693.id-6Z-6K9P7D-6693 .fa_icon {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6693.id-6Z-6K9P7D-6693 .iconElement .fa,
.id-6Z-6K9P7D-6693.id-6Z-6K9P7D-6693 .iconElement .fas,
.id-6Z-6K9P7D-6693.id-6Z-6K9P7D-6693 .iconElement .fa-fw {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6668.id-6Z-6K9P7D-6668 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6678.id-6Z-6K9P7D-6678 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6678.id-6Z-6K9P7D-6678 .elSubheadline {
  color: #000000;
}

.id-6Z-6K9P7D-6694.id-6Z-6K9P7D-6694 .fa_icon {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6694.id-6Z-6K9P7D-6694 .iconElement .fa,
.id-6Z-6K9P7D-6694.id-6Z-6K9P7D-6694 .iconElement .fas,
.id-6Z-6K9P7D-6694.id-6Z-6K9P7D-6694 .iconElement .fa-fw {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6793.id-6Z-6K9P7D-6793 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6686.id-6Z-6K9P7D-6686 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6686.id-6Z-6K9P7D-6686 .elSubheadline {
  color: #000000;
}

.id-6Z-6K9P7D-6792.id-6Z-6K9P7D-6792 .fa_icon {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6792.id-6Z-6K9P7D-6792 .iconElement .fa,
.id-6Z-6K9P7D-6792.id-6Z-6K9P7D-6792 .iconElement .fas,
.id-6Z-6K9P7D-6792.id-6Z-6K9P7D-6792 .iconElement .fa-fw {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6672.id-6Z-6K9P7D-6672 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6796.id-6Z-6K9P7D-6796 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6796.id-6Z-6K9P7D-6796 .elSubheadline {
  color: #000000;
}

.id-6Z-6K9P7D-6692.id-6Z-6K9P7D-6692 .fa_icon {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6692.id-6Z-6K9P7D-6692 .iconElement .fa,
.id-6Z-6K9P7D-6692.id-6Z-6K9P7D-6692 .iconElement .fas,
.id-6Z-6K9P7D-6692.id-6Z-6K9P7D-6692 .iconElement .fa-fw {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6675.id-6Z-6K9P7D-6675 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6689.id-6Z-6K9P7D-6689 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6689.id-6Z-6K9P7D-6689 .elSubheadline {
  color: #000000;
}

.id-6Z-6K9P7D-6701.id-6Z-6K9P7D-6701 {
  padding-top: 0px;
  background: #000000;
}
.id-6Z-6K9P7D-6702.id-6Z-6K9P7D-6702 {
  width: 1170px;
}
.id-6Z-6K9P7D-6704.id-6Z-6K9P7D-6704 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6704.id-6Z-6K9P7D-6704 .elSubheadline {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-6707.id-6Z-6K9P7D-6707 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6707.id-6Z-6K9P7D-6707 .elHeadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6712.id-6Z-6K9P7D-6712 {
  width: 1170px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-ZW7kAo-9.id-6Z-ZW7kAo-9 {
  width: 1170px;
}
.id-6Z-6K9P7D-6745.id-6Z-6K9P7D-6745 {
  width: 1170px;
}
.id-6Z-6K9P7D-6747.id-6Z-6K9P7D-6747 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6747.id-6Z-6K9P7D-6747 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6771.id-6Z-6K9P7D-6771 {
  width: 1170px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-6K9P7D-6773.id-6Z-6K9P7D-6773 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6773.id-6Z-6K9P7D-6773 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-6776.id-6Z-6K9P7D-6776 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-6805.id-6Z-6K9P7D-6805 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-6805.id-6Z-6K9P7D-6805 .elBulletList .fa,
.id-6Z-6K9P7D-6805.id-6Z-6K9P7D-6805 .elBulletList .fas,
.id-6Z-6K9P7D-6805.id-6Z-6K9P7D-6805 .elBulletList .fa-fw {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-7864.id-6Z-6K9P7D-7864 {
  background: #000000;
}
.id-6Z-6K9P7D-7865.id-6Z-6K9P7D-7865 {
  width: 1170px;
}
.id-6Z-6K9P7D-7867.id-6Z-6K9P7D-7867 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7867.id-6Z-6K9P7D-7867 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-7870.id-6Z-6K9P7D-7870 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7870.id-6Z-6K9P7D-7870 .elHeadline {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-7896.id-6Z-6K9P7D-7896 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7896.id-6Z-6K9P7D-7896 .elHeadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-7900.id-6Z-6K9P7D-7900 {
  width: 1170px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.id-6Z-6K9P7D-7903.id-6Z-6K9P7D-7903 .elImage {
  height: 327px;
  width: 100%;
}

.id-6Z-b4YjXG-8.id-6Z-b4YjXG-8 .elButton {
  width: 100%;
}

.id-6Z-6K9P7D-7904.id-6Z-6K9P7D-7904 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7904.id-6Z-6K9P7D-7904 .elHeadline {
  color: rgb(255, 255, 255);
  text-align: center;
}

.id-6Z-6K9P7D-7907.id-6Z-6K9P7D-7907 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7907.id-6Z-6K9P7D-7907 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-7940.id-6Z-6K9P7D-7940 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7940.id-6Z-6K9P7D-7940 .elParagraph {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-7914.id-6Z-6K9P7D-7914 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-6K9P7D-7914.id-6Z-6K9P7D-7914 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-7949.id-6Z-6K9P7D-7949 {
  background: rgb(0, 0, 0);
}
.id-6Z-6K9P7D-7950.id-6Z-6K9P7D-7950 {
  padding-top: 0px;
  padding-bottom: 0px;
  background: rgb(0, 0, 0);
}
.id-6Z-6K9P7D-7938.id-6Z-6K9P7D-7938 {
  margin-top: -5px;
}

.id-6Z-6K9P7D-7939.id-6Z-6K9P7D-7939 {
  margin-top: -15px;
  padding-top: 13px;
}

.id-6Z-6K9P7D-7936.id-6Z-6K9P7D-7936 {
  margin-top: -10px;
}

.id-6Z-9JGGmA-115.id-6Z-9JGGmA-115 {
  background: rgb(0, 0, 0);
}
.id-6Z-9JGGmA-116.id-6Z-9JGGmA-116 {
  padding-top: 0px;
  padding-bottom: 0px;
  background: rgb(0, 0, 0);
}
.id-6Z-9JGGmA-118.id-6Z-9JGGmA-118 {
  margin-top: -5px;
}

.id-6Z-9JGGmA-120.id-6Z-9JGGmA-120 {
  margin-top: -15px;
  padding-top: 13px;
}

.id-6Z-9JGGmA-122.id-6Z-9JGGmA-122 {
  margin-top: -10px;
}

.id-6Z-9JGGmA-112.id-6Z-9JGGmA-112 {
  width: 1170px;
}

.id-6Z-9JGGmA-114.id-6Z-9JGGmA-114 .elDivider {
  border-color: rgb(255, 255, 255);
}

.id-6Z-9JGGmA-88.id-6Z-9JGGmA-88 {
  width: 1170px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.id-6Z-9JGGmA-92.id-6Z-9JGGmA-92 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGGmA-92.id-6Z-9JGGmA-92 .elHeadline {
  color: rgb(255, 255, 255);
  text-align: center;
}

.id-6Z-9JGGmA-95.id-6Z-9JGGmA-95 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGGmA-95.id-6Z-9JGGmA-95 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-9JGGmA-98.id-6Z-9JGGmA-98 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGGmA-98.id-6Z-9JGGmA-98 .elParagraph {
  color: rgb(255, 255, 255);
}

.id-6Z-9JGGmA-101.id-6Z-9JGGmA-101 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-9JGGmA-101.id-6Z-9JGGmA-101 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-9JGGmA-104.id-6Z-9JGGmA-104 {
  background: rgb(0, 0, 0);
}
.id-6Z-9JGGmA-105.id-6Z-9JGGmA-105 {
  padding-top: 0px;
  padding-bottom: 0px;
  background: rgb(0, 0, 0);
}
.id-6Z-9JGGmA-107.id-6Z-9JGGmA-107 {
  margin-top: -5px;
}

.id-6Z-9JGGmA-109.id-6Z-9JGGmA-109 {
  margin-top: -15px;
  padding-top: 13px;
}

.id-6Z-9JGGmA-111.id-6Z-9JGGmA-111 {
  margin-top: -10px;
}

.id-6Z-9JGGmA-90.id-6Z-9JGGmA-90 .elImage {
  height: 327px;
  width: 100%;
}

.id-6Z-b4YjXG-17.id-6Z-b4YjXG-17 .elButton {
  width: 100%;
}

.id-6Z-7Gamxz-2.id-6Z-7Gamxz-2 {
  padding-bottom: 0px;
}
.id-6Z-7Gamxz-4.id-6Z-7Gamxz-4 {
  width: 1170px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.id-6Z-7Gamxz-6.id-6Z-7Gamxz-6 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-6.id-6Z-7Gamxz-6 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-7Gamxz-9.id-6Z-7Gamxz-9 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-7Gamxz-14.id-6Z-7Gamxz-14 {
  padding-bottom: 0px;
  padding-top: 0px;
}
.id-6Z-7Gamxz-15.id-6Z-7Gamxz-15 {
  padding-top: 0px;
}

.id-6Z-7Gamxz-50.id-6Z-7Gamxz-50 .elImage {
  border-radius: 32px;
}

.id-6Z-7Gamxz-31.id-6Z-7Gamxz-31 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-7Gamxz-22.id-6Z-7Gamxz-22 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-22.id-6Z-7Gamxz-22 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-7Gamxz-49.id-6Z-7Gamxz-49 .elImage {
  border-radius: 32px;
}

.id-6Z-7Gamxz-37.id-6Z-7Gamxz-37 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-7Gamxz-40.id-6Z-7Gamxz-40 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-40.id-6Z-7Gamxz-40 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-7Gamxz-19.id-6Z-7Gamxz-19 .elImage {
  border-radius: 32px;
}

.id-6Z-7Gamxz-26.id-6Z-7Gamxz-26 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-7Gamxz-34.id-6Z-7Gamxz-34 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-34.id-6Z-7Gamxz-34 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-9JGGmA-63.id-6Z-9JGGmA-63 {
  padding-top: 0px;
}

.id-6Z-9JGGmA-65.id-6Z-9JGGmA-65 .elImage {
  border-radius: 32px;
}

.id-6Z-9JGGmA-66.id-6Z-9JGGmA-66 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-9JGGmA-69.id-6Z-9JGGmA-69 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGGmA-69.id-6Z-9JGGmA-69 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-9JGGmA-73.id-6Z-9JGGmA-73 .elImage {
  border-radius: 32px;
}

.id-6Z-9JGGmA-74.id-6Z-9JGGmA-74 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-9JGGmA-77.id-6Z-9JGGmA-77 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGGmA-77.id-6Z-9JGGmA-77 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-9JGGmA-81.id-6Z-9JGGmA-81 .elImage {
  border-radius: 32px;
}

.id-6Z-9JGGmA-82.id-6Z-9JGGmA-82 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-9JGGmA-85.id-6Z-9JGGmA-85 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGGmA-85.id-6Z-9JGGmA-85 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-7Gamxz-3.id-6Z-7Gamxz-3 {
  padding-bottom: 0px;
  padding-top: 0px;
  background: #000000;
}
.id-6Z-7Gamxz-53.id-6Z-7Gamxz-53 {
  width: 1170px;
  padding-bottom: 0px;
}
.id-6Z-7Gamxz-55.id-6Z-7Gamxz-55 {
  padding-bottom: 0px;
  padding-top: 0px;
  background: rgb(0, 0, 0);
}
.id-6Z-7Gamxz-56.id-6Z-7Gamxz-56 {
  padding-bottom: 0px;
  padding-top: 0px;
}
.id-6Z-7Gamxz-59.id-6Z-7Gamxz-59 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-59.id-6Z-7Gamxz-59 .elSubheadline {
  color: rgb(220, 178, 42);
}

.id-6Z-7Gamxz-63.id-6Z-7Gamxz-63 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-63.id-6Z-7Gamxz-63 .elHeadline {
  color: rgb(255, 255, 255);
}

.id-6Z-7Gamxz-73.id-6Z-7Gamxz-73 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-7Gamxz-73.id-6Z-7Gamxz-73 .elSubheadline {
  color: rgb(255, 255, 255);
}

.id-6Z-7Gamxz-80.id-6Z-7Gamxz-80 {
  margin-top: 15px;
}
.id-6Z-7Gamxz-80.id-6Z-7Gamxz-80 .elButton {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.id-6Z-6K9P7D-7954.id-6Z-6K9P7D-7954 {
  padding-top: 0px;
}
.id-6Z-6K9P7D-7955.id-6Z-6K9P7D-7955 {
  width: 1170px;
  padding-bottom: 0px;
}
.id-6Z-6K9P7D-7957.id-6Z-6K9P7D-7957 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-7957.id-6Z-6K9P7D-7957 .elSubheadline {
  color: rgb(212, 175, 55);
}

.id-6Z-6K9P7D-7960.id-6Z-6K9P7D-7960 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-8234.id-6Z-6K9P7D-8234 {
  width: 1170px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-6K9P7D-8250.id-6Z-6K9P7D-8250 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8250.id-6Z-6K9P7D-8250 .elSubheadline {
  color: #000000;
}
.id-6Z-6K9P7D-8250.id-6Z-6K9P7D-8250 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-6K9P7D-8243.id-6Z-6K9P7D-8243 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-8240.id-6Z-6K9P7D-8240 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8240.id-6Z-6K9P7D-8240 .elSubheadline {
  color: #000000;
}
.id-6Z-6K9P7D-8240.id-6Z-6K9P7D-8240 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-6K9P7D-8253.id-6Z-6K9P7D-8253 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-b4YjXG-22.id-6Z-b4YjXG-22 {
  width: 1170px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-b4YjXG-24.id-6Z-b4YjXG-24 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-b4YjXG-24.id-6Z-b4YjXG-24 .elSubheadline {
  color: #000000;
}
.id-6Z-b4YjXG-24.id-6Z-b4YjXG-24 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-b4YjXG-27.id-6Z-b4YjXG-27 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-b4YjXG-31.id-6Z-b4YjXG-31 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-b4YjXG-31.id-6Z-b4YjXG-31 .elSubheadline {
  color: #000000;
}
.id-6Z-b4YjXG-31.id-6Z-b4YjXG-31 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-b4YjXG-34.id-6Z-b4YjXG-34 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-b4YjXG-39.id-6Z-b4YjXG-39 {
  width: 1170px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-b4YjXG-41.id-6Z-b4YjXG-41 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-b4YjXG-41.id-6Z-b4YjXG-41 .elSubheadline {
  color: #000000;
}
.id-6Z-b4YjXG-41.id-6Z-b4YjXG-41 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-b4YjXG-44.id-6Z-b4YjXG-44 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-b4YjXG-48.id-6Z-b4YjXG-48 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-b4YjXG-48.id-6Z-b4YjXG-48 .elSubheadline {
  color: #000000;
}
.id-6Z-b4YjXG-48.id-6Z-b4YjXG-48 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-b4YjXG-51.id-6Z-b4YjXG-51 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-9JGdg5-18.id-6Z-9JGdg5-18 {
  width: 1170px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.id-6Z-9JGdg5-20.id-6Z-9JGdg5-20 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGdg5-20.id-6Z-9JGdg5-20 .elSubheadline {
  color: #000000;
}
.id-6Z-9JGdg5-20.id-6Z-9JGdg5-20 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-9JGdg5-23.id-6Z-9JGdg5-23 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-9JGdg5-27.id-6Z-9JGdg5-27 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-9JGdg5-27.id-6Z-9JGdg5-27 .elSubheadline {
  color: #000000;
}
.id-6Z-9JGdg5-27.id-6Z-9JGdg5-27 .fa_prepended {
  color: rgb(220, 178, 42);
}

.id-6Z-9JGdg5-30.id-6Z-9JGdg5-30 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.id-6Z-6K9P7D-8130.id-6Z-6K9P7D-8130 {
  background: rgb(0, 0, 0);
}
.id-6Z-6K9P7D-8131.id-6Z-6K9P7D-8131 {
  width: 1170px;
}
.id-6Z-6K9P7D-8136.id-6Z-6K9P7D-8136 {
  margin-top: -30px;
}

.id-6Z-6K9P7D-8137.id-6Z-6K9P7D-8137 {
  padding-top: 0px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8137.id-6Z-6K9P7D-8137 .elHeadline {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8183.id-6Z-6K9P7D-8183 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.id-6Z-6K9P7D-8183.id-6Z-6K9P7D-8183 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8181.id-6Z-6K9P7D-8181 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.id-6Z-6K9P7D-8181.id-6Z-6K9P7D-8181 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8179.id-6Z-6K9P7D-8179 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.id-6Z-6K9P7D-8179.id-6Z-6K9P7D-8179 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8177.id-6Z-6K9P7D-8177 {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.id-6Z-6K9P7D-8177.id-6Z-6K9P7D-8177 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8140.id-6Z-6K9P7D-8140 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8140.id-6Z-6K9P7D-8140 .elHeadline {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8175.id-6Z-6K9P7D-8175 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8175.id-6Z-6K9P7D-8175 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8189.id-6Z-6K9P7D-8189 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8189.id-6Z-6K9P7D-8189 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8187.id-6Z-6K9P7D-8187 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8187.id-6Z-6K9P7D-8187 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8185.id-6Z-6K9P7D-8185 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8185.id-6Z-6K9P7D-8185 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8151.id-6Z-6K9P7D-8151 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8151.id-6Z-6K9P7D-8151 .elHeadline {
  color: rgb(255, 255, 255);
}

.id-6Z-6K9P7D-8220.id-6Z-6K9P7D-8220 {
  width: 1170px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.id-6Z-6K9P7D-8222.id-6Z-6K9P7D-8222 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-6K9P7D-8222.id-6Z-6K9P7D-8222 .elSubheadline {
  color: rgb(243, 243, 243);
}

.id-6Z-ZW7kAo-3.id-6Z-ZW7kAo-3 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.id-6Z-ZW7kAo-3.id-6Z-ZW7kAo-3 .elParagraph {
  color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  font-size: 0.46rem;
}
@media only screen and (max-width: 700px) {
  .id-6Z-ZW7kAo-3.id-6Z-ZW7kAo-3.id-6Z-ZW7kAo-3 .elParagraph {
    letter-spacing: 0;
    line-height: 140%;
    font-size: 0.8rem;
  }
}

.id-6Z-LalDMW-6 {
  width: 100%;
  justify-content: space-around;
  gap: 0px;
  align-items: center;
  padding-left: 53px;
  padding-right: 53px;
  background: #000000;
}
.id-6Z-LalDMW-7 {
  margin-top: 0px;
  text-align: left;
  flex-shrink: 0;
}
.id-6Z-LalDMW-7 .elImage {
  width: 83px;
  height: 77px;
  aspect-ratio: 16 / 9;
}

.id-6Z-LalDMW-8 {
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  font-size: 0.8333rem;
  flex-basis: 507px;
}

.id-6Z-LalDMW-8 .elNavigatorDesktop .elNavigation-menu-dropdown-holder {
  width: auto;
}
.id-6Z-LalDMW-8 .elModal {
  background: rgb(1, 0, 0);
}

.id-6Z-LalDMW-8 .elNavigatorWrapper {
  --layout-justification-setting: center;
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .elNavigatorButton {
  justify-content: end;
}

.id-6Z-LalDMW-8
  .elNavigatorDesktop
  .elNavigation-menu-dropdown-holder
  .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon > div,
.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon:before,
.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon:after {
  background: rgb(255, 255, 255);
  height: 5px;
}

.id-6Z-LalDMW-8 .elTypographyLink {
  color: rgb(255, 255, 255);
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .navHamburgerIcon {
  height: 19px;
  width: 28px;
}

.id-6Z-LalDMW-8 .elNavigatorWrapper .navigatorBtn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.id-6Z-LalDMW-8 .elNavigatorDesktop {
  gap: 1.9em;
}

.id-6Z-LalDMW-8 .elNavigatorMobile {
  --layout-justification-setting-mobile: center;
  gap: 1.43em;
}
.id-6Z-LalDMW-8 .elClose {
  color: rgb(254, 254, 254);
  font-size: 48px;
}
@media only screen and (max-width: 700px) {
  .id-6Z-LalDMW-8 {
    letter-spacing: 0;
    line-height: 140%;
    font-size: 1.25rem;
  }
}

.id-6Z-LalDMW-9 {
  /* background: ; */
}

.id-6Z-LalDMW-9.elTypographyLink {
  color: rgb(212, 175, 55);
}

.id-6Z-LalDMW-15 {
  text-transform: uppercase;
}

.id-6Z-LalDMW-17 {
  text-align: right;
  margin-top: 5px;
}
.id-6Z-LalDMW-17 .elButton {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  border-style: none;
  padding-left: 15px;
  padding-right: 15px;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 12px;
  --style-box-shadow-blur: 24px;
  --style-box-shadow-spread: -8px;
  --style-box-shadow-color: rgb(220, 178, 42);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px)
    var(--style-box-shadow-spread, 0px) var(--style-box-shadow-color, #000000);
  background: rgb(212, 175, 55);
}

.id-6Z-LalDMW-17 .elButton .elButtonSub {
  color: #ffffff;
}

.id-6Z-LalDMW-17 .elButton .elButtonText {
  color: #ffffff;
  font-size: 0.86rem;
}

.id-6Z-LalDMW-17 .elButton:hover,
.id-6Z-LalDMW-17 .elButton.elButtonHovered {
  background: rgb(253, 194, 0);
}

.id-6Z-LalDMW-17 .elButton:hover .elButtonText,
.id-6Z-LalDMW-17 .elButton.elButtonHovered .elButtonText {
  color: #fff;
}

.id-6Z-LalDMW-17 .elButton:hover .elButtonSub,
.id-6Z-LalDMW-17 .elButton.elButtonHovered .elButtonSub {
  color: #fff;
}

.id-6Z-LalDMW-17 .elButton:active,
.id-6Z-LalDMW-17 .elButton.elButtonActive {
  background: rgb(212, 175, 55);
}

.id-6Z-LalDMW-17 .elButton:active .elButtonText,
.id-6Z-LalDMW-17 .elButton.elButtonActive .elButtonText {
  color: #ffffff;
}

.id-6Z-LalDMW-17 .elButton:active .elButtonSub,
.id-6Z-LalDMW-17 .elButton.elButtonActive .elButtonSub {
  color: #ffffff;
}

.max300 {
  max-width: 370px;
  width: 100%;
  padding: 20px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* padding-bottom: 80px; */
}
.max300img {
  width: 100px !important;
  height: 100px;
  /* margin-bottom: 20px; */
  position: relative;
  top: -50px;
}
@media (max-width: 768px) {
  .max300img {
    top: -30px;
  }
}
.elHeadline {
  margin-top: -20px;
}
.elSubheadline {
  margin-bottom: 20px;
  color: #f1bf1e;
  margin-top: 5px;
}
.whowe {
  justify-content: center;
}
.id-6Z-LaD9Dq-56.id-6Z-LaD9Dq-56 .inputHolder,
.id-6Z-LaD9Dq-56.id-6Z-LaD9Dq-56 .borderHolder {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;
}
/* .myemail {
     padding-top: 12px!important;
     padding-bottom: 12px!important;
     padding-left: 12px!important;
     padding-right: 12px!important;
     border-style: solid!important;
     border-color: rgba(0, 0, 0, 0.2)!important;
     border-width: 1px!important;
 } */
.pt0 {
  padding-top: 0px;
}
.mt0 {
  margin-top: 0px;
}
/* .plmore{
   padding-left: 50px!important;
 }
 .relative{
   position: relative;
 }
 .absolutes{
   position: absolute;
   z-index: 100;
 }
 .countrys{
   width: 50px;
   height: 40px;
   background: black;
 } */
.w-full {
  width: 100% !important;
}
/*  */

/* Nav Mobile */
.elModal {
  position: fixed !important;
  top: 0 !important;
  left: -100% !important; /* Off-screen */
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ) !important; /* Semi-transparent background */
  transition: left 0.5s ease !important; /* Smooth transition */
  z-index: 1000 !important; /* Ensure it's on top */
}

.modal-open {
  left: 0 !important; /* Bring the modal into view */
}

.modal-closed {
  left: -100% !important; /* Hide it again when closed */
}

/* Close Icon Styles */
.elModalClose {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  cursor: pointer !important;
  color: white !important;
}

/* Hamburger Icon Styles */
.elNavigatorButton {
  cursor: pointer !important;
}

.navHamburgerIcon div {
  width: 30px !important;
  height: 4px !important;
  background-color: #333 !important;
  margin: 6px 0 !important;
}
/* Nav Mobile */
.elButtonMainText {
  cursor: pointer !important;
}
.mt-2 {
  margin-top: 8px;
}
.minus_1 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.elSubheadline {
  margin-bottom: 2px;
}
.mt3 {
  margin-top: 12px;
}
@media (max-width: 768px) {
  .p0 {
    padding: 0px !important;
  }
  .mt-3 {
    margin-top: 12px;
  }
  .mb-3 {
    margin-bottom: 12px;
  }
  .flexwrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: flex-start;
  }
  .flexwrap::before {
    display: none;
  }
  .mt0 {
    margin-top: 0px !important;
  }
}
.active-link {
  color: #d4af37;
}

/*  */

.mobilenav {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000;
}
.xmark {
  display: none;
}
@media (max-width: 1024px) {
  .xmark {
    display: block;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .mobilenav {
    display: flex;
  }
  .nav_main {
    position: fixed;
    transform: translateX(-2000px);
  }
  .nav_1 {
    flex-direction: column;
  }
  .nav_2 {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .nav_3 {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }
}
.translateFull {
  transform: translateX(0px) !important;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: 100000;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  gap: 20px;
  height: 100vh;
  padding-bottom: 50px;
  /* top: 60px; */
  overflow-y: scroll;
}
.active {
  color: #d4af37 !important;
  border-bottom: 1px solid #d4af37 !important;
  padding-bottom: 6px;
}

.nav_list {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  padding-bottom: 6px;
}
.nav_list1 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 6px;
}
.nav_list2 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 6px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.btn1 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  border-radius: 4px;
  background: #d4af37;
  width: 185.61px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4af37;
  outline: none;
  cursor: pointer;
}
.btn1:hover {
  background-color: transparent;
  color: #d4af37;
}
/* hero */
.hero_main {
  position: relative;
  width: 100%;
}
.herobg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  object-fit: cover;
  height: 460px;
}
@media (max-width: 1030px) {
  .herobg {
    height: 100%;
  }
}
.hero_main1 {
  padding-top: 60px;
  padding-bottom: 130px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}
.hero_1 {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.25%; /* 70.56px */
}
.hero_2 {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 110.25%; /* 70.56px */
}
.hero_3 {
  color: #d4af37;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.25%; /* 70.56px */
}
.hero_4 {
  margin-top: 16px;
  margin-bottom: 32px;
  color: #c4c4c4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn2 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  border-radius: 10px;
  background: #d4af37;
  width: 200px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4af37;
  outline: none;
  cursor: pointer;
}
.btn2:hover {
  background-color: transparent;
  color: #d4af37;
}
/* world wide */
.world_main {
  padding-top: 60px;
  padding-bottom: 100px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}
.world_1 {
  color: var(--Secondary, #000);
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  max-width: 920px;
  width: 100%;
  margin: auto;
}
.world_2 {
  color: var(--Secondary, #d4af37);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}
.ch {
  font-size: 35px;
}
.world_p {
  margin-top: 12px;
  margin-bottom: 40px;
  color: #72839a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.world_3 {
  display: flex;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 1024px) {
  .world_3 {
    flex-wrap: wrap;
  }
  .world_5 {
    width: 100%;
  }
  .world_5 img {
    width: 100%;
  }
}
.world_4 {
  max-width: 305px;
  width: 100%;
}
.world_4_1 {
  color: #222;
  /* text-align: center; */
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 126.667% */
}
.world_4_2 {
  color: #72839a;
  /* text-align: center; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  margin-top: 20px;
}
.world_6 {
  max-width: 350px;
  width: 100%;
}
.world_6_1 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #222;
  /* text-align: center;
  font-family: Montserrat; */
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 126.667% */
  margin-left: -50px;
}
@media (max-width: 768px) {
  .world_6_1 {
    margin-left: 0px;
  }
}
.world_6_2 {
  color: #d4af37;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
}
.world_6_3 {
  margin-bottom: 38px;
  color: #72839a;
  /* text-align: center; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
}
.btn4 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  border-radius: 4px;
  background: #d4af37;
  width: 184px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #d4af37;
  outline: none;
  cursor: pointer;
}
.btn4:hover {
  background-color: transparent;
  color: #d4af37;
}
/* Choose Us */

.choose_main {
  position: relative;
}
.choosebg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  height: 600px;
}
@media (max-width: 1024px) {
  .choosebg {
    height: 100%;
  }
}
.choose_1 {
  padding-top: 60px;
  color: var(--Secondary, #fff);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 166.667% */
  text-transform: uppercase;
}
.choose_2 {
  color: var(--Secondary, #d4af37);
}
.choose_3 {
  margin-top: 10px;
  margin-bottom: 50px;
  color: #e5e5e5;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 159.091% */
}
.choose_4 {
  color: #d4d4d4;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  text-align: center;
}
.choose_5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .choose_5 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.choose_6 {
  max-width: 280px;
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 33px;
  background-color: rgba(0, 0, 0, 0.51);
  backdrop-filter: blur(11.600000381469727px);
}
.choose_7 {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.choose_8 {
  color: #d4d4d4;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
/* Service Area */
.exm {
  margin-top: 40px;
}
.service_1 {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.border-left {
  border-left: 4px solid #e9f0fb;
  border-right: 4px solid #e9f0fb;
}
.service_2 {
  background: #fff;
  max-width: 394px;
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 30px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 4px solid #e9f0fb;
  border-bottom: 4px solid #e9f0fb;
}
@media (max-width: 1221px) {
  .service_2 {
    border-left: 4px solid #e9f0fb;
    border-right: 4px solid #e9f0fb;
  }
}
.service_3 {
  color: #222;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: 35.1px; /* 130% */
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  text-align: left !important;
  /* margin-top: 20px; */
}
.zeroone {
  position: absolute;
  z-index: -1;
  top: -15px;
  left: -5px;
}
.service_4 {
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
}
.service_5 {
  color: var(--Secondary, #d4af37);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.5px; /* 175% */
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}
/* Projects */
.projects_main {
  background-color: #ffffff;
  /* padding-top: 80px; */
  padding-top: 35px;
  padding-bottom: 80px;
}
.projects_3 h1 {
  color: #000;
}

.projects_1 {
  max-width: 1370px;
  width: 100%;
  /* padding-left: 20px; */
  padding-right: 20px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
@media (max-width: 1130px) {
  .projects_1 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    /* padding-left: 20px; */
  }
  .projects_3 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.projects_2 {
  width: 65%;
  display: flex;
  gap: 32px;
}
@media (max-width: 1120px) {
  .projects_2 {
    width: 100%;
    margin: auto;
  }
  .projects_1 {
    padding-right: 0px;
  }
}
.projects_3 {
  max-width: 580px;
  width: 100%;
}
.projects_4 {
  color: #fff;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  text-align: left !important;
  line-height: 52px;
}
.projects_5 {
  color: var(--Secondary, #d4af37);
}
.projects_6 {
  color: #000000;
  margin-top: 26px;
  margin-bottom: 34px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.projects_7 {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.projects_8 {
  margin-top: 34px;
  display: flex;
  gap: 8px;
}
.projects_8 svg {
  cursor: pointer;
}
.projects_8_mob {
  display: none;
}
@media (max-width: 768px) {
  .projects_8 {
    display: none;
  }
  .projects_8_mob {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    padding-left: 20px;
  }
}
/* Profit */
.profit_main {
  padding-top: 35px;
  padding-bottom: 130px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 30px;
}
@media (max-width: 1024px) {
  .profit_main {
    flex-wrap: wrap;
  }
}
.profit_1 {
  max-width: 600px;
  width: 100%;
}
.profit_1 h1 {
  color: var(--Secondary, #222);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  margin-top: 38px;
}
.profitspan {
  color: var(--Secondary, #d4af37);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px; /* 121.053% */
}
.profit_1 p {
  margin-top: 60px;
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}
.profit_2 {
  max-width: 560px;
  width: 100%;
}
.profitimg {
  width: 100%;
}
/* Clients */
.clients_main {
  padding-top: 10px;
  padding-bottom: 150px;
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.clients_1 {
  color: #222;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 133.333% */
  text-transform: uppercase;
  margin-bottom: 120px;
}
.working {
  color: #d4af37;
}
.clients_2 {
  display: flex;
  justify-content: center;
}
.clients_3 {
  max-width: 445px;
  width: 100%;
}
.clients_4 {
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.34);
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 26px;
  align-items: center;
  position: relative;
}
.clients_4_right {
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.34);
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 26px;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
}
.Arrow-c {
  position: absolute;
  right: -28px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.Arrow-c_right {
  position: absolute;
  left: -28px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  transform: rotate(180deg);
}
@media (max-width: 500px) {
  .Arrow-c {
    right: -18px;
    display: none;
  }
  .Arrow-c_right {
    right: -18px;
    display: none;
  }
}
.clients_5 {
  width: 123px;
  height: 112px;
  border-radius: 0px 0px 10px 10px;
  background: var(--Secondary, #d4af37);
  color: #fff;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  margin-top: -10px;
}
.step {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.clients_6 {
  color: #404040;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mt250 {
  margin-top: 275px;
}
.mt279 {
  margin-top: 260px;
  position: relative;
  top: 44px;
}
.mt279_right {
  margin-top: 260px;
  position: relative;
  top: 35px;
}
.-mtc {
  margin-top: -45px;
}
.mt135 {
  margin-top: 157px;
}
@media (min-width: 1024px) {
  .mob2 {
    display: none;
  }
}
@media (max-width: 1024px) {
  .clientline {
    display: none;
  }
  .clients_2 {
    flex-wrap: wrap;
  }
  .mt135 {
    margin-top: 50px;
  }
  .mt250 {
    margin-top: 50px;
  }
  .mt279 {
    margin-top: 50px;
    position: relative;
    top: 10px;
  }
  .mt279_right {
    margin-top: 40px;
    top: 10px;
  }
  .c3mob {
    display: none;
  }
}
/* History */
.history_main {
  background-color: #000;
  padding-top: 48px;
  padding-bottom: 48px;
}
.history_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 55px;
}
.mobile_historyimage {
  display: none;
}
.desktop_historyimage {
  display: block;
}
@media (max-width: 1024px) {
  .history_1 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .mobile_historyimage {
    display: block;
    margin-bottom: 30px;
  }
  .desktop_historyimage {
    display: none;
  }
}
.history_2 {
  max-width: 562px;
  width: 100%;
}
.historybg {
  width: 100%;
}
.history_3 {
  max-width: 525px;
  width: 100%;
}
.history_4 {
  color: #fff;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  text-transform: uppercase;
}
.historyspan {
  color: #d4af37;
}
.history_5 {
  margin-top: 60px;
  margin-bottom: 60px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.btnhistory {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  border-radius: 4px;
  background: #d4af37;
  min-width: 218px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4af37;
  outline: none;
  cursor: pointer;
}
.btnhistory:hover {
  background-color: transparent;
  color: #d4af37;
}

/* Team */
.team_main {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 56px;
  padding-bottom: 76px;
}
.team_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .team_1 {
    justify-content: center;
  }
}
.team_2 {
  width: 300px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.team_2 h1 {
  margin-top: 20px;
  margin-bottom: 4px;
  color: #333;
  text-align: center;
  font-size: 18.438px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.4px;
}
.team_2 p {
  color: #55676b;
  text-align: center;
  font-size: 19.523px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}
.btnload {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  border-radius: 4px;
  background: #d4af37;
  min-width: 185px;
  height: 60px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4af37;
  outline: none;
  cursor: pointer;
}
.btnload:hover {
  background-color: transparent;
  color: #d4af37;
}
/* Testimonials */
.testi_main {
  padding-top: 40px;
}

.testi_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 70px;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}
/*  */
.t_cards {
  max-width: 370px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.15);
  padding-top: 65px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 45px;
  border-bottom: 8px solid #d4af37;
  position: relative;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.t_cards:hover {
  box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: rgba(237, 27, 80, 0.1);
}
.t_cards_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tabler-quote {
  width: 54px;
  position: absolute;
  left: 10px;
  top: -20px;
  transform: rotate(180deg);
}
.t-cards_img {
  margin-bottom: 30px;
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
}
/*  */
.testi_2_black {
  max-width: 657px;
  width: 100%;
  background-color: #000;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 27px;
  align-items: center;
  border-radius: 16px;
}
.testi_2 {
  max-width: 657px;
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 20px;
  /* align-items: center; */
  border-radius: 16px;
}
/* .testi_2 .userimg{
  height: 253px;
} */
@media (max-width: 550px) {
  .testi_2 {
    flex-wrap: wrap;
  }
  .testi_2 .userimg {
    width: 100%;
  }
}
.testi_3 {
  /* color: #000; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  text-align: center;
}
.testi_3_black {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  text-align: justify;
}
.testi_4_black {
  margin-top: 30px;
  color: #fff;
  font-size: 13.443px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.testi_4 {
  margin-top: 20px;
  /* color: #000; */
  font-size: 13.443px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.testi_5_black {
  color: #fff;
  font-size: 13.443px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.testi_5 {
  margin-top: 20px;
  /* color: #000; */
  font-size: 13.443px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.star_card {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
/* Total */
.total_main {
  background-color: #000;
  padding-top: 70px;
  padding-bottom: 55px;
}
.total_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
@media (max-width: 1024px) {
  .total_1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.total_2 {
  width: 235px;
}
.total_2 h1 {
  color: #fff;
  text-align: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 800;
  line-height: 55px;
}
.plus {
  color: #d4af37;
}
.total_2 p {
  margin-top: 50px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

/* Knowledge */
.knowledge_main {
  padding-top: 80px;
  padding-bottom: 90px;
}
.knowledge_1 {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
}
.k1-update {
  flex-wrap: wrap !important;
  justify-content: center;
}
@media (max-width: 1024px) {
  .knowledge_1 {
    flex-wrap: wrap;
  }
}
.knowledge_2 {
  max-width: 370px;
  width: 100%;
  padding-top: 34px;
  padding-bottom: 18px;
  border-top: 2px solid #e9f0fc;
  border-right: 2px solid #e9f0fc;
  border-left: 2px solid #e9f0fc;
  border-bottom: 2px solid #e9f0fc;
  background: #fff;
}
.k2-update {
  padding-top: 0px;
}
.knowledge_2 h1 {
  color: #222;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
}
.knowledge_2 p {
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 33px;
}
.k34 {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.k34-update {
  justify-content: end !important;
}
.knowledge_3 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 16px;
  padding-left: 36px;
}
.knowledge_3 h2 {
  color: #72839a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.25px;
  text-transform: capitalize;
}
.knowledge_4 {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 36px;
}
.knowledge_4 h2 {
  color: #72839a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.25px;
  text-transform: capitalize;
}
.kimg {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.opacity {
  position: absolute;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.readall {
  margin-top: 16px !important;
  max-width: 1225px;
  width: 100%;
  margin: auto;
  text-align: right !important;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: end;
}
/* News */
.news_1 {
  color: #fff;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 55px;
  margin-bottom: 37px;
}
.news_2 {
  max-width: 216px;
  width: 100%;
  height: 130px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

/* Contact */
.contact_main {
  position: relative;
}
.contactbg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  object-fit: cover;
  height: 940px;
}
@media (max-width: 1024px) {
  .contactbg {
    height: 100%;
  }
}
.contact_main1 {
  padding-top: 100px;
  /* padding-bottom: 100px; */
}
.contact_1 {
  max-width: 1060px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
}
.form1 {
  position: relative;
  margin-top: 70px;
  max-width: 900px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 768px) {
  .form1 {
    justify-content: center;
  }
}
.form2 {
  max-width: 375px;
  width: 100%;
}
.form3 {
  width: 100%;
}

.form2,
.form3 p {
  color: #4d5053;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
  letter-spacing: 0.22px;
  margin-bottom: 8px;
}
.formname {
  border: none;
  background-color: white;
  outline: none;
  width: 100%;
  height: 55px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.formmessage {
  border: none;
  background-color: white;
  outline: none;
  width: 100%;
  height: 130px;
  border-radius: 12px;
  display: flex;
  /* align-items: center; */
  padding-left: 20px;
  resize: none;
  padding-top: 20px;
}
select {
  appearance: none;
}
.subject {
  position: relative;
}
.subject-arrow {
  position: absolute;
  right: 20px;
  top: 55px;
}
.security {
  display: flex;
  align-items: center;
}
.security p {
  width: 100px;
}
.security .formname {
  width: 100px;
}
.btnsend {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  border-radius: 20px;
  background: #d4af37;
  min-width: 210px;
  height: 50px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4af37;
  outline: none;
  cursor: pointer;
}
.btnsend:hover {
  background-color: transparent;
  color: #d4af37;
}
.cdetail {
  position: absolute;
  left: -150px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 350px;
  width: 100%;
  padding-top: 76px;
  padding-bottom: 76px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 50px;
  background: #2b2b2b;
  height: 430px;
  z-index: 10;
}
@media (max-width: 1200px) {
  .form1 {
    margin: auto;
    padding-top: 20px;
  }
  .cdetail {
    position: relative;
    left: 0px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 350px;
    width: 100%;
    margin: auto;
    padding-top: 76px;
    padding-bottom: 76px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 50px;
    background: #2b2b2b;
    height: 430px;
    z-index: 10;
  }
}
.cdetail_1 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}
.cdetail_1 p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.2px;
}
.social {
  padding-top: 30px;
  display: flex;
  gap: 50px;
  align-items: center;
}

.f_extend {
  max-width: 250px;
  width: 100%;
}
.allrights {
  background-color: #000;
  padding-top: 30px;
  padding-bottom: 10px;
}
.allrights_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}
@media (max-width: 768px) {
  .allrights_1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.allrights_1 p {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.25px;
}
.allrights_2 {
  display: flex;
  gap: 15px;
}
.allrights_2 p {
  width: 140px;
}
@media (max-width: 768px) {
  .hero_1 {
    font-size: 30px;
    text-align: left !important;
  }
  .hero_2 {
    font-size: 30px;
  }
  .hero_3 {
    font-size: 30px;
  }
  .hero_4 {
    font-size: 16px;
  }
  .world_1 {
    font-size: 30px;
    line-height: normal;
  }
  .world_2 {
    font-size: 32px;
  }
  .ch {
    font-size: 25px;
    line-height: normal;
  }
  .world_p {
    font-size: 16px;
  }
  .world_4_1 {
    font-size: 20px;
  }
  .world_6_1 {
    font: 20px;
  }
  .choose_1 {
    font-size: 30px;
    line-height: normal;
  }
  .projects_4 {
    font-size: 30px;
    line-height: normal;
  }
  .profit_1 h1 {
    font-size: 30px;
    line-height: normal;
  }
  .profitspan {
    font-size: 30px;
  }
  .clients_5 {
    font-size: 30px;
  }
  .clients_1 {
    font-size: 30px;
    line-height: normal;
  }
  .history_4 {
    font-size: 30px;
  }
  .total_2 h1 {
    font-size: 30px;
  }
  .news_1 {
    font-size: 30px;
    line-height: normal;
  }
  .clients_6 {
    font-size: 18px;
  }
  .cdetail {
    padding-left: 10px;
    padding-right: 10px;
  }
  .allrights {
    padding-top: 50px;
  }
}
.ps {
  display: none;
}
@media (max-width: 1024px) {
  .ps {
    display: block;
    margin: auto;
  }
  .mt135 {
    margin-top: 0px;
  }
  .mt279_right {
    margin-top: 0px;
    top: 0px;
  }
  .mt250 {
    margin-top: 0px;
  }
  .mt279 {
    margin-top: 0px;
    position: relative;
    top: 0px;
  }
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}

.t1img {
  width: 240px;
}
.testswip > .swiper-wrapper > .swiper-slide {
  border: 2px solid #e9f0fc;
  border-radius: 16px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;
}
@media (min-width: 768px) {
  .testswip > .swiper-wrapper > .swiper-slide-next {
    transform: scale(1) !important;
    background: #000 !important;
    border-radius: 16px !important;
    color: white !important;
  }
}
.testswip > .swiper-wrapper > .swiper-slide-next > .testi_3 {
  color: white !important;
}

@media (max-width: 768px) {
  .team_main {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team_1 {
    row-gap: 5px;
  }
  .team_2 {
    width: 150px;
    height: 260px;
  }
  .team_2 img {
    width: 150px;
  }
  .team_2 h1 {
    font-size: 14.438px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.1px;
  }
  .team_2 p {
    font-size: 12px;
    line-height: 16px;
  }
  .footer_2 p {
    text-align: center;
  }
  .footer_3 {
    text-align: center;
  }
  .footer_3 h1 {
    text-align: center;
  }
  .footer_3 p {
    text-align: center;
  }
  .allrights_1 p {
    text-align: center;
  }
  .footer_2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .footer_2 img {
    margin: auto;
    width: 273px;
  }
}
h1 {
  text-align: justify;
}
p {
  text-align: justify;
}
span {
  text-align: justify;
}

.textJustify {
  text-align: justify;
}

.fullwwidth {
  width: 100%;
}

.blog_title {
  font-size: 38px;
  color: var(--Secondary, #000);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  max-width: 920px;
  width: 100%;
  margin: auto;
}

.blog_content {
  /* max-width: 370px; */
  width: 100%;
  padding-top: 34px;
  padding-bottom: 18px;
  border-top: 2px solid #e9f0fc;
  border-right: 2px solid #e9f0fc;
  border-left: 2px solid #e9f0fc;
  border-bottom: 2px solid #e9f0fc;
  background: #fff;
}
.blog_content h1 {
  color: #222;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
}
.blog_content p {
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 33px;
}

.blog_image {
  width: 100%;
  height: 350px;
}
.ex_profit_1 p {
  margin-top: 0px !important;
}
/*  */
.port {
  max-width: 370px;
  width: 100%;
  border-top: 2px solid #e9f0fc;
  border-right: 2px solid #e9f0fc;
  border-left: 2px solid #e9f0fc;
  border-bottom: 2px solid #e9f0fc;
  background: #fff;
  position: relative;
}
.port h1 {
  position: absolute;
  bottom: 0px;
  color: #ffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
}

/* Add this style to your CSS/SCSS file */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: #d4af37;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: none;
  transition: opacity 0.3s;
}

.scroll-to-top-button.visible {
  display: block;
}

/* Case study | Portfolio */

/* CASE Studies */
/* CASE Studies */
.case__a,
.faced,
.faced__a {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  /* padding-left: 10px; */
  padding-right: 10px;
}
.case__b {
  margin-top: 20px;
  background: white;
  box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  gap: 40px;
  align-items: center;
  position: relative;
}
.dots {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  z-index: 50;
}
.case__c {
  width: 291px;
  height: 249px;
  position: relative;
}
.guybg {
  width: 291px;
  height: 249px;
  position: absolute;
  background: #d9d9d9;
  bottom: -12px;
  left: -12px;
  z-index: 1;
  border-radius: 5px;
}
.case__c img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
  z-index: 20;
}
.case__d {
  max-width: 900px;
  width: 100%;
}
.case__d_1 {
  width: 90px;
  height: 6px;
  background: linear-gradient(90deg, #d4af37 0%, #d4af37 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.case__d h2 {
  color: #242527;
  font-size: 46px;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}
.case__d h2 span {
  color: #d4af37;
  font-size: 46px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}
.case__d p {
  margin-top: 12px !important;
  text-align: justify;
  color: #737071;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  word-wrap: break-word;
}
.case__d_2 {
  margin-top: 24px;
  display: flex;
  gap: 24px;
  align-items: center;
}
.case__e {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.case__e_1 {
  max-width: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  color: #404040;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.98px;
  box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
  background: white;
  border-radius: 10px;
  padding: 27px 27px 27px 27px;
}
.faced {
  margin-top: 48px;
}
.faced h2 {
  color: #242527;
  font-size: 40px;
  font-weight: 600;
  word-wrap: break-word;
  text-transform: uppercase;
}
.faced span {
  color: #d4af37;
  font-size: 40px;
  font-weight: 700;
  word-wrap: break-word;
}
.faced p {
  color: #737071;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: justify;
  margin-top: 20px;
}
.faced__a {
  margin-top: 30px;
}
.faced__b {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 20px;
}
.faced__b_1 {
  max-width: 1000px;
  width: 100%;
}
.faced__b_2 {
  max-width: 250px;
  width: 100%;
}
.faced__b_1 h2 {
  margin-top: 20px;
  color: #737071;
  font-size: 28px;
  font-weight: 600;
}
.faced__b_1 span {
  color: #d4af37;
}
.faced__b_1 p {
  margin-top: 12px;
  text-align: justify;
  color: #737071;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
}
.faced__flex {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.faced__flex h2 {
  margin-top: 10px;
}
.pink {
  color: #d4af37;
  font-weight: 600;
}
.faced__b_2 h2 {
  color: #737071;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.faced__b_2 .media_logos {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
  /* width: 100px; */
}
.public {
  margin-top: 10px;
  width: 254px;
  height: 59px;
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #404040;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.68px;
  padding-left: 20px;
  padding-right: 20px;
}
.pb20 {
  margin-bottom: 60px;
}
.star__a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.star__b h3 {
  color: #484848;
  font-size: 32px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}
.star__b h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #484848;
  font-size: 16px;
  font-weight: 400;
}
.star__c {
  color: #737071;
  font-size: 16px;
  font-weight: 400;
  width: 182px;
  height: 71px;
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.pxextra {
  /* padding-left: 50px;
  padding-right: 50px; */
  /* max-width: 1000px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
}
.pxextra1 {
  max-width: 800px;
}
.pxextra1 p {
  font-weight: 400;
}
@media (max-width: 768px) {
  .case__e,
  .faced__b {
    justify-content: center;
    flex-wrap: wrap;
  }
  .faced_line {
    display: none;
  }
  .case__d h2 {
    color: #242527;
    font-size: 36px;
    font-weight: 600;
  }
  .case__d h2 span {
    color: #d4af37;
    font-size: 36px;
  }
  .case__b {
    margin-top: 60px;
    background: white;
    box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
  }
  .case__c {
    width: 100%;
    height: 100%;
  }
  .faced h2 {
    font-size: 30px;
  }
  .faced span {
    font-size: 30px;
  }
  .faced__b_1 h2 {
    font-size: 20px;
  }
  .faced__b_1 p {
    font-size: 16px;
  }
  .star__b h3 {
    color: #484848;
    font-size: 20px;
  }
}
/* CASE Studies */
/* CASE Studies */

.vidplay {
  color: #d4af37;
  font-size: large;
}
.flexsee {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
@media (min-width: 1200px) {
  .faced__b_ex {
    border-right: 1px solid #dcdcdc;
    padding-right: 100px;
  }
}
.loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #dcdcdc;
  border-radius: 50%;
  border-top-color: #d4af37;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/* Modal */
.modal1 {
  /* display: none; */
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: 9000;
}
.modal2 {
  background: white;
  max-width: 800px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
}
.modal2 span {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}
/* Modal */

.faq {
  border-radius: 8px;
  border: 1px solid #ced2da;
  background: #fff;
  box-shadow: 0px 4px 166.7px 0px rgba(0, 0, 0, 0.06);
}
.fji {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  margin: 0px !important;
}

/*  */

/* Login */

.boost {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
  height: 100vh;
}
@media (min-width: 1100px) {
  .boost_1 {
    position: relative;
    max-width: 823px;
    width: 100%;
  }
}
.boost_1 {
  position: relative;
  max-width: 823px;
  width: 100%;
  background: linear-gradient(180deg, #303030 0%, #202020 47.92%, #000 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.up {
  position: absolute;
  right: 0;
  top: 0;
}
.bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}
.logo {
  padding-left: 110px;
  padding-right: 147px;
  width: 500px;
}
.boost_2 {
  color: var(--White, #fff);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.5%; /* 34.71px */
  padding-left: 30px;
  padding-left: 147px;
  padding-right: 147px;
}
.boost_3 {
  padding-top: 20px;
  /* max-width: 580px; */
  width: 100%;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.boost_4 {
  display: flex;
  border-bottom: 1px solid #dadada;
}
.boost_5 {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.boost_6 {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.login {
  color: #dadada;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 126px;
  padding-bottom: 16px;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
}
.border-b {
  color: #333333 !important;
  border-bottom: 4px solid #333333;
  font-weight: 600 !important;
}
.signup {
  color: #dadada;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 126px;
  padding-bottom: 16px;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
}
.boost_8 {
  color: #333;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 100px;
}

.boost_9 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}
.boost_10 {
  /* max-width: 365px; */
  max-width: 500px;
  width: 100%;
  padding-right: 5px;
  margin: auto;
}
.boost_11 {
  margin-top: 63px;
}
.boost_12 {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.boost_13 {
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .boost_14 {
    /* max-width: 290px; */
    width: 100%;
  }
  .mt {
    display: none;
  }
}
.boost_14 {
  border-radius: 10px;
  border: 1px solid #333333;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  height: 60px;
  /* max-width: 290px; */
  width: 100%;
  padding-left: 50px;
  padding-right: 20px;
}
.message {
  position: absolute;
  left: 20px;
  top: 20px;
}
.forgot {
  margin-top: 8px;
  text-align: right;
  color: #52af6c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.loginbtn {
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background: #1c1b21;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  cursor: pointer;
}
.loginbtn:hover {
  background: transparent;
  color: #333333;
  border: 1px solid #333333;
}
.hr {
  border: 1px solid #dadada;
  margin-top: 50px;
  margin-bottom: 40px;
}
.register {
  text-align: center;
  padding-bottom: 40px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.now {
  color: #333333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
  cursor: pointer;
}
@media (max-width: 950px) {
  .boost {
    flex-wrap: wrap;
  }
  .boost_1 {
    height: 400px !important;
  }
  .boost_1 {
    position: relative;
    max-width: 950px !important;
    width: 100%;
    align-items: center !important;
  }
  .boost_1 {
    position: relative;
    /* max-width: 823px; */
    width: 100%;
  }
  .boost_1 {
    align-items: center;
  }
}
@media (max-width: 786px) {
  .mt {
    display: block !important;
  }
  .up {
    display: none;
  }
  .bottom {
    display: none;
  }
  .logo {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 5px;
  }
  .boost_2 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    text-align: center;
  }
  .boost_8 {
    font-size: 20px;
    margin-top: 35px;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
    width: 190px;
  }
  .boost {
    gap: 1px !important;
  }
  .boost_11 {
    margin-top: 34px;
  }
  .boost_12 {
    font-size: 12px;
  }
  .forgot {
    font-size: 12px;
  }
  .boost_14 {
    font-size: 10px;
    width: 100% !important;
  }
  .hr {
    border: 1px solid #dadada;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .register {
    font-size: 12px;
  }
  .now {
    font-size: 12px;
    margin-left: 10px;
  }
}

@media (max-width: 1350px) {
  /* .boost_1 {
    position: relative;
    max-width: 553px;
    width: 100%;
    background: linear-gradient(180deg, #303030 0%, #202020 47.92%, #000 100%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
  .boost_3 {
    padding-top: 20px;
    max-width: 480px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  } */
  .logo {
    padding-left: 50px;
    padding-right: 50px;
  }
  /* .boost_2 {
    padding-left: 80px;
    padding-right: 80px;
  } */
}
@media (max-width: 1200px) {
  .boost_1 {
    position: relative;
    max-width: 553px;
    width: 100%;
    background: linear-gradient(180deg, #303030 0%, #202020 47.92%, #000 100%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
  .boost_3 {
    padding-top: 20px;
    max-width: 480px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo {
    padding-left: 80px;
    padding-right: 80px;
    width:500px;
  }
  .boost_2 {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1050px) {
  .logo {
    /* padding-left: 23px;
    padding-right: 23px; */
    width:500px;
  }
  .boost_2 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-height: 900px) {
  .boost_8 {
    margin-top: 100px;
  }
  .boost_11 {
    margin-top: 63px !important;
  }
  .hr {
    border: 1px solid #dadada;
    margin-top: 50px !important;
    margin-bottom: 40px !important;
  }
}
@media (max-height: 900px) {
  .boost_8 {
    margin-top: 30px;
  }
  .boost_11 {
    margin-top: 20px !important;
  }
  .hr {
    border: 1px solid #dadada;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
}
@media (max-width: 1015px) {
  .logo {
    padding-left: 10px;
    padding-right: 10px;
    width: 300px !important;
    margin: auto;
  }
}
.fp {
  font-size: 14px !important;
  text-align: right;
  margin-top: 10px;
  cursor: pointer;
}
.r_me {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
}
.r_me p {
  color: #6b7280;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.s-up {
  margin-top: 20px !important;
}
.s-up-form {
  margin-top: 10px !important;
}
.s-up-hr {
  border: none;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

/* Login */

/* Sale */
.bgblack {
  background: black;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.bgblack img {
  max-width: 450px;
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.qmarks {
  width: 30px;
  margin: 0px;
}
.faq-question span {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-start;
}
.sale_1 {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin-top: 0px !important;
  /* padding-top: 40px!important; */
  border: 2px red dashed;
  padding: 12px;
  background: #d4af37;
  color: white;
  font-size: 20px;
  text-align: center;
  /* margin-top: 30px; */
  /* margin-bottom: 10px; */
}
.sale_2 {
  background: white;
  padding: 12px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  box-shadow: 0 10px -7px -40px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.sale_2 h1 {
  color: black;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.sale_3 {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .sale_3 {
    flex-wrap: wrap;
  }
}
.sale_4 {
  max-width: 575px;
  width: 100%;
}
.sale_4 p {
  color: #d4af37;
  font-size: 28px;
  text-decoration: underline;
  font-weight: 600;
  text-align: center;
}
.sale_4 img {
  width: 100%;
}

.opensample {
  border-radius: 6px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: -4px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 31px;
  --style-box-shadow-spread: 0px;
  --style-box-shadow-color: rgb(140, 140, 154);
  box-shadow: var(--style-box-shadow-style-type)
    var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px)
    var(--style-box-shadow-spread, 0px) var(--style-box-shadow-color, #000000);
  background: rgb(188, 156, 34);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: auto; */
  font-weight: 600;
  transition: background 0.2s ease;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  text-decoration: none;
  margin-top: 16px;
}
.sale_4_1 {
  text-align: center;
  margin-top: 26px;
  margin-top: 26px;
  color: black;
  font-weight: 600;
  font-size: 28px;
}
.real_saqib {
  width: 100%;
  /* margin-bottom: 20px; */
}
.heres {
  font-size: 14px;
  text-align: center;
}
.sale_4_2 {
  margin-top: 20px;
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
}
.sale_4_2 img {
  width: 16px;
  margin: 0px;
}
.sale_5 {
  padding: 12px;
  max-width: 480px;
  width: 100%;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.sale_5 > form {
  border: 1px solid black;
  padding: 12px;
}
.form-group {
  margin-bottom: 15px;
}

.form-group.half {
  width: 48%;
  display: inline-block;
}

label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.phone-group {
  display: flex;
}

.country-code {
  font-size: 18px;
  margin-right: 10px;
  line-height: 36px;
}

.error {
  border-color: #e63946;
}

.error-message {
  color: #e63946;
  font-size: 12px;
  margin-top: 5px;
}

.product {
  display: flex;
  align-items: center;
  border: 1px solid rgb(188, 156, 34);
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(188, 156, 34, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.product-info {
  flex-grow: 1;
}

.product-title {
  font-size: 12px;
  color: #666;
}

.product-price {
  font-size: 16px;
  font-weight: bold;
  color: #444;
}

.submit-section {
  text-align: center;
  margin-top: 20px;
}

.submit-btn {
  background-color: #d4af37;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #d4af37;
}

.disclaimer {
  font-size: 12px;
  text-align: center;
  color: #888;
  margin-top: 10px;
}
.c_i {
  font-size: 12px;
  font-weight: 700;
}
.testimonials-section {
  text-align: center;
  padding: 40px 20px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin-top: 30px;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 40px;
  text-align: center;
}

.testimonials-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  margin-top: 60px;
}

.testimonial {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.testimonial:hover {
  transform: translateY(-10px);
}

.testimonial-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 20px;
  position: relative;
  /* top: -50px; */
}
.testimonial-text {
  position: relative;
  /* top: -30px; */
}
.testimonial-text {
  font-size: 12px;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.6;
  text-align: center;
}

.testimonial-author {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  text-align: center;
}

.cta-button {
  background-color: #d4af37;
  border: none;
  color: #fff;
  padding: 15px 30px;
  font-size: 26px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.cta-button:hover {
  background-color: #d4af37;
  opacity: 0.8;
}
.faq-section_main {
  background: black;
  padding: 20px;
  margin-top: 20px;
  /* margin-bottom: 20px; */
}
.faq-section {
  text-align: center;
  padding: 50px 20px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.faq-header {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.faq-title {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 40px;
}

.faq-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.faq-item {
  background-color: #bc9c22;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 100%;
}

.faq-question {
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
}

.faq-answer {
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .max300 {
    max-width: 800px;
  }
}
@media (max-width: 1200px) {
  .testimonials-container {
    flex-wrap: wrap !important;
    gap: 40px;
  }
  .testimonial {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }

  .faq-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .rowreverse {
    flex-direction: column-reverse;
  }
  .elButtonMain {
    text-align: center;
  }
}
[data-page-element="TextArea/V1"].labelInside.elInputFocused .elInput,
[data-page-element="TextArea/V1"].labelInside.hasValue .elInput {
  transform: translateY(25%);
}
[data-page-element="Input/V1"].labelInside.elInputFocused .elInput,
[data-page-element="Input/V1"].labelInside.hasValue .elInput {
  transform: translateY(25%);
}
[data-page-element="Input/V1"] {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
/* Sale */

.elButton_1 {
  width: 190px !important;
}
.elButtonText_1 {
  font-size: 20px !important;
}
.bgsales {
  /* background-image: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/photo-1583668928307-a9c4fc140c48.jpg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-top: 10px;
  /* padding-bottom: 40px; */
}
.text-center {
  text-align: center;
}
.myrow {
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.myrow1 {
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.myrow::before {
  display: none;
}
.myrow1::before {
  display: none;
}
.mt6 {
  margin-top: 20px;
}
.color-black {
  color: black;
}
.color-golden {
  color: #d4af37;
}
.rounded {
  border-radius: 20px;
}
.mob-show {
  display: none !important;
}
.mt12 {
  margin-top: 60px;
}
.p_team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12px;
}
@media (max-width: 768px) {
  .myrow {
    grid-template-columns: repeat(1, 2fr);
  }
  .myrow1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .id-6Z-7Gamxz-53 {
    padding-bottom: 20px !important;
  }
  .mob-hidden {
    display: none !important;
  }
  .mob-show {
    display: flex !important;
  }
  .mx0 {
    margin: 0px !important;
  }
  .mt0 {
    margin-top: 6px !important;
  }
  .faced__flex {
    gap: 0px;
  }
  .faced__b_2 {
    max-width: 500px;
  }
  .public {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .public img {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.phone-group {
  display: flex;
  align-items: center;
  position: relative;
}

.country-code {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* .input-fields {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */
.color-white {
  color: white;
}
.pb10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.socialstext {
  margin-top: -10px !important;
}
.project_im {
  max-width: 370px;
  width: 100%;
  margin: auto;
}

/* Accordian */
.accordion {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  /* border: 1px solid #ddd; */
  /* margin-bottom: 20px; */
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px; */
}

.accordion-item {
  padding-bottom: 20px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 12px;
}

.accordion-header p {
  margin: 0;
  font-weight: 500;
  /* color: #333; */
  text-align: left;
}
.accordion-header {
  color: #333;
}

.icon {
  font-size: 1.5em;
  transition: transform 0.3s ease;
}

.icon.rotate {
  transform: rotate(45deg);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* border: 1px solid #ddd; */
}

.accordion-content.show {
  max-height: 200px;
  padding: 16px;
}
/* .accordion-header.show{
  } */
.open {
  background: #d4af37 !important;
  color: #fff !important;
}

.accordion-content p {
  margin: 0;
  color: #666;
}
/* Accordian */
/* Thanks Page  */
.thanksbg {
  background-color: #fcf8f2;
}
.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thankh1 {
  color: #b18900;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
}

.subtitle {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.step-container {
  margin-bottom: 20px;
  text-align: left;
}

.step-title {
  color: #b18900;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  text-decoration: underline;
}

.step-subtitle {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.step-description {
  /* font-style: italic; */
  margin-bottom: 10px;
  text-align: center;
}

.step-list {
  list-style-type: none;
  padding-left: 20px;
}

.step-item {
  font-size: 24px;
  margin-bottom: 10px;
}

.footer-text {
  color: #b18900;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
}

.signature {
  font-size: 18px;
  color: #b18900;
  margin-top: 30px;
  margin-bottom: 30px;
}

.footer_thanks {
  /* background-color: #111; */
  color: #fff;
  padding: 10px;
  font-size: 16px;
  margin-top: 30px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.footer_logo {
  width: 50px !important;
  margin: 0px !important;
}
.ticksss {
  width: 20px;
}
@media (max-width: 768px) {
  .thankh1 {
    color: #b18900;
    font-size: 45px;
  }
}
/* Thanks Page  */
/* Pricing */
/* .App {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} */

.app-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: black;
  color: white;
  width: 100%;
  align-items: center;
}

.logoss {
  width: 150px; /* Adjusted logo size */
  height: auto;
  margin: 0px;
}
.header-buttons {
  margin: 0px;
}

.header-buttons .reset-button {
  background-color: rgb(188, 156, 34);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.header-buttons .reset-button:hover {
  background-color: rgb(188, 156, 34);
}

.main-content {
  display: flex;
  flex-grow: 1 !important;
  padding: 20px;
  width: 100%;
}

.sidebar-filters {
  padding: 20px;
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
  min-width: 250px;
  max-width: 300px;
}

.sidebar-filters h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.filter-group {
  margin-bottom: 15px;
}

.filter-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.filter-group select,
.filter-group input {
  width: 100%;
  padding: 8px;
  font-size: 1em;
  margin-bottom: 5px;
}

.reset-button {
  width: 100%;
  padding: 10px;
  background-color: rgb(188, 156, 34);
  color: white;
  font-size: 1em;
  border: none;
  cursor: pointer;
}

.reset-button:hover {
  background-color: rgb(188, 156, 34);
}

.publication-container {
  flex-grow: 1;
  padding: 20px;
  overflow-x: auto;
}

.publication-table {
  width: 100%;
  border-collapse: collapse;
}

.publication-table th,
.publication-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.publication-table th {
  background-color: #f4f4f4;
}

.publication-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.publication-table tr:hover {
  background-color: #f1f1f1;
}

.publication-cell {
  display: flex;
  align-items: center;
}

.pub-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.pub-name {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.pub-name:hover {
  text-decoration: underline;
}

.info-icon {
  margin-left: 5px;
  color: #666;
  cursor: pointer;
}

.sample-icon {
  width: 30px;
  height: 30px;
}

/* Tags */
.tag {
  font-size: small;
  padding: 2px;
  border-radius: 5px;
  margin-right: 5px;
  color: white;
}

.tag.contributor {
  background-color: #bf12f3;
}

.tag.new {
  background-color: #ff0000;
}

.tag.sponsored {
  background-color: #3498db;
}

.tag.discrete {
  background-color: #9b59b6;
}

.genre-list {
  display: flex;
  flex-wrap: wrap;
}

.genre-tag {
  background-color: lightgrey;
  color: black;
  font-size: small;
  padding: 2px 8px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Dialog Styles */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.dialog-box button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: rgb(188, 156, 34);
  color: white;
  border: none;
  cursor: pointer;
}

.dialog-box button:hover {
  background-color: rgb(188, 156, 34);
}

.app-footer {
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  position: relative;
  bottom: 0;
}

/* Style the track of the range input */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: rgb(188, 156, 34);
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(188, 156, 34);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin-top: -6px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: rgb(188, 156, 34);
  border-radius: 5px;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: rgb(188, 156, 34);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: rgb(188, 156, 34);
  border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
  background: rgb(188, 156, 34);
  border-radius: 5px;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: rgb(188, 156, 34);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]:hover::-webkit-slider-thumb {
  background: rgb(160, 130, 30);
}

input[type="range"]:hover::-moz-range-thumb {
  background: rgb(160, 130, 30);
}

input[type="range"]:hover::-ms-thumb {
  background: rgb(160, 130, 30);
}

.no-sample {
  display: inline-block;
  background-color: lightgrey;
  color: black;
  font-size: 0.8rem;
  border-radius: 25px;
  text-align: center;
  width: 100px;
  height: 30px;
  line-height: 30px;
}
.pub-logo {
  margin: 0px;
}
.sample-icon {
  width: 30px;
  height: 30px;
}
@media (max-width: 768px) {
  .main-content {
    flex-wrap: wrap;
  }
  .publication-container {
    padding: 0px;
  }
  .sidebar-filters {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sidebar-filters {
    min-width: 276px;
    max-width: 422px;
    width: 100%;
    padding: 4pxx;
  }
}

/* width */
.publication-container::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.publication-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.publication-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.publication-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Pricing */
.flex-icons {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.text-lefts {
  text-align: left !important;
}
.mx0 {
  margin-left: 0px;
  margin-right: 0px;
}
@media (max-width: 768px) {
  .text-lefts {
    text-align: center !important;
  }
  .flex-icons {
    justify-content: center;
  }
  .flexwrapreverse {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 20px;
  }
  .m-none {
    display: none;
  }
  .sidebar-filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    /* margin-bottom: 20px; */
  }
  .filter-group{
    margin-bottom: 1px;
  }
  .main-content {
    padding: 10px;
}
.reset-button{
  font-size: 10px;
  margin-top: 10px;
}
.filter-group{
  width: 100%;
}
.reset-button{
  width: 100%;
}
.filter-group label{
  font-size: 10px;
}
.filter-group input{
  font-size: 10px;
}
.filter-group span{
  font-size: 10px;
}
.filter-group select{
  font-size: 10px;
}
}

.footer-text {
  color: white;
  font-size: 12px;
}
.mt0 {
  margin-top: 0px !important;
}
.id-6Z-6K9P7D-8130.id-6Z-6K9P7D-8130 {
  border-top: 1px solid white;
  padding-top: 50px;
}


.team_main h3{
  margin-top: 20px;
  margin-bottom: 20px;
}



/* Sales Timer top of page */

.countdown-timer {

  color: #ffffff; /* White text */
  text-align: center;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 20px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.countdown-timer h2 {
  margin: 0;
  font-weight: bold;
}

.timer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.time-box {
  /* background-color: transparent; Matches the background color */
  color: #ffffff;
  /* padding: 5px 10px; */
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-box span:first-child {
  font-size: 20px;
  font-weight: bold;
}

/* brown */

.messageh2{
  color: brown;
  font-size: larger;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  backdrop-filter: blur(5px); /* Background blur effect */
  z-index: 9999; /* Ensure it's on top */
}

.nb-spinner {
  width: 75px;
  height: 75px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #FDC200;
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Add this CSS to style the loader */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
